import './pop_up_input.css';

export default function PopUpInput({ body = <div />, actions = [], isDisplayed = false, expanded = true }) {

    return <div className={`pop_up_input ${isDisplayed && 'pop_up_input_displayed'} ${expanded && 'pop_up_input_expanded'}`}>
        <div className="pop_up_input_body_holder">
            {body}
        </div>
        <div className="divider" />
        <div className="pop_up_input_actions flex">
            {actions.length && actions.map((e, i) => {
                return <p key={i} className="pop_up_input_actions_item" onClick={(event) => { event.stopPropagation(); e?.onClick(); }}>{e.label}</p>
            })}
        </div>
    </div>
}