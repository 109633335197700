import React, { useEffect, useState } from 'react';
import './file_uploader_input.css';
import { isEmpty, uploadFilesS3 } from '../../../utils/utils';
import FilePicker from '../file_picker/file_picker';






export default class FileUploaderInput extends React.Component {

    state = {
        isAsyncCall: false,
        files: [],
        fTaken: 0,
        isAsyncCallUpload: false,
        uploadResults: {},
        uploadingIndex: 0,
        urls: [],
    }

    async handleFileCollection(url) {
        if (!isEmpty(url)) {
            if (this.state.urls.length < this.state.files.length) {
                await this.setState({ urls: [...this.state.urls, url] });
                if ((this.state.urls.length) === this.state.files.length) {
                    this.props.uploadOptions.onUploadSuccess(this.state.urls);
                }
            }
        }
    }

    componentDidUpdate(prevPros, prevState, snapshot) {
        if (prevPros.uploadOptions.itsUploadTime !== this.props.uploadOptions.itsUploadTime) {
            if (this.props.uploadOptions.itsUploadTime && this.state.files.length < 1) {
                this.props.uploadOptions.onUploadSuccess([]);
            }
        }
    }

    componentDidMount() {
        if (this.props.isResetTime) {
            this.setState({ isAsyncCall: false, fTaken: 0 });
            this.setFiles([], true);
        }
    }

    render() {
        const files = [...this.state.files];
        const isMultiple = this.props.multiple;
        const extensions = String([...this.props.extensions].map((e) => `.${e}`));
        return <div className='file_uploader_input'>
            {
                files.length || this.state.isAsyncCall
                    ? <div className='input_like_div file_uploader_input_not_empty'>
                        {
                            this.state.isAsyncCall
                                ? <div className='flex file_uploader_input_upload_indicator'>
                                    <i className='fa-solid fa-spinner fa-spin' />
                                    <h6>Please wait ...</h6>
                                </div>
                                : <div className='file_uploader_input_not_empty_pl_h'>
                                    <p className='file_uploader_input_hint_style'>{this.props.hintText}</p>
                                    {isMultiple && <h6 onClick={() => { this.setFiles([], true); this.setState({ fTaken: 0 }) }}>Clear all</h6>}
                                </div>
                        }
                        <div className='divider' />
                        {[...files].map((e, i) => {
                            return <SingleFileItem
                                key={i}
                                file={e}
                                uploadOptions={this.props.uploadOptions}
                                itsUploadTime={this.props.uploadOptions.itsUploadTime}
                                onSuccess={(url) => {
                                    this.handleFileCollection(url);
                                }}
                                onClick={(state) => {
                                    if (state !== 'circle-check') {
                                        this.setState({ fTaken: this.state.files.filter((element) => element !== e).length });
                                        this.setFiles(this.state.files.filter((element) => element !== e), true)
                                    }
                                }} />
                        })}
                    </div>
                    : <div>
                        <FilePicker
                            extensions={extensions}
                            isMultiple={isMultiple}
                            isEnabled={true}
                            max={this.props.max}
                            onChange={(files) => {
                                console.log("What the fucckkkk", files);

                                this.setFiles(files, true);
                            }}
                            onLoading={(isAsyncl) => {
                                this.setState({ isAsyncCall: isAsyncl });
                            }}
                            builder={(inputId) => {
                                return <label htmlFor={inputId} className='input_like_div file_uploader_input_hint_style file_uplaoder_input_field_label'>{this.props.hintText}</label>
                            }}
                        />
                    </div>
            }
        </div>
    }



    async setFiles(files, isAll) {
        if (isAll) {
            this.props.onChange(files.length);
            await this.setState({ files: files, uploadResults: {}, });
        } else {
            this.props.onChange([...this.state.files, ...files].length);
            await this.setState({ files: [...this.state.files, ...files], uploadResults: {}, });
        }
    }
}

function returnNewState(uploadResults) {
    if (!uploadResults?.success && !uploadResults?.error) {
        return 'close'
    } else if (uploadResults?.success && !uploadResults?.error) {
        return 'circle-check'
    } else {
        return 'circle-exclamation';
    }
}


function SingleFileItem({ file = null, onClick = () => { }, itsUploadTime = false, onSuccess = (url) => { }, uploadOptions = {} }) {
    const [state, setUploadState] = useState({ success: false, error: false });
    const [isAsyncCallUpload, setisAsyncCallUpload] = useState(false);
    const [hasUpload, setHasUpload] = useState(false);

    useEffect(() => {
        if (itsUploadTime) {
            if (!hasUpload) {
                setHasUpload(true);
                setisAsyncCallUpload(true);
                uploadFilesS3(file, { userUUID: uploadOptions.userUUID, field: uploadOptions.field }).then((url) => {
                    const val = !isEmpty(url);
                    setisAsyncCallUpload(false);
                    setUploadState({ success: val, error: !val });
                    onSuccess(url);
                    
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [itsUploadTime]);


    const currentState = returnNewState(state);
    return <div className='images_lu'>
        <p>{file?.name}</p>
        {isAsyncCallUpload
            ? <i className='fa-solid fa-spinner fa-spin' />
            : <i className={`fa-solid fa-${currentState}`} id={`upload_indicator_${currentState}`} onClick={(event) => {
                event.stopPropagation();
                onClick(currentState);
            }} />
        }
    </div>
}