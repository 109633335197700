import React from "react";
import { getErrorMessage, isEmpty } from "../../../utils/utils";
import Notifier from "../../components/notifier/notifier";
import Joi from "joi";
import ApiProvider, { ApiMethods } from "../../../data/providers/api_services";
import { systemAuthBaseUrl } from "../../../config/config";
import { copyWith } from "../../../logic/slice";
import { connect } from "react-redux";


class ChangePassword extends React.Component {

    state = {
        isAsyncCall: false,
        errorMessage: '',
        successMessage: '',
        formValues: {},
        formValuesToSend: {},
    }

    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState(state => ({ ...state, formValues: { ...state.formValues, [name]: value } }));
    }
    render() {
        const waitingmsg = {
            'fr': "Patientez s'il vous plait...",
            'en': "Please wait...",
            'sw': "Tafadhali subiri..."
        }[`${this.props.currentLanguage}`]

        const change = {
            'fr': "Changer",
            'en': "Change ",
            'sw': "Badilisha"
        }[`${this.props.currentLanguage}`]
        const joiSchema = Joi.object({
            CurrentPassword: Joi.string().required(),
            NewPassword: Joi.string().required(),
            ConfirmNewPassword: Joi.string().required().valid(Joi.ref('NewPassword')),
        });
        return <div className="modal_rideau">
            <div className="dif_modal change_password_up">
                <div className="flex call_to_subcsription_header update_user_informations_header">
                    <div>
                        <h2>{{
                            fr : "Changer votre mot de passe",
                            en : "Change your password",
                            sw : "Badilisha nenosiri lako"
                        }[`${this.props.currentLanguage}`]}</h2>
                    </div>
                    <span id='cross_container' onClick={() => { this.props.onClosePressed() }}>
                        <i className="fa-solid fa-xmark"></i>
                    </span>
                </div>
                <div className="divider" />
                <fieldset disabled={this.state.isAsyncCall}>
                    {!isEmpty(this.state.errorMessage) && <>
                        <Notifier type='error' message={this.state.errorMessage} />
                        <div className="divider" />
                    </>}
                    {!isEmpty(this.state.successMessage) && <>
                        <Notifier type='success' message={this.state.successMessage} />
                        <div className="divider" />
                    </>}
                    <form className='search_form' onChange={this.handleChange} onSubmit={(event) => {
                        event.preventDefault();
                        this.setState({ errorMessage: '', successMessage: '', infoMessage: '' });
                        joiSchema.validateAsync(this.state.formValues).then((formValues) => {
                            this.changePassword(this.state.formValues);
                        }).catch((error) => {
                            this.setState({ errorMessage: `${error.details.map((e) => e.message)}` });
                        });
                    }}>
                        <input type="password" name="CurrentPassword" placeholder={{
                            fr : "Mot de passe actuelle",
                            en : "Current password",
                            sw : "Neno siri la sasa"
                        }[`${this.props.currentLanguage}`]} />
                        <input type="password" name="NewPassword" placeholder={{
                            fr : "Nouveau mot de passe",
                            en : "New password",
                            sw : "Neno siri la mpia"
                        }[`${this.props.currentLanguage}`]} />
                        <input type="password" name="ConfirmNewPassword" placeholder={{
                            fr : "Confirmer votre mot de passe",
                            en : "Confrim your password",
                            sw : "hakikisha neno siri lako"
                        }[`${this.props.currentLanguage}`]} />
                        <div className="divider" />
                        <button className={`${this.state.isAsyncCall && 'button_on_async'}`} type={'submit'}>{this.state.isAsyncCall ? waitingmsg : change }</button>
                    </form>
                </fieldset>
            </div>
        </div>
    }

    changePassword(formValues) {
        this.setState({ isAsyncCall: true, errorMessage: '', successMessage: '' });
        const restApiServices = new ApiProvider();
        restApiServices.provider({
            baseUrl: systemAuthBaseUrl,
            endpoint: 'api/alter-user-change-password',
            apiMethod: ApiMethods.patch,
            successCodeExpectation: 200,
            requestData: {
                token: {
                    accessToken: this.props?.userInformation?.accessToken,
                },
                body: formValues,
            },
        }).then((response) => {
            if (typeof (response.error) === 'undefined') {
                this.setState({ isAsyncCall: false, successMessage: 'You password has been changed successfully' });
                this.props.dispatch(copyWith({ userInformation: {}}));
            } else {
                this.setState({ isAsyncCall: false, errorMessage: getErrorMessage(response.error, 'en') });
            }
        });
    }
}

const mapStateToProps = (storeOf) => ({
    currentLanguage: storeOf.madoshome.currentLanguage.code,
    userInformation: storeOf.madoshome.userInformation,
});

export default connect(mapStateToProps)(ChangePassword);