import React from "react";
import CardsDisplayer from "../cards_displayer/cards_displayer";
import noEntitiesSvg from "../../../assets/svgs/nodata.svg"
import './entity_displayer.css';
import NotFound from "./itemsNotFound";



export default class EntityDisplayer extends React.Component {

    state = {
        isAsyncCall: true,
        isAsyncMore: false,
        entities: [],
        maxReached: false,
    }

    max = 40;


    loading() {
        if (!this.state.isAsyncMore) {
            this.setState({})
            this.setState({ isAsyncMore: true });
            const theList = this.state.entities.filter((en)=> en != null);
            console.log('TRUE ENT',this.state.entities.length, 'false en', theList.length);
            this.props.onFetchingEntities(theList.length ? theList[theList.length - 1].id : 0).then(async (entities) => {
                await this.setState({ isAsyncMore: false, isAsyncCall: false });
                if ([...entities].length) {
                    if (theList.length < this.max) {
                        const entitiesList = [...theList, ...entities].filter((el) => el != null);
                        this.props.onEntitiesFetched(entitiesList);
                        await this.setState({ entities: entitiesList });
                    } else {
                        await this.setState({ entities: entities });
                    }
                } else {
                    await this.setState({ maxReached: true });
                }
            });
        }
    }


    componentDidMount() {
        if ([...this.props.entities].length) {
            this.setState({ isAsyncCall: false, entities: [...this.props.entities] });
        } else {
            if (this.props.onFetchingEntities) {
                this.loading();
            } else {
                this.setState({ entities: [], isAsyncCall: false });
            }
        }
    }

    componentWillUnmount() {
        this.setState({ entities: [] });
    }

    render() {
        const { builder, mapCoordinates } = this.props;
        return <div className="entity_displayer">
            {this.state.isAsyncCall ? <CardsDisplayer
                itemCount={30}
                itemBuilder={(index) => {
                    return <div className='shine' />;
                }}
                onReachBottom={async () => { }}
            /> : <CardsDisplayer
                itemCount={this.state.isAsyncMore ? this.state.entities.length + 1 : this.state.entities.length}
                itemBuilder={(index) => {
                    const entity = this.state.entities[index];
                    return entity === null ? <></>: Object.keys(entity ?? {}).length > 0
                        ? builder(entity)
                        : this.state.isAsyncMore ? <div style={{ minHeight: '100%', minWidth: '100%' }} className='shine' /> : <></>
                }}
                onReachBottom={async () => {
                    if (!this.state.maxReached) {
                        this.loading();
                    }
                }}
                mapCoordinates={mapCoordinates(this.state.entities)}
            />
            }
            {!this.state.isAsyncCall && <NotFound
                Data={this.state.entities}
                Message={
                    {
                        fr: "Aucune publication trouvée dans cette section.",
                        en: "No post was found in this section.",
                        sw: "Hakuna chapisho inayo patikana katika sehemu hii."
                    }
                }
                onRetry = {(event)=>{
                    event.stopPropagation();
                    this.setState({isAsyncCall : true })
                    this.loading()
                    // console.log("retrying ...")
                }}
                imgToShow = {noEntitiesSvg}
            />}
        </div>
    }
}
