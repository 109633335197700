import { systemFavoriteBaseUrl, systemHotelsBaseUrl, systemHousesBaseUrl, systemTourismBaseUrl } from "../../../config/config";
import ApiProvider, { ApiMethods } from "../../../data/providers/api_services";
import HotelCard from "../../components/hotelcard/hotel_card";
import HouseCard from "../../components/housecard/house_card";
import TourSiteCard from "../../components/tour_site_card/tour_site_card";

export const homeCategories = ({ lat = 0.0, lng = 0.0, accessToken = '', region = 'Unknown' }) => [
    {
        key: 'home/houses',
        onFetchingEntities: async (offset) => {
            const restApiServices = new ApiProvider();
            return await restApiServices.provider({
                baseUrl: systemHousesBaseUrl,
                endpoint: `api/building/filter?offset=${offset}`,
                apiMethod: ApiMethods.get,
                successCodeExpectation: 200,
                requestData: {
                    token: { accessToken: accessToken },
                    headers: {
                        latitude: lat,
                        longitude: lng,
                        region: region,
                    },
                },
            }).then((response) => {
                if (typeof (response.error) === "undefined") {
                    return response.response;
                } else {
                    return [];
                }
            });
        },
        builder: (entity) => { return <HouseCard key={entity.id} house={entity} isUpdateTime={false} /> },
        mapCoordinates: (entity) => { return { lat: Number(entity.Latitude), lng: Number(entity.Longitude) } }
    },
    {
        key: 'home/hotel',
        onFetchingEntities: async (offset) => {
            const restApiServices = new ApiProvider();
            return await restApiServices.provider({
                baseUrl: systemHotelsBaseUrl,
                endpoint: `api/hotel/filter?offset=${offset}`,
                apiMethod: ApiMethods.get,
                successCodeExpectation: 200,
                requestData: {
                    token: { accessToken: accessToken },
                    headers: {
                        latitude: lat,
                        longitude: lng,
                        region: region,
                    },
                },
            }).then((response) => {
                if (typeof (response.error) === "undefined") {
                    return response.response;
                } else {
                    return [];
                }
            });
        },
        builder: (entity) => { return <HotelCard key={entity.id} hotel={entity} isUpdateTime={false} /> },
        mapCoordinates: (entity) => { return { lat: Number(entity.Latitude), lng: Number(entity.Longitude) } }
    },
    {
        key: 'home/tour',
        onFetchingEntities: async (offset) => {
            const restApiServices = new ApiProvider();
            return await restApiServices.provider({
                baseUrl: systemTourismBaseUrl,
                endpoint: `api/tourism/filter?offset=${offset}`,
                apiMethod: ApiMethods.get,
                successCodeExpectation: 200,
                requestData: {
                    token: { accessToken: accessToken },
                    headers: {
                        latitude: lat,
                        longitude: lng,
                        region: region,
                    },
                },
            }).then((response) => {
                if (typeof (response.error) === "undefined") {
                    return response.response;
                } else {
                    return [];
                }
            });
        },
        builder: (entity) => { return <TourSiteCard key={entity.id} tourSite={entity} isUpdateTime={false} /> },
        mapCoordinates: (entity) => { return { lat: Number(entity.Latitude), lng: Number(entity.Longitude) } }
    },
];

export const myCategories = ({ lat = 0.0, lng = 0.0, accessToken = '', region = 'Unknown' }) => [
    {
        key: 'my/houses',
        onFetchingEntities: async (offset) => {
            const restApiServices = new ApiProvider();
            return await restApiServices.provider({
                baseUrl: systemHousesBaseUrl,
                endpoint: `api/building/published-building?offset=${offset}`,
                apiMethod: ApiMethods.get,
                successCodeExpectation: 200,
                requestData: {
                    token: { accessToken: accessToken },
                    headers: {
                        latitude: lat,
                        longitude: lng,
                        region: region,
                    },
                },
            }).then((response) => {
                if (typeof (response.error) === "undefined") {
                    return response.response;
                } else {
                    return [];
                }
            });
        },
        builder: (entity) => { return <HouseCard key={entity.id} house={entity} isUpdateTime={true} /> },
        mapCoordinates: (entity) => { return { lat: Number(entity.Latitude), lng: Number(entity.Longitude) } }
    },
    {
        key: 'my/hotel',
        onFetchingEntities: async (offset) => {
            const restApiServices = new ApiProvider();
            return await restApiServices.provider({
                baseUrl: systemHotelsBaseUrl,
                endpoint: `api/hotel/published-hotel?offset=${offset}`,
                apiMethod: ApiMethods.get,
                successCodeExpectation: 200,
                requestData: {
                    token: { accessToken: accessToken },
                    headers: {
                        latitude: lat,
                        longitude: lng,
                        region: region,
                    },
                },
            }).then((response) => {
                if (typeof (response.error) === "undefined") {
                    return response.response;
                } else {
                    return [];
                }
            });
        },
        builder: (entity) => { return <HotelCard key={entity.id} hotel={entity} isUpdateTime={true} /> },
        mapCoordinates: (entity) => { return { lat: Number(entity.Latitude), lng: Number(entity.Longitude) } }
    },
    {
        key: 'my/tour',
        onFetchingEntities: async (offset) => {
            const restApiServices = new ApiProvider();
            return await restApiServices.provider({
                baseUrl: systemTourismBaseUrl,
                endpoint: `api/tourism/published-tourism/?offset=${offset}`,
                apiMethod: ApiMethods.get,
                successCodeExpectation: 200,
                requestData: {
                    token: { accessToken: accessToken },
                    headers: {
                        latitude: lat,
                        longitude: lng,
                        region: region,
                    },
                },
            }).then((response) => {
                if (typeof (response.error) === "undefined") {
                    return response.response;
                } else {
                    return [];
                }
            });
        },
        builder: (entity) => { return <TourSiteCard key={entity.id} tourSite={entity} isUpdateTime={true} /> },
        mapCoordinates: (entity) => { return { lat: Number(entity.Latitude), lng: Number(entity.Longitude) } }
    },
];

export const faveCategories = ({ lat = 0.0, lng = 0.0, accessToken = '', region = 'Unknown' }) => [
    {
        key: 'fav/houses',
        onFetchingEntities: async (offset) => {
            const restApiServices = new ApiProvider();
            const favorites = await restApiServices.provider({
                baseUrl: systemFavoriteBaseUrl,
                endpoint: `api/building/my-favorite?offset=${offset}`,
                apiMethod: ApiMethods.get,
                successCodeExpectation: 200,
                requestData: {
                    token: { accessToken: accessToken },
                },
            }).then((response) => {
                if (typeof (response.error) === "undefined") {
                    return response.response;
                } else {
                    return [];
                }
            });

            return favorites.length ? await restApiServices.provider({
                baseUrl: systemHousesBaseUrl,
                endpoint: `api/building/view-list`,
                apiMethod: ApiMethods.post,
                successCodeExpectation: 200,
                requestData: {
                    token: { accessToken: accessToken },
                    body: favorites?.map((e) => { return e.BuildingId })
                },
            }).then((response) => {
                if (typeof (response.error) === "undefined") {
                    return favorites.map((e) => {
                        const building = response.response.find((element) => Number(element.id) === Number(e.BuildingId));
                        return typeof building !== 'undefined' ? {
                            ...building,
                            id: e.id,
                            houseId: building.id,
                        } : null;
                    }).filter((e) => e!= null);
                } else {
                    return [];
                }
            }) : [];
        },
        builder: (entity) => { return <HouseCard key={entity.id} house={entity} isUpdateTime={false} isFavorite={true} /> },
        mapCoordinates: (entity) => { return { lat: Number(entity.Latitude), lng: Number(entity.Longitude) } }
    },
    {
        key: 'fav/hotel',
        onFetchingEntities: async (offset) => {
            const restApiServices = new ApiProvider();
            const favorites = await restApiServices.provider({
                baseUrl: systemFavoriteBaseUrl,
                endpoint: `api/hotel/my-favorite?offset=${offset}`,
                apiMethod: ApiMethods.get,
                successCodeExpectation: 200,
                requestData: {
                    token: { accessToken: accessToken },
                },
            }).then((response) => {
                if (typeof (response.error) === "undefined") {
                    return response.response;
                } else {
                    return [];
                }
            });

            return favorites.length ? await restApiServices.provider({
                baseUrl: systemHotelsBaseUrl,
                endpoint: `api/hotel/view-list`,
                apiMethod: ApiMethods.post,
                successCodeExpectation: 200,
                requestData: {
                    token: { accessToken: accessToken },
                    body: favorites?.map((e) => { return e.HotelId })
                },
            }).then((response) => {
                if (typeof (response.error) === "undefined") {
                    return favorites.map((e) => {
                        const Hotel = response.response.find((element) => Number(element.id) === Number(e.HotelId));
                        return typeof Hotel !== 'undefined' ? {
                            ...Hotel,
                            id: e.id,
                            hotelId: Hotel.id,
                        } : null;
                    }).filter((e) => e!= null);
                } else {
                    return [];
                }
            }) : [];
        },
        builder: (entity) => { return <HotelCard key={entity.id} hotel={entity} isUpdateTime={false} isFavorite={true} /> },
        mapCoordinates: (entity) => { return { lat: Number(entity.Latitude), lng: Number(entity.Longitude) } }
    },
    {
        key: 'fav/tour',
        onFetchingEntities: async (offset) => {
            const restApiServices = new ApiProvider();
            const favorites = await restApiServices.provider({
                baseUrl: systemFavoriteBaseUrl,
                endpoint: `api/tourism/my-favorite?offset=${offset}`,
                apiMethod: ApiMethods.get,
                successCodeExpectation: 200,
                requestData: {
                    token: { accessToken: accessToken },
                },
            }).then((response) => {
                if (typeof (response.error) === "undefined") {
                    return response.response;
                } else {
                    return [];
                }
            });

            return favorites.length ? await restApiServices.provider({
                baseUrl: systemTourismBaseUrl,
                endpoint: `api/tourism/view-list`,
                apiMethod: ApiMethods.post,
                successCodeExpectation: 200,
                requestData: {
                    token: { accessToken: accessToken },
                    body: favorites?.map((e) => { return e.TourismId })
                },
            }).then((response) => {
                if (typeof (response.error) === "undefined") {
                    return favorites.map((e) => {
                        const Tourism = response.response.find((element) => Number(element.id) === Number(e.TourismId));
                        return typeof Tourism !== 'undefined' ? {
                            ...Tourism,
                            id: e.id,
                            tourismId: Tourism.id,
                        } : null;
                    }).filter((e) => e!= null);
                } else {
                    return [];
                }
            }) : [];
        },
        builder: (entity) => { return <TourSiteCard key={entity.id} tourSite={entity} isUpdateTime={false} isFavorite={true} /> },
        mapCoordinates: (entity) => { return { lat: Number(entity.Latitude), lng: Number(entity.Longitude) } }
    },
];

export const randomUserCategories = ({ id = 0, lat = 0, lng = 0, accessToken = '', region = 'Unknown' }) => [
    {
        key: 'userss/houses',
        onFetchingEntities: async (offset) => {
            const restApiServices = new ApiProvider();
            return await restApiServices.provider({
                baseUrl: systemHousesBaseUrl,
                endpoint: `api/building/publisher-building/${id}?offset=${offset}`,
                apiMethod: ApiMethods.get,
                successCodeExpectation: 200,
                requestData: {
                    token: { accessToken: accessToken },
                    headers: {
                        latitude: lat,
                        longitude: lng,
                        region: region,
                    },
                },
            }).then((response) => {
                if (typeof (response.error) === "undefined") {
                    return response.response;
                } else {
                    return [];
                }
            });
        },
        builder: (entity) => { return <HouseCard key={entity.id} house={entity} isUpdateTime={false} /> },
        mapCoordinates: (entity) => { return { lat: Number(entity.Latitude), lng: Number(entity.Longitude) } }
    },
    {
        key: 'userss/hotel',
        onFetchingEntities: async (offset) => {
            const restApiServices = new ApiProvider();
            return await restApiServices.provider({
                baseUrl: systemHotelsBaseUrl,
                endpoint: `api/hotel/publisher-hotel/${id}?offset=${offset}`,
                apiMethod: ApiMethods.get,
                successCodeExpectation: 200,
                requestData: {
                    token: { accessToken: accessToken },
                    headers: {
                        latitude: lat,
                        longitude: lng,
                        region: region,
                    },
                },
            }).then((response) => {
                if (typeof (response.error) === "undefined") {
                    return response.response;
                } else {
                    return [];
                }
            });
        },
        builder: (entity) => { return <HotelCard key={entity.id} hotel={entity} isUpdateTime={false} /> },
        mapCoordinates: (entity) => { return { lat: Number(entity.Latitude), lng: Number(entity.Longitude) } }
    },
    {
        key: 'userss/tour',
        onFetchingEntities: async (offset) => {
            const restApiServices = new ApiProvider();
            return await restApiServices.provider({
                baseUrl: systemTourismBaseUrl,
                endpoint: `api/tourism/publisher-tourism/${id}?offset=${offset}`,
                apiMethod: ApiMethods.get,
                successCodeExpectation: 200,
                requestData: {
                    token: { accessToken: accessToken },
                    headers: {
                        latitude: lat,
                        longitude: lng,
                        region: region,
                    },
                },
            }).then((response) => {
                if (typeof (response.error) === "undefined") {
                    return response.response;
                } else {
                    return [];
                }
            });
        },
        builder: (entity) => { return <TourSiteCard key={entity.id} tourSite={entity} isUpdateTime={false} /> },
        mapCoordinates: (entity) => { return { lat: Number(entity.Latitude), lng: Number(entity.Longitude) } }
    },
];