import React from 'react';
import { Fragment } from 'react/cjs/react.production.min';
import './listViewBuilder.css';


export default class ListViewBuilder extends React.Component {

    state = {
        items: [],
    }

    builder({ itemCount, itemBuilder }) {
        return Array.from({ length: itemCount }, (_, i) => {
            return itemBuilder(i);
        })
    }

    handleScroll = (event) => {
        const {scrollTop, scrollHeight, clientHeight} = event.target;
        if(scrollHeight - scrollTop === clientHeight){
            this.props.onReachBottom();
        }
    }

    componentDidMount() {
        this.setState({ items: this.builder({ itemCount: this.props.itemCount, itemBuilder: this.props.itemBuilder }) })
    }

    componentDidUpdate(prevProps) {
        if (this.props.itemCount !== prevProps.itemCount && this.props.itemBuilder !== prevProps.itemBuilder) {
            this.setState({ items: this.builder({ itemCount: this.props.itemCount, itemBuilder: this.props.itemBuilder }) })
        }
    }

    render() {
        return <div className='listViewBuilder' onScroll={this.handleScroll} style={{ padding: `${this.props.padding}rem`, gridGap: `${this.props.gridGap}rem`, flexDirection: `${this.props.scrollDirection}` }}>
            {[...this.state.items].map((e, index) => { return <Fragment key={index}>{e}</Fragment> })}
        </div>
    }
}