import React from 'react';
import { connect } from 'react-redux';
import { systemHousesBaseUrl } from '../../../config/config';
import { CalcCrow, capitalize } from '../../../utils/utils';
import DetailsModal from '../../pages/details_modal/details_modal';
import CustomCarousel from '../caroussel/caroussel';
import DetailsDisplayer from '../details_displayer/details_displayer';
import Numeral from 'react-numeral';
import './house_card.css';
import HouseDetails from './house_details';
import UpdateHouse from './update_house';


class HouseCard extends React.Component {

    state = {
        isDetailsDisplayed: false,
        isUpdateTime: false,
        isAsyncCall: false,
        currentHouse: {},
        hasBeenRemoved: false,
    }

    render() {
        const { id, houseId, Actif, SaleDetail, Rental, Parking, Latitude, Longitude, BedRooms, BathRoom, BuildingType, Images } = this.props.house;
        const house = this.state.currentHouse;
        return this.state.hasBeenRemoved
            ? <></>
            : this.props.house ? <div key={id} className='houses_card' >
                {(this.props.isUpdateTime && !Actif) ? <div className='show_actif_status' id={`${!Actif && 'show_actif_status_disabled'}`}>{Actif ? <i className='fa fa-eye' /> : <i className='fa fa-eye-slash' />}</div> : <></>}
                {/* {(this.props.isFavorite)
                    ? <div className='show_actif_status remove_fav' id={`${this.props.isFavorite && 'show_actif_status_disabled'}`} onClick={(event) => {
                        event.stopPropagation();
                        this.removeToFavorite({
                            entityId: id, onSucess: () => {
                                this.setState({ hasBeenRemoved: true });
                            }
                        });
                    }}>
                        {this.state.isAsyncCall ? <i className='fa fa-spinner fa-spin' /> : <i className='fa fa-trash' />}
                    </div> : <></>} */}
                {this.state.isDetailsDisplayed && <DetailsModal
                    body={<DetailsDisplayer detailsOptions={{
                        entityId: houseId ? houseId : id,
                        images: Images,
                        baseUrl: systemHousesBaseUrl,
                        entityEndpoint: 'building/detail',
                        likeEndpoint: 'like',
                        unlikeEndpoint: 'unlike',
                        commentBody: { BuildingId: houseId ? houseId : id },
                        bookmarkEndpoint: 'building',
                        entityDetailsUi: <HouseDetails building={house} />,
                        commentEndpoint: 'comment/add-comment',
                        deleteCommentEndpoint: 'comment/remove-comment',
                        fetchingCommentsEnpoint: 'comment/filter-comment',
                        reportEndpoint: 'add-report-house',
                        reportKey: 'idhouse',
                        onObjectReady: (object) => { this.setState({ currentHouse: object }) },
                    }} />}
                    onClose={() => { this.setState({ isDetailsDisplayed: !this.state.isDetailsDisplayed }) }} />
                }
                {this.state.isUpdateTime && <DetailsModal
                    body={<UpdateHouse building={this.props.house} onDeleteObject={() => {
                        this.setState({ isUpdateTime: !this.state.isUpdateTime })
                    }} />}
                    onClose={() => { this.setState({ isUpdateTime: false }) }}
                />
                }
                <CustomCarousel images={Images ? [...Images].map((e) => e.Url) : []} />
                <div className='houses_card_details' onClick={(event) => {
                    event.stopPropagation();
                    if (this.props.isUpdateTime) {
                        this.setState({ isUpdateTime: !this.state.isUpdateTime })
                    } else {
                        this.setState({ isDetailsDisplayed: !this.state.isDetailsDisplayed })
                    }
                }}>
                    <div className='houses_card_details_price_like flex'>
                        <div className='houses_card_details_pricing_table'>
                            {SaleDetail ? <div className='houses_card_details_pricing_table_left'>
                                <p>{{
                                    fr: "Prix de vente",
                                    en: "Sale price",
                                    sw: "Bei ya kuuza"
                                }[`${this.props.currentLanguage}`]}</p>
                                <h5>{this.props?.currencies.filter((e) => e.id === Number(SaleDetail.Currency))[0]?.Name} <Numeral value={SaleDetail.Price} format={'0.0a'} /></h5>
                            </div> : <></>}
                            {Rental ? <div className='houses_card_details_pricing_table_left'>
                                <p>{{
                                    fr: "Prix de location",
                                    en: "Rental price",
                                    sw: "Bei ya kukodisha"
                                }[`${this.props.currentLanguage}`]}</p>
                                <h5>{this.props?.currencies.filter((e) => e.id === Number(Rental.Currency))[0]?.Name} <Numeral value={Rental.Price} format={'0.0a'} />/{String(Rental.RentalType[capitalize(this.props.currentLanguage)]).toLocaleLowerCase()}</h5>
                            </div> : <div></div>}
                        </div>
                    </div>
                    <div className='houses_card_details_more flex'>
                        <i className='fa fa-map-location-dot' />
                        <h6><Numeral value={CalcCrow(Latitude, Longitude, this.props.userLocation?.lat, this.props.userLocation?.lon)} format={'0.0a'} />{{
                            fr: " Kilomètre de loins",
                            en: " Kilometer away",
                            sw: " Kilomita mbali"
                        }[`${this.props.currentLanguage}`]}</h6>
                    </div>
                    <div className='houses_card_details_more flex'>
                        <i className='fa fa-house' />
                        <h6>{BuildingType ? BuildingType[capitalize(this.props.currentLanguage)] : ''}</h6>
                    </div>
                    <div className='houses_card_details_more flex'>
                        <div className='houses_card_details_more flex'>
                            <i className='fa fa-bed' />
                            <h6>{[...BedRooms ?? []].length}</h6>
                        </div>
                        <div className='houses_card_details_more flex'>
                            <i className='fa fa-bath' />
                            <h6>{BathRoom ?? 0}</h6>
                        </div>
                        <div className='houses_card_details_more flex'>
                            <i className='fa fa-car' />
                            <h6>{Parking ?? 0}</h6>
                        </div>
                    </div>
                </div>
            </div> : <></>
    }
}

const mapStateToProps = (storeOf) => ({
    currentLanguage: storeOf.madoshome.currentLanguage.code,
    userInformation: storeOf.madoshome.userInformation,
    userLocation: storeOf.madoshome.userLocation,
    currencies: storeOf.madoshome.currencies,
});

export default connect(mapStateToProps)(HouseCard);