import './authentication.css';
import React, { createRef } from 'react';
import ApiProvider, { ApiMethods } from '../../../data/providers/api_services';
import { appName, systemAuthBaseUrl } from '../../../config/config';
import { copyWith } from '../../../logic/slice';
import axios from 'axios';
import { capitalize, getErrorMessage, isEmpty } from '../../../utils/utils';
import { connect } from 'react-redux';
import Notifier, { Notifierpopup } from '../../components/notifier/notifier';
import ManagerRepo from '../../../data/repositories/managerRepo';
import Joi from "joi";
import ForgetPassword from './forget_password';
import SignUpVerification from './sign_up_verification';
import HideandShowInput from './hideandshow';
import Illustration from '../../../assets/svgs/onSuccess.svg'
import IllustrationError from '../../../assets/svgs/accessDenied.svg'
import IllustrationForgetPassword from '../../../assets/svgs/successRegistration.svg'


class Authentication extends React.Component {

    state = {
        isLogin: true,
        formValues: {},
        currentPassword: '',
        currentMail: '',
        errorMessage: '',
        infoMessage: '',
        validationError: '',
        successForgetPassword: '',
        isAsyncCall: false,
        successState: false,
        errorState: false,
        isVerifyMailTime: false,
        didForgetPassword: false,
        isLoginTime: true,
    }

    getJoiSchemaValues(isLogin) {
        const loginFormValues = {
            Username: Joi.string().required(),
            Password: Joi.string().required(),
        };
        return isLogin ? loginFormValues : {
            ...loginFormValues,
            ConfirmPassword: Joi.string().required().valid(Joi.ref('Password')),
            Fullname: Joi.string().required(),
            Email: Joi.string().required().email({ tlds: { allow: false } }),
            PhoneNumber: Joi.string().required(),
            WhatsappNumber: Joi.string().required(),
            UserTypeId: Joi.number().required(),
            ProfilImage: Joi.string().default(`https://avatars.dicebear.com/api/personas/male/${Math.floor(Math.random() * 4777777770)}.png`),
        }
    }

    formRef = createRef();

    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState(state => ({ ...state, formValues: { ...state.formValues, [name]: value } }));
    }

    componentWillUnmount() {
        this.source.cancel();
    }

    render() {
        const waitingmsg = {
            'fr': "Patientez s'il vous plait...",
            'en': "Please wait...",
            'sw': "Tafadhali subiri..."
        }[`${this.props.currentLanguage}`]

        const logintext = {
            'fr': "Se connecter",
            'en': "Log In",
            'sw': "Ingia"
        }[`${this.props.currentLanguage}`]
        const registertext = {
            'fr': "S'inscrire",
            'en': "Register ",
            'sw': "Ji andikishe"
        }[`${this.props.currentLanguage}`]

        const dontHaveaccount = {
            fr: "Vous n'avez pas de compte ?",
            en: "Don't you have  an account ?",
            sw: "Je, una akaunti ?"
        }[`${this.props.currentLanguage}`]
        const hasAccount = {
            fr: "Vous avez déjà un compte ?",
            en: "Already have an account ?",
            sw: "Je, tayari una akaunti ?"
        }[`${this.props.currentLanguage}`]

        const successMessage = {
            fr: `Nous avons terminé votre inscription avec succès.
            Profitez du meilleur de ${appName}.`,
            en: `We have successfully completed your registration.
            Enjoy the best of ${appName}.`,
            sw: `Tumekamilisha usajili wako.
            Furahia bora zaidi za ${appName}.`
        }[this.props.currentLanguage]

        const successForgetPassword = {
            fr: `Votre mot de passe a ete changer avec succes`,
            en: `Your password has been changed successfully`,
            sw: `Neno Siri lako lime badilika vizuri`
        }[this.props.currentLanguage]



        const joiSchema = Joi.object(this.getJoiSchemaValues(this.state.isLogin));



        return (
            <>
                {this.state.successState && <Notifierpopup
                    Illustration={Illustration}
                    msg={successMessage}
                    onClose={() => {
                        this.setState({ isLoginTime: true, isLogin: true })
                    }}
                />}

                {!isEmpty(this.state.errorMessage) && <Notifierpopup
                    Illustration={IllustrationError}
                    msg={this.state.errorMessage}
                    onClose={() => {
                        this.setState({ isLoginTime: true })
                    }}
                />}

                {!isEmpty(this.state.successForgetPassword) && <Notifierpopup
                    Illustration={IllustrationForgetPassword}
                    msg={this.state.successForgetPassword}
                    onClose={() => {
                        this.setState({ isLoginTime: true })
                    }}
                />}



                {this.state.isLoginTime && <div className='modal_rideau'>
                    <div className="authentication_container dif_modal">
                        {this.state.didForgetPassword === true &&
                            <ForgetPassword
                                currentLanguage={this.props.currentLanguage}
                                onClosePressed={() => { this.setState({ didForgetPassword: !this.state.didForgetPassword, }) }}
                                onSuccessForgetPassword={(successMessage) => {
                                    successMessage && this.setState({ successForgetPassword: successForgetPassword, didForgetPassword: false, isLoginTime: false })
                                }}
                            />
                        }

                        {this.state.isVerifyMailTime === true &&
                            <SignUpVerification
                                email={this.state.currentMail}
                                otherValues={this.state.formValues}
                                onClosePressed={() => { this.setState({ isVerifyMailTime: !this.state.isVerifyMailTime }) }}
                                onUpdate={() => {
                                    this.formRef.current.reset();
                                    this.setState({ formValues: {}, isAsyncCall: false, successState: true, isLoginTime: false });
                                }}
                                onVerificationError={(errorMsgOnVerification) => {
                                    this.setState({ validationError: errorMsgOnVerification, isVerifyMailTime: false })
                                }}
                            />
                        }




                        <div className="header_login" >
                            <h3>{{
                                fr: "Rejoignez nous pour debloquer le meilleur de Madoshome",
                                en: "Joins us to unlock the best of Madoshome",
                                sw: "Ungana nasi ili ufunguwe utendaji bora wa madoshome"
                            }[`${this.props.currentLanguage}`]}
                            </h3>
                            <span id='cross_container' onClick={this.props.onClosePressed}>
                                <i className="fa-solid fa-xmark"></i>
                            </span>
                        </div>
                        <div className='divider' />



                        {!isEmpty(this.state.validationError) && <Notifier type='error' message={this.state.validationError} />}
                        {!isEmpty(this.state.infoMessage) && <Notifier type='info' message={this.state.infoMessage} />}


                        <fieldset disabled={this.state.isAsyncCall}>

                            <form autoComplete='off' ref={this.formRef} className='form_container' onChange={this.handleChange} onSubmit={(event) => {
                                event.preventDefault();
                                this.setState({ errorMessage: '', successMessage: '', infoMessage: '' });


                                joiSchema.validateAsync(this.state.formValues).then(() => {
                                    if (this.state.isLogin) {
                                        this.onAuth(this.state.formValues);

                                    } else {
                                        this.setState({ isVerifyMailTime: !this.state.isVerifyMailTime });
                                    }
                                }).catch((error) => {
                                    this.setState({ validationError: `${error.details.map((e) => e.message)}` });
                                });


                            }}>


                                <div className='LR_container'>
                                    {!this.state.isLogin && <>
                                        <input type="text" name="Fullname" placeholder={{
                                            fr: 'Nom complet',
                                            en: 'Full name',
                                            sw: 'Jina lote'
                                        }[`${this.props.currentLanguage}`]} />
                                        <select className="account_type" name="UserTypeId">
                                            <option value={0}>{{
                                                fr: 'Type du compte',
                                                en: 'Account Type',
                                                sw: 'Aina ya Akaunti'
                                            }[`${this.props.currentLanguage}`]}</option>
                                            {[...this.props.accountTypes].map((e) => {
                                                return <option key={e.id} value={e.id}>{e[capitalize(this.props?.currentLanguage)]}</option>
                                            })}
                                        </select>

                                        <input
                                            autoComplete="email"
                                            type="email"
                                            name="Email"
                                            placeholder={{
                                                fr: 'Adresse Mail',
                                                en: 'Mail Address',
                                                sw: 'Anwani ya barua pepe'
                                            }[`${this.props.currentLanguage}`]}
                                            onChange={(event) => { this.setState({ currentMail: event.target.value }); }}
                                        // onBlur={(event) => {
                                        //     if (!isEmpty(this.state.currentMail)) {
                                        //         console.log('Verifying...', this.state.currentMail);
                                        //     }
                                        // }} 
                                        />
                                    </>}
                                    <input type="text" name="Username" autoComplete="Username" placeholder={{
                                        fr: "Nom d'utilisateur",
                                        en: 'Username',
                                        sw: 'Jina la mtumiaji'
                                    }[`${this.props.currentLanguage}`]} />


                                    <HideandShowInput placeholder={{
                                        fr: "Mot de passe",
                                        en: 'Password',
                                        sw: 'Nenosiri'
                                    }[`${this.props.currentLanguage}`]} />


                                    {!this.state.isLogin && <>
                                        <input autoComplete="new-password" type="password" name="ConfirmPassword" placeholder={{
                                            fr: "Confirmfé votre mot de passe",
                                            en: 'Confirm your password',
                                            sw: 'Thibitisha nenosiri lako'
                                        }[`${this.props.currentLanguage}`]} />
                                        <div className="form_input_divider">
                                            <input type="tel" name="PhoneNumber" placeholder={{
                                                fr: "Numèro de téléphone",
                                                en: 'Phone number',
                                                sw: 'Numbari yako ya simu'
                                            }[`${this.props.currentLanguage}`]} />
                                            <input type="tel" name="WhatsappNumber" placeholder={{
                                                fr: "Numèro WhatsApp",
                                                en: 'WhatsApp Number',
                                                sw: 'Numbari yako ya WhatsApp'
                                            }[`${this.props.currentLanguage}`]} />
                                        </div>
                                    </>}
                                </div>



                                <div className="footer_authentication">
                                    <button className={`${this.state.isAsyncCall && 'button_on_async'}`} type={'submit'}>{this.state.isAsyncCall ? waitingmsg : this.state.isLogin ? logintext : registertext}</button>
                                    {this.state.isLogin && <h6 className='forget_password_h6' onClick={(event) => { event.stopPropagation(); this.setState({ didForgetPassword: !this.state.didForgetPassword }) }}>{{
                                        fr: 'Mot de passe oublié ?',
                                        en: 'Forgot password ? ',
                                        sw: 'Umesahau nywila ?'
                                    }[`${this.props.currentLanguage}`]}</h6>}
                                    <p>{this.state.isLogin ? dontHaveaccount : hasAccount} <span className='forget_password_h6'
                                        onClick={() => {
                                            this.setState({
                                                successMessage: '', validationError: '', isLogin: !this.state.isLogin, formValues: {}
                                            }); this.formRef.current.reset();
                                        }}>{this.state.isLogin ? registertext : logintext}</span></p>

                                    {!this.state.isLogin && <div className='have_an_account_or_not'>
                                        <p>
                                            {{
                                                fr: " En continuant, vous acceptez nos ",
                                                en: " By continuing you agree to our ",
                                                sw: " Kwa kuendelea unakubaliana na "
                                            }[`${this.props.currentLanguage}`]}
                                            <span className='forget_password_h6'>
                                                <a style={{ color: "var(--primaryColor)" }} href={`https://madoshome.com/terms-and-conditions/${this.props.currentLanguage === 'en' ? "" : this.props.currentLanguage + "/"}`} target={"_blank"} rel={"noreferrer"}>{{
                                                    fr: 'Conditions et termes ',
                                                    en: 'Terms and conditions ',
                                                    sw: 'Masharti '
                                                }[`${this.props.currentLanguage}`]}</a>
                                            </span>
                                            {{
                                                fr: 'et ',
                                                en: 'and ',
                                                sw: 'na '
                                            }[`${this.props.currentLanguage}`]}
                                            <span className='forget_password_h6'>
                                                <a style={{ color: "var(--primaryColor)" }} href={`https://madoshome.com/privacy-policy/${this.props.currentLanguage === 'en' ? "" : this.props.currentLanguage + "/"}`} target={"_blank"} rel={"noreferrer"}>
                                                    {{
                                                        fr: "Politique de confidentialité",
                                                        en: "Privacy policy",
                                                        sw: "Sera ya faragha"
                                                    }[`${this.props.currentLanguage}`]}</a>
                                            </span>
                                        </p>
                                    </div>}
                                </div>
                            </form>
                        </fieldset>
                    </div>
                </div>}


            </>


        )

    }

    source = axios.CancelToken.source();
    onAuth(payload) {
        this.setState({ isAsyncCall: true, errorMessage: '', successMessage: '', infoMessage: '' });
        const restApiServices = new ApiProvider();
        restApiServices.provider({
            baseUrl: systemAuthBaseUrl,
            endpoint: this.state.isLogin ? 'api/signin' : 'api/signup',
            apiMethod: ApiMethods.post,
            successCodeExpectation: 200,
            cancelToken: this.source.token,
            requestData: {
                body: payload,
            },
        }).then((response) => {
            if (typeof (response.error) === 'undefined') {
                this.formRef.current.reset();
                this.setState({ isAsyncCall: false });
                this.props.onClosePressed()
                if (this.state.isLogin) {
                    const { refreshToken, accessToken } = response.response.user;
                    const managerRepo = new ManagerRepo();
                    managerRepo.onSetRefreshToken({ refreshToken: refreshToken ?? '', accessToken: accessToken ?? '' });
                    this.props.dispatch(copyWith({
                        userInformation: response.response.user,
                        refreshToken: refreshToken ?? '',
                        accessToken: accessToken ?? '',
                    }));
                }
            } else {
                console.log(response.error);
                if (this.state.isLogin) {
                    const hasChecker = typeof response.error?.user !== 'undefined';
                    this.setState({ isAsyncCall: false, errorMessage: `${hasChecker ? 'Wrong username or password' : getErrorMessage(response.error, 'en')}`, isLoginTime: false });
                } else {
                    this.setState({ isAsyncCall: false, errorMessage: getErrorMessage(response.error, 'en') });
                }

            }
        }).catch((e) => {
            this.setState({ isAsyncCall: false, errorMessage: e })
        });
    }
}

const mapStateToProps = (storeOf) => ({
    currentLanguage: storeOf.madoshome.currentLanguage.code,
    accountTypes: storeOf.madoshome.accountTypes,
});

export default connect(mapStateToProps)(Authentication);