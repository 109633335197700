import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import './bed_room_input.css';




function copyWith(oldRoom, newRoom) {
    return {
        BathRoom: newRoom.BathRoom ?? oldRoom.BathRoom,
        Wardrobe: newRoom.Wardrobe ?? oldRoom.Wardrobe,
        Balcony: newRoom.Balcony ?? oldRoom.Balcony,
    }
}

function newRooms(rooms, updatedRoom, index) {
    var myRooms = rooms;
    let upR = copyWith(myRooms[index], updatedRoom);
    myRooms[index] = upR;
    return myRooms;
}

export default function BedRoomsInput({ isDisabled = false, intialValue = [], onChange = () => { } }) {
    const defaultRoomValue = { BathRoom: false, Wardrobe: false, Balcony: false };
    const [generatedRooms, setRooms] = useState([]);
    const currentLanguage = useSelector(state => state.madoshome.currentLanguage.code)

    useEffect(() => {
        if (intialValue.length) {
            setRooms(intialValue);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [intialValue])

    return <div className="bedroom_input_new">
        <div className='bedRoom_input_title'>
            <p className=''>{
                {
                    fr: "Chambre",
                    en: "Bedroom",
                    sw: "Vyumba"
                }[`${currentLanguage}`]
            }{`${generatedRooms.length > 1 ? 's' : ''}`}</p>

            <input type='number' value={generatedRooms.length * 1} onChange={(event) => {
                event.stopPropagation();
                setRooms(Array.from({ length: event.target.value > 10 ? 10 : event.target.value }, (_, i) => {
                    return defaultRoomValue;
                }));
                onChange(Array.from({ length: event.target.value > 10 ? 10 : event.target.value }, (_, i) => {
                    return defaultRoomValue;
                }));
            }} />
        </div>
        {generatedRooms.map((e, i) => {
            return <div key={i} className='room_item'>
                <p className=''>{i + 1}</p>
                <div>
                    <fieldset disabled={isDisabled} className="checkbox_input_holder_disabled_holder">
                        <div className="checkbox_input_holder">
                            <input type='checkbox' checked={e.BathRoom} onChange={(event) => {
                                event.stopPropagation();
                                const updatedRoom = { ...e, BathRoom: event.target.checked };
                                setRooms(newRooms(generatedRooms, updatedRoom, i));
                                onChange(generatedRooms);
                            }} />
                            <label>{{
                                fr: "Douche",
                                en: "Bathroom",
                                sw: "Bafuni"
                            }[`${currentLanguage}`]}</label>
                        </div>
                        <div className="checkbox_input_holder">
                            <input type='checkbox' checked={e.Wardrobe} onChange={(event) => {
                                event.stopPropagation();
                                const updatedRoom = { ...e, Wardrobe: event.target.checked };
                                setRooms(newRooms(generatedRooms, updatedRoom, i));
                                onChange(generatedRooms);
                            }} />
                            <label>{{
                                fr : "Pendrie",
                                en : "Wardrobe",
                                sw : "Kabati la nguo"
                            }[`${currentLanguage}`]}</label>
                        </div>
                        <div className="checkbox_input_holder">
                            <input type='checkbox' checked={e.Balcony} onChange={(event) => {
                                event.stopPropagation();
                                console.log("Balcony Clicked", event.target.checked)
                                const updatedRoom = { ...e, Balcony: event.target.checked };
                                setRooms(newRooms(generatedRooms, updatedRoom, i));
                                onChange(generatedRooms);
                            }} />
                            <label>{{
                                fr : "Balcon",
                                en : "Balcony",
                                sw : "Balcony"
                            }[`${currentLanguage}`]}</label>
                        </div>
                    </fieldset>
                </div>
            </div>
        })}
    </div>
}