import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { deleteFilesS3 } from "../../../utils/utils";

class DeleteEntityConfirmation extends React.Component {

    state = {
        isAsyncCall: false,
        isDeleteMediaTime: false,
        deletedMedia: 0,
    }


    render() {
        const waitingmsg = {
            'fr': "Patientez s'il vous plait...",
            'en': "Please wait...",
            'sw': "Tafadhali subiri..."
        }[`${this.props.currentLanguage}`]

        const deleteRooms = {
            'fr': "Supprimer ",
            'en': "Delete ",
            'sw': "Futa"
        }[`${this.props.currentLanguage}`]
        const length = this.props?.images?.length;
        const media = [...this.props?.images ?? [], this.props.video ? { id: 'video_tour_undefined', Url: this.props.video } : {}]
        return <div className="modal_rideau">
            <div className="dif_modal">
                <div style={{ padding: '1rem' }}>
                    <div className="flex call_to_subcsription_header update_user_informations_header">
                        <h2>{{
                            fr : 'Supprimer',
                            en : 'Delete',
                            sw : 'Vuta'
                        }[this.props.currentLanguage]}</h2>
                        <span id='cross_container' onClick={() => { this.props.onClosePressed() }}>
                            <i className="fa-solid fa-xmark"></i>
                        </span>
                    </div>
                    <div className="divider" />
                    <p>{{
                        fr : `En procédant, cette opération supprimera toutes les informations de cette publication dans notre base de données. Et c'est irreversible.`,
                        en : 'This will remove all of the informations about the post from our database. This cannot be undone.',
                        sw : 'Kwa kuendelea, operesheni hii itafuta taarifa zote za chapisho hili katika hifadhidata yetu. Na hii haiwezi kutenduliwa.'
                    }[this.props.currentLanguage]}</p>
                    <div className="divider" />
                    {media.map((e) => {
                        return Object.keys(e).length ? <MediaFile id={e.id} url={e.Url} isDeleteTime={this.state.isDeleteMediaTime} onDelete={() => {
                            if (length === Number(this.state.deletedMedia) + 1) {
                                this.setState({ isAsyncCall: false, isDeleteMediaTime: false });
                                this.props.onClosePressed();
                                this.props.onDeleteFinished();
                            } else {
                                this.setState({ deletedMedia: Number(this.state.deletedMedia) + 1 });
                            }
                        }} /> : <></>
                    })}
                    <button style={{ width: '100%' }} className={`${this.state.isAsyncCall ? 'button_on_async' : "delete_account_button"}`} onClick={(event) => {
                        event.preventDefault();
                        this.setState({ isAsyncCall: true });
                        this.props.onDelete().then((isDeleted) => {
                            if (isDeleted) {
                                if (media.filter((e)=> typeof e.Url !== 'undefined').length > 0) {
                                    this.setState({ isDeleteMediaTime: true });
                                } else {
                                    this.setState({ isAsyncCall: false, isDeleteMediaTime: false });
                                    this.props.onClosePressed();
                                    this.props.onDeleteFinished();
                                }
                            } else {
                                this.setState({ isAsyncCall: false });
                            }
                        });
                    }}>{this.state.isAsyncCall ? waitingmsg : deleteRooms }</button>
                </div>
            </div>
        </div>
    }
}

const mapStateToProps = (storeOf) => ({
    currentLanguage: storeOf.madoshome.currentLanguage.code,
    userInformation: storeOf.madoshome.userInformation,
    subscriptionPlans: storeOf.madoshome.subscriptionPlans,
});

export default connect(mapStateToProps)(DeleteEntityConfirmation);


function MediaFile({ url = '', isDeleteTime = false, onDelete = () => { } }) {

    const [hasUpload, setHasUpload] = useState(false);
    useEffect(() => {
        if (isDeleteTime) {
            if (!hasUpload) {
                setHasUpload(true);
                deleteFilesS3(url).then((isDeleted) => {
                    console.log('Deletion of', url, isDeleted);
                    onDelete();
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDeleteTime]);
    return <></>
}