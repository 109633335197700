import React, { Component } from 'react'
import { isEmpty, getErrorMessage, capitalize } from '../../../utils/utils';
import LocationPickerInput from '../location_picker/location_picker';
import Notifier from '../notifier/notifier';
import { connect } from "react-redux";
import ApiProvider, { ApiMethods } from "../../../data/providers/api_services";
import { systemTourismBaseUrl, tourismImagesS3FolderNAme } from "../../../config/config";
import { appName } from "../../../config/config";
import Joi from "joi";
import DetailsModal from '../../pages/details_modal/details_modal';
import DetailsDisplayer from '../details_displayer/details_displayer';
import Caroussel002 from '../caroussel/caroussel002';
import Loader from '../loader/loader';
import TourSiteDetails from './tour_site_details';
import TourServices from './tour_service';
import TourWorkingDay from './tour_working_day';
import DeleteEntity from '../delete_enity_confirm/delete_entity';
import TourPricing from './tour_pricing';
import { copyWith } from '../../../logic/slice';


class UpdateTourSite extends Component {

    componentDidMount() {
        this.GetEntityObject();
        if (!this.props.tourismTypes.length || !this.props.days.length) {
            this.utilsFetcher();
        }
    }

    getFormValues(tour) {
        const {
            TourismTypeId,
            Name,
            Latitude,
            Longitude,
            Address,
            History,
            Description,
            SiteWeb,
        } = tour;
        return {
            TourismTypeId: TourismTypeId ?? 0,
            Latitude: Latitude,
            Name: Name,
            Longitude: Longitude,
            Address: Address,
            Description: Description,
            History: History ?? '',
            SiteWeb: SiteWeb ?? '',
        }
    }



    state = {
        errorMessage: '',
        errorObjectMessage: '',
        successMessage: '',
        formValues: {},
        formValuesToSend: {},
        isDisabled: true,
        isAsyncCall: false,
        isAsyncObjectCall: true,
        isVisible: false,
        isDetailsDisplayed: false,
        wantToDeleteAccount: false,
        tour: {},
    }


    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState(state => ({ ...state, formValues: { ...state.formValues, [name]: value }, formValuesToSend: { ...state.formValuesToSend, [name]: value } }));
    }

    joiSchema = Joi.object({
        Name: Joi.string().required(),
        TourismTypeId: Joi.number().min(0),
        Latitude: Joi.number().not(0.0),
        Longitude: Joi.number().not(0.0),
        Address: Joi.string().required(),
        SiteWeb: Joi.string().allow(''),
        BuiltIn: Joi.number().min(0),
        Price: Joi.number().min(1),
        Currency: Joi.number(),
        Description: Joi.string().allow(''),
        History: Joi.string().allow(''),
    });

    render() {
        const isDisabled = this.state.isDisabled;
        const tour = this.state.tour;
        const startYear = new Date().getFullYear() - 100;
        return (this.state.isAsyncObjectCall || !isEmpty(this.state.errorObjectMessage)) ? <div className="details_displayer_screen_message_loader">
            {this.state.isAsyncObjectCall && <Loader />}
            <div className="divider" /><div className="divider" />
            {!isEmpty(this.state.errorObjectMessage) && <>
                <Notifier type='error' message={this.state.errorObjectMessage} />
                <div className="divider" />
            </>}
        </div> : (!this.state.isAsyncObjectCall && isEmpty(this.state.errorObjectMessage)) && <div className='update_houses_screen'>
            {this.state.isDetailsDisplayed && <DetailsModal
                body={<DetailsDisplayer detailsOptions={{
                    entityId: tour.id,
                    images: tour.Images,
                    baseUrl: systemTourismBaseUrl,
                    entityEndpoint: 'tourism/my-own-tourism-detail',
                    likeEndpoint: 'like',
                    unlikeEndpoint: 'unlike',
                    commentBody: { TourismId: tour.id },
                    entityDetailsUi: <TourSiteDetails tour={tour} />,
                    commentEndpoint: 'comment/add-comment',
                    deleteCommentEndpoint: 'comment/remove-comment',
                    fetchingCommentsEnpoint: 'comment/filter-comment',
                    reportEndpoint: 'add-report-Touristic',
                    bookmarkEndpoint: 'tourism',
                    reportKey: 'idTouristic',
                    onObjectReady: (object) => { this.setState({ tour: object }) },
                }} />}
                onClose={() => { this.setState({ isDetailsDisplayed: !this.state.isDetailsDisplayed }) }} />
            }
            {this.state.wantToDeleteAccount === true &&
                <DeleteEntity
                    images={tour.Images}
                    video={tour.Video}
                    onClosePressed={() => { this.setState({ wantToDeleteAccount: !this.state.wantToDeleteAccount }) }}
                    onDeleteFinished={() => {
                        this.props.onDeleteObject();
                    }}
                    onDelete={async () => {
                        this.setState({ isAsyncCall: true })
                        const restApiServices = new ApiProvider();
                        return await restApiServices.provider({
                            baseUrl: systemTourismBaseUrl,
                            endpoint: `api/tourism/remove-tourism-entity/${tour.id}`,
                            apiMethod: ApiMethods.delete,
                            successCodeExpectation: 200,
                            requestData: {
                                token: {
                                    accessToken: this.props.userInformation?.accessToken
                                }
                            },
                        }).then((response) => {
                            if (typeof (response.error) === "undefined") {
                                this.setState({ isAsyncCall: false });
                                return true;
                            } else {
                                this.setState({ isAsyncCall: false })
                                return false;
                            }
                        });
                    }}
                />
            }
            <div className='update_houses_screen_left'>
                <div className="update_building">
                    <div className="divider" />
                    <div className='update_actions'>
                        <i className='fa fa-rotate-right' onClick={(event) => {
                            event.stopPropagation();
                            this.setState({ errorMessage: '', successMessage: '', infoMessage: '' });
                            this.GetEntityObject();
                        }} />
                        <i className='fa fa-eye' onClick={(event) => {
                            event.stopPropagation();
                            this.setState({ isDetailsDisplayed: !this.state.isDetailsDisplayed });
                        }} />
                        {isDisabled && <i className='fa fa-lock' onClick={(event) => {
                            event.stopPropagation();
                            this.setState({ isDisabled: !isDisabled });

                        }} />}
                        {!isDisabled && <i id='spinner_loader' className='fa fa-unlock' onClick={(event) => {
                            event.stopPropagation();
                            if (Object.keys(this.state.formValuesToSend).length) {
                                this.setState({ errorMessage: '', successMessage: '', infoMessage: '' });
                                this.joiSchema.validateAsync(this.state.formValues).then((formValues) => {
                                    this.setState({ isDisabled: !isDisabled });
                                    this.onUpdate(this.state.formValuesToSend);
                                }).catch((error) => {
                                    this.setState({ errorMessage: `${error.details.map((e) => e.message)}` });
                                });
                            } else {
                                this.setState({ isDisabled: !isDisabled });
                            }
                        }} />}
                        <i className='fa fa-trash' onClick={(event) => {
                            event.stopPropagation();
                            this.setState({ wantToDeleteAccount: !this.state.wantToDeleteAccount });
                        }} />
                        {this.state.isAsyncCall && <i id='spinner_loader' className='fa-solid fa-spinner fa-spin' />}
                    </div>
                    {!isEmpty(this.state.errorMessage) && <>
                        <Notifier type='error' message={this.state.errorMessage} />
                        <div className="divider" />
                    </>}
                    {!isEmpty(this.state.successMessage) && <>
                        <Notifier type='success' message={this.state.successMessage} />
                        <div className="divider" />
                    </>}
                    <div className="update_building_form">
                        <fieldset disabled={isDisabled || this.state.isAsyncCall}>
                            <form onChange={this.handleChange}>
                                <div className="update_input">
                                    <h5>{{
                                        fr: "Dénomination",
                                        en: "Denomination",
                                        sw: "Dhehebu"
                                    }[this.props.currentLanguage]}</h5>
                                    <input name='Name' value={this.state.formValues.Name} onChange={(event) => { }} />
                                </div>
                                <div className="update_input">
                                    <h5>{{
                                        fr: "Type",
                                        en: "Type",
                                        sw: "Aina"
                                    }[this.props.currentLanguage]}</h5>
                                    <select className="account_type" name="TourismTypeId" value={Number(this.state.formValues.TourismTypeId)} onChange={(event) => { }}>
                                        <option value={0} disabled={true}>Type</option>
                                        {this.props.tourismTypes.map((e, i) => {
                                            return <option key={i} value={e.id}>{e[capitalize(this.props.currentLanguage)]}</option>
                                        })}
                                    </select>
                                </div>
                                <div className="update_input">
                                    <h5>{{
                                        fr: "Adresse",
                                        en: "Address",
                                        sw: "Anwani"
                                    }[`${this.props.currentLanguage}`]}</h5>
                                    <input name="Address" onChange={(event) => { }} value={this.state.formValues.Address} />
                                </div>
                                <div className="update_input">
                                    <h5>{{
                                        fr: "Coordonnées",
                                        en: "Cordinates",
                                        sw: "Kuratibu"
                                    }[`${this.props.currentLanguage}`]}</h5>
                                    <LocationPickerInput key={Math.random() * 28937923} isDisabled={isDisabled} intialValue={{ lat: this.state.formValues.Latitude, lng: this.state.formValues.Longitude }} onChange={(lat, lng) => {
                                        this.setState(state => ({ ...state, formValues: { ...state.formValues, Latitude: lat, Longitude: lng }, formValuesToSend: { ...state.formValuesToSend, Latitude: lat, Longitude: lng } }));
                                    }} />
                                </div>
                                <div className='row_inputs flex'>
                                    <div className="update_input">
                                        <h5>Built in</h5>
                                        <select onChange={(event) => { }} className="account_type" name="BuiltIn" value={this.state.formValues.BuiltIn}>
                                            <option value={0} disabled={true}>Built in</option>
                                            {Array.from({ length: new Date().getFullYear() - startYear }, (_, i) => {
                                                return new Date().getFullYear() - i;
                                            }).map((e, i) => {
                                                return <option key={i} value={Number(e)}>{e}</option>
                                            })}
                                        </select>
                                    </div>
                                </div>
                                <div className="update_input">
                                    <h5>{{
                                            fr : 'Site web (www.exemple.com)',
                                            en : 'Web site (www.example.com)',
                                            sw : 'Tovuti (www.mfano.com)'
                                        }[this.props.currentLanguage]}</h5>
                                    <input name='SiteWeb' value={this.state.formValues.SiteWeb} onChange={(event) => { }} />
                                </div>
                                <div className="update_input">
                                    <h5>{{
                                        fr : 'Historiques (Facultatif)',
                                        en : 'History (Optional)',
                                        sw : 'Historia (Si lazima)',
                                    }[this.props.currentLanguage]}</h5>
                                    <textarea name='History' onChange={(event) => { }} value={this.state.formValues.History} />
                                </div>
                                <div className="update_input">
                                    <h5>{{
                                        fr: 'Description',
                                        en: 'Description',
                                        sw: 'Maelezo'
                                    }[this.props.currentLanguage]}</h5>
                                    <textarea name='Description' onChange={(event) => { }} value={this.state.formValues.Description} />
                                </div>
                                <div className="divider" />
                            </form>
                        </fieldset>
                    </div>
                </div>
            </div>
            <div className='update_houses_screen_right'>
                <div style={{ height: '25rem', minHeight: '25rem', width: '100%' }}>
                    <Caroussel002
                        key={Math.random()}
                        max={15}
                        min={4}
                        hintText="Add images"
                        multiple={true}
                        extensions={['jpeg', 'jpg', 'png']}
                        initialValues={this.state.tour.Images}
                        video={this.state.tour.Video}
                        entityIdObject={{ TourismId: this.state.tour.id }}
                        builder={(index) => {
                            return Object.keys(this.state.tour.Images[index] ?? {}).length ? <img alt='' src={this.state.tour.Images[index]?.Url} style={{ height: '100%', width: '100%', objectFit: 'cover' }} /> : <></>
                        }}
                        uploadOptions={{
                            userUUID: this.props.userInformation.UID,
                            token: this.props.userInformation.accessToken,
                            field: tourismImagesS3FolderNAme,
                            baseUrl: systemTourismBaseUrl,
                            uploadEndpoint: `tourism/add-image`,
                            deleteEndpoint: (id) => `tourism/remove-image/${id}/${this.state.tour.id}`,
                            uploadVideoEndpoint: `tourism/update/${this.state.tour.id}`,
                        }}
                        onUpload={(urlObject) => {
                            this.setState(state => ({ tour: { ...state.tour, Images: [urlObject, ...this.state.tour.Images] } }));
                        }}
                        onDelete={(id) => {
                            this.setState(state => ({ tour: { ...state.tour, Images: state.tour.Images.filter((e) => e.id !== id) } }));
                        }}
                        onVideoChanged={(url) => {
                            this.setState(state => ({ tour: { ...state.tour, Video: url } }));
                        }}
                    />
                </div>
                <div className="divider" /><div className="divider" />
                <div className='enable_house_inputs'>
                    <div className='enable_house_inputs_item'>
                        <h5>{{
                            fr: 'Visible publiquement ',
                            en: 'Publicly visible',
                            sw: 'Inaonekana hadharani'
                        }[this.props.currentLanguage]}</h5>
                        <label className='switch'>
                            <input type='checkbox' checked={this.state.isVisible} value={this.state.isVisible} onChange={(event) => {
                                event.stopPropagation();
                                this.makeVisible(event.target.checked);
                            }} />
                            <span className='slider round' />
                        </label>
                    </div>
                    {this.state.isAsyncCall && <i id='spinner_loader' className='fa-solid fa-spinner fa-spin' />}
                </div>
                <div className='divider' />
                <TourPricing initialValue={tour.PriceDetail} tourId={tour.id} />
                <div className='divider' />
                <h2>{{
                       fr: "Services ou produits payants supplémentaires",
                       en: "Additional paid services or products ",
                       sw: "Huduma au bidhaa za ziada zinazolipwa "
                   }[this.props.currentLanguage]}</h2>
                <div className="divider" />
                {[...tour.Services ?? []].map((e) => {
                    return <TourServices
                        key={e.id}
                        initialValue={e}
                        isUpdate={true}
                        tourId={tour.id}
                        onUpdate={(service) => {
                            this.setState(state => ({ tour: { ...state.tour, Services: [...state.tour.Services.filter((e) => e.id !== service.id), service] } }));
                        }}
                        onDelete={(id) => {
                            this.setState(state => ({ tour: { ...state.tour, Services: state.tour.Services.filter((e) => e.id !== id) } }));
                        }} />
                })}
                <TourServices
                    initialValue={{}}
                    isUpdate={false}
                    tourId={tour.id}
                    onInsert={(service) => {
                        this.setState(state => ({ tour: { ...state.tour, Services: [service, ...this.state.tour.Services] } }));
                    }} />
                <div className='divider' />
                <h2>{{
                    fr : 'Jours ouverts',
                    en : 'Open days',
                    sw : 'Siku za wazi'
                }[this.props.currentLanguage]}</h2>
                <div className="divider" />
                {[...tour.Calendars ?? []].map((e) => {
                    return <TourWorkingDay
                        key={e.id}
                        days={this.props.days ?? []}
                        initialValue={e}
                        isUpdate={true}
                        tourId={tour.id}
                        onUpdate={(day) => {
                            this.setState(state => ({ tour: { ...state.tour, Calendars: [...state.tour.Calendars.filter((e) => e.id !== day.id), day] } }));
                        }}
                        onDelete={(id) => {
                            this.setState(state => ({ tour: { ...state.tour, Calendars: state.tour.Calendars.filter((e) => e.id !== id) } }));
                        }} />
                })}
                <TourWorkingDay
                    initialValue={{}}
                    days={this.props.days ?? []}
                    isUpdate={false}
                    tourId={tour.id}
                    onInsert={(day) => {
                        this.setState(state => ({ tour: { ...state.tour, Calendars: [day, ...this.state.tour.Calendars] } }));
                    }} />
                <div className="divider" />
            </div>
        </div>

    }

    utilsFetcher() {
        this.setState({ isAsyncCall: true });
        const restApiServices = new ApiProvider();
        return restApiServices.provider({
            baseUrl: systemTourismBaseUrl,
            endpoint: "api/utils",
            apiMethod: ApiMethods.get,
            successCodeExpectation: 200,
            requestData: {
                token: {
                    accessToken: localStorage.getItem(`${appName}act`) ?? ''
                }
            },
        }).then((response) => {
            if (typeof (response.error) === "undefined") {
                const { TourismType, Days } = response.response;
                this.props.dispatch(copyWith({ tourismTypes: TourismType ?? [], days: Days ?? [] }));
                this.setState({ isAsyncCall: false });
            } else {
                this.props.dispatch(copyWith({ tourismTypes: [], days: [] }));
                this.setState({ isAsyncCall: false });
            }
        });
    };

    GetEntityObject() {
        this.setState({ isAsyncObjectCall: true });
        const restApiServices = new ApiProvider();
        restApiServices.provider({
            baseUrl: systemTourismBaseUrl,
            endpoint: `api/tourism/my-own-tourism/${this.props.tour.id}`,
            apiMethod: ApiMethods.get,
            successCodeExpectation: 200,
            requestData: {
                token: {
                    accessToken: this.props.userInformation?.accessToken
                }
            },
        }).then((response) => {
            if (typeof (response.error) === "undefined") {
                const object = response.response;
                this.setState({
                    tour: object,
                    formValues: this.getFormValues(object),
                    isAsyncObjectCall: false,
                    isVisible: object.Actif ? true : false,
                });
            } else {
                this.setState({ isAsyncObjectCall: false, errorObjectMessage: getErrorMessage(response.error, 'en') });
            }
        });
    };


    makeVisible(state) {
        console.log(state);
        if (!this.state.isAsyncCall) {
            this.setState({ isAsyncCall: true });
            const restApiServices = new ApiProvider();
            restApiServices.provider({
                baseUrl: systemTourismBaseUrl,
                endpoint: `api/tourism/toggle-state/${this.state.tour.id}/${state}`,
                apiMethod: ApiMethods.patch,
                successCodeExpectation: 200,
                requestData: {
                    token: {
                        accessToken: localStorage.getItem(`${appName}act`) ?? ''
                    },
                    body: {},
                },
            }).then((response) => {
                if (typeof (response.error) === "undefined") {
                    this.setState({ isVisible: !this.state.isVisible, isAsyncCall: false });
                } else {
                    this.setState({ isAsyncCall: false, errorMessage: getErrorMessage(response.error, 'en') });
                }
            });
        }
    };


    onUpdate(values) {
        if (!this.state.isAsyncCall) {
            this.setState({ isAsyncCall: true });
            const restApiServices = new ApiProvider();
            restApiServices.provider({
                baseUrl: systemTourismBaseUrl,
                endpoint: `api/tourism/update/${this.state.tour.id}`,
                apiMethod: ApiMethods.patch,
                successCodeExpectation: 200,
                requestData: {
                    token: {
                        accessToken: localStorage.getItem(`${appName}act`) ?? ''
                    },
                    body: values,
                },
            }).then((response) => {
                if (typeof (response.error) === "undefined") {
                    this.setState({ formValuesToSend: {}, isAsyncCall: false });
                } else {
                    this.setState({ isAsyncCall: false, isDisabled: false, errorMessage: getErrorMessage(response.error, 'en') });
                }
            });
        }

    };
}

const mapStateToProps = (storeOf) => ({
    currentLanguage: storeOf.madoshome.currentLanguage.code,
    userInformation: storeOf.madoshome.userInformation,
    tourismTypes: storeOf.madoshome.tourismTypes,
    days: storeOf.madoshome.days,
});

export default connect(mapStateToProps)(UpdateTourSite);
