import { configureStore } from '@reduxjs/toolkit'
import nisysSlice from './slice'

export default configureStore({
    reducer: {
        madoshome: nisysSlice,
    },
})


