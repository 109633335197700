import React, { Component } from 'react'
import { isEmpty, getErrorMessage } from '../../../utils/utils';
import LocationPickerInput from '../location_picker/location_picker';
import Notifier from '../notifier/notifier';
import { connect } from "react-redux";
import ApiProvider, { ApiMethods } from "../../../data/providers/api_services";
import { hotelsImagesS3FolderNAme, systemHotelsBaseUrl } from "../../../config/config";
import { appName } from "../../../config/config";
import Joi from "joi";
import DetailsModal from '../../pages/details_modal/details_modal';
import DetailsDisplayer from '../details_displayer/details_displayer';
import Caroussel002 from '../caroussel/caroussel002';
import Loader from '../loader/loader';
import DeleteEntity from '../delete_enity_confirm/delete_entity';
import { HotelDetailsUI } from './hotel_details';
import HotelRoom from './hotel_rooms';
import { copyWith } from '../../../logic/slice';


class UpdateHotel extends Component {

    componentDidMount() {
        this.GetEntityObject();
        if (!this.props.ratingTypes.length || !this.props.roomTypes.length) {
            this.utilsFetcher();
        }
    }

    getFormValues(hotel) {
        const {
            Name,
            RatingId,
            Latitude,
            Longitude,
            Address,
            Description,
            Website,
            Email,
            BuiltIn,
            Contact,
            Info,
        } = hotel;
        return {
            Name: Name,
            RatingId: RatingId ?? 0,
            Latitude: Latitude,
            Longitude: Longitude,
            Address: Address,
            BuiltIn: BuiltIn,
            Website: Website ?? '',
            Email: Email ?? '',
            Contact: Contact ?? '',
            Description: Description,
            Info: Info,
        }
    }



    state = {
        errorMessage: '',
        errorObjectMessage: '',
        successMessage: '',
        formValues: {},
        formValuesToSend: {},
        isDisabled: true,
        isAsyncCall: false,
        isAsyncObjectCall: true,
        isVisible: false,
        isDetailsDisplayed: false,
        wantToDeleteAccount: false,
        hotel: {},
    }


    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState(state => ({ ...state, formValues: { ...state.formValues, [name]: value }, formValuesToSend: { ...state.formValuesToSend, [name]: value } }));
    }

    joiSchema = Joi.object({
        Name: Joi.string().required(),
        RatingId: Joi.number().min(0),
        Latitude: Joi.number().not(0.0),
        Longitude: Joi.number().not(0.0),
        Address: Joi.string().required(),
        BuiltIn: Joi.number().min(0),
        Website: Joi.string().allow(''),
        Email: Joi.string().allow(''),
        Contact: Joi.string().allow(''),
        Description: Joi.string().allow(''),
        Info: Joi.string().allow('', null),


    });

    render() {
        const isDisabled = this.state.isDisabled;
        const hotel = this.state.hotel;
        const startYear = new Date().getFullYear() - 100;
        return (this.state.isAsyncObjectCall || !isEmpty(this.state.errorObjectMessage)) ? <div className="details_displayer_screen_message_loader">
            {this.state.isAsyncObjectCall && <Loader />}
            <div className="divider" /><div className="divider" />
            {!isEmpty(this.state.errorObjectMessage) && <>
                <Notifier type='error' message={this.state.errorObjectMessage} />
                <div className="divider" />
            </>}
        </div> : (!this.state.isAsyncObjectCall && isEmpty(this.state.errorObjectMessage)) && <div className='update_houses_screen'>
            {this.state.isDetailsDisplayed && <DetailsModal

                body={<DetailsDisplayer detailsOptions={{
                    entityId: hotel.id,
                    images: hotel.HotelImages,
                    baseUrl: systemHotelsBaseUrl,
                    entityEndpoint: 'hotel/my-own-hotel-detail',
                    likeEndpoint: 'like',
                    unlikeEndpoint: 'unlike',
                    commentBody: { HotelId: hotel.id },
                    entityDetailsUi: <HotelDetailsUI
                        key={Math.random() * 9038023801}
                        hotel={hotel}
                        types={this.props.roomTypes}
                        currencies={this.props.currencies}
                    />,
                    commentEndpoint: 'comment/add-comment',
                    deleteCommentEndpoint: 'comment/remove-comment',
                    fetchingCommentsEnpoint: 'comment/filter-comment',
                    reportEndpoint: 'add-report-Hotel',
                    bookmarkEndpoint: 'hotel',
                    reportKey: 'idhotel',
                    onObjectReady: (object) => {
                        this.setState({ hotel: object })
                    },
                }} />}
                onClose={() => { this.setState({ isDetailsDisplayed: !this.state.isDetailsDisplayed }) }} />
            }
            {this.state.wantToDeleteAccount === true &&
                <DeleteEntity
                    images={hotel.HotelImages}
                    video={hotel.Video}
                    onClosePressed={() => { this.setState({ wantToDeleteAccount: !this.state.wantToDeleteAccount }) }}
                    onDeleteFinished={() => {
                        this.props.onDeleteObject();
                    }}
                    onDelete={async () => {
                        this.setState({ isAsyncCall: true })
                        const restApiServices = new ApiProvider();
                        return await restApiServices.provider({
                            baseUrl: systemHotelsBaseUrl,
                            endpoint: `api/hotel/remove-hotel-entity/${hotel.id}`,
                            apiMethod: ApiMethods.delete,
                            successCodeExpectation: 200,
                            requestData: {
                                token: {
                                    accessToken: this.props.userInformation?.accessToken
                                }
                            },
                        }).then((response) => {
                            if (typeof (response.error) === "undefined") {
                                this.setState({ isAsyncCall: false });
                                return true;
                            } else {
                                this.setState({ isAsyncCall: false })
                                return false;
                            }
                        });
                    }}
                />
            }
            <div className='update_houses_screen_left'>
                <div className="update_building">
                    <div className="divider" />
                    <div className='update_actions'>
                        <i className='fa fa-rotate-right' onClick={(event) => {
                            event.stopPropagation();
                            this.setState({ errorMessage: '', successMessage: '', infoMessage: '' });
                            this.GetEntityObject();
                        }} />
                        <i className='fa fa-eye' onClick={(event) => {
                            event.stopPropagation();
                            this.setState({ isDetailsDisplayed: !this.state.isDetailsDisplayed });
                        }} />
                        {isDisabled && <i className='fa fa-lock' onClick={(event) => {
                            event.stopPropagation();
                            this.setState({ isDisabled: !isDisabled });

                        }} />}
                        {!isDisabled && <i id='spinner_loader' className='fa fa-unlock' onClick={(event) => {
                            event.stopPropagation();
                            if (Object.keys(this.state.formValuesToSend).length) {
                                this.setState({ errorMessage: '', successMessage: '', infoMessage: '' });
                                this.joiSchema.validateAsync(this.state.formValues).then((formValues) => {
                                    this.setState({ isDisabled: !isDisabled });
                                    this.onUpdate(this.state.formValuesToSend);
                                }).catch((error) => {
                                    this.setState({ errorMessage: `${error.details.map((e) => e.message)}` });
                                });
                            } else {
                                this.setState({ isDisabled: !isDisabled });
                            }
                        }} />}
                        <i className='fa fa-trash' onClick={(event) => {
                            event.stopPropagation();
                            this.setState({ wantToDeleteAccount: !this.state.wantToDeleteAccount });
                        }} />
                        {this.state.isAsyncCall && <i id='spinner_loader' className='fa-solid fa-spinner fa-spin' />}
                    </div>
                    {!isEmpty(this.state.errorMessage) && <>
                        <Notifier type='error' message={this.state.errorMessage} />
                        <div className="divider" />
                    </>}
                    {!isEmpty(this.state.successMessage) && <>
                        <Notifier type='success' message={this.state.successMessage} />
                        <div className="divider" />
                    </>}
                    <div className="update_building_form">
                        <fieldset disabled={isDisabled || this.state.isAsyncCall}>
                            <form onChange={this.handleChange}>
                                <div className="update_input">
                                    <h5>{{
                                        fr: "Dénomination",
                                        en: "Denomination",
                                        sw: "Dhehebu"
                                    }[this.props.currentLanguage]}</h5>
                                    <input name='Name' value={this.state.formValues.Name} onChange={(event) => { }} />
                                </div>
                                <div className="update_input">
                                    <h5>{{
                                        fr: "Etoiles",
                                        en: "Stars",
                                        sw: "Nyota"
                                    }[`${this.props.currentLanguage}`]}</h5>
                                    <select className="account_type" name="RatingId" value={Number(this.state.formValues.RatingId)} onChange={(event) => { }}>
                                        <option value={0} disabled={true}>Stars</option>
                                        {this.props.ratingTypes.map((e, i) => {
                                            return <option key={i} value={e.id}>{e.Start}</option>
                                        })}
                                    </select>
                                </div>

                                <div className="update_input">
                                    <h5>{{
                                        fr: "Coordonnées",
                                        en: "Cordinates",
                                        sw: "Kuratibu"
                                    }[`${this.props.currentLanguage}`]}</h5>
                                    <LocationPickerInput key={Math.random() * 28937923} isDisabled={isDisabled} intialValue={{ lat: this.state.formValues.Latitude, lng: this.state.formValues.Longitude }} onChange={(lat, lng) => {
                                        this.setState(state => ({ ...state, formValues: { ...state.formValues, Latitude: lat, Longitude: lng }, formValuesToSend: { ...state.formValuesToSend, Latitude: lat, Longitude: lng } }));
                                    }} />
                                </div>
                                <div className='row_inputs flex'>
                                    <div className="update_input">
                                        <h5>{{
                                        fr: "Adresse",
                                        en: "Address",
                                        sw: "Anwani"
                                    }[`${this.props.currentLanguage}`]}</h5>
                                        <input name="Address" onChange={(event) => { }} value={this.state.formValues.Address} />
                                    </div>
                                    <div className="update_input">
                                        <h5>{{
                                            fr: "Construit en",
                                            en: "Built in",
                                            sw: "Ime jengwa mwaka"
                                        }[`${this.props.currentLanguage}`]}</h5>
                                        <select onChange={(event) => { }} className="account_type" name="BuiltIn" value={this.state.formValues.BuiltIn}>
                                            <option value={0} disabled={true}>Built in</option>
                                            {Array.from({ length: new Date().getFullYear() - startYear }, (_, i) => {
                                                return new Date().getFullYear() - i;
                                            }).map((e, i) => {
                                                return <option key={i} value={Number(e)}>{e}</option>
                                            })}
                                        </select>
                                    </div>
                                </div>
                                <div className='row_inputs flex'>
                                    <div className="update_input">
                                        <h5>{{
                                            fr : 'Site web',
                                            en : 'Web site',
                                            sw : 'Tovuti'
                                        }[this.props.currentLanguage]}</h5>
                                        <input name='Website' value={this.state.formValues.Website} onChange={(event) => { }} />
                                    </div>
                                    <div className="update_input">
                                        <h5>{{
                                            fr : 'Adresse email',
                                            en : 'Email address',
                                            sw : 'Barua pepe'
                                        }[this.props.currentLanguage]}</h5>
                                        <input name='Email' value={this.state.formValues.Email} onChange={(event) => { }} />
                                    </div>
                                </div>
                                <div className="update_input">
                                    <h5>{{
                                        fr : 'Numéro de téléphone',
                                        en : 'Phone number',
                                        sw : 'Nambari ya simu'
                                    }[this.props.currentLanguage]}</h5>
                                    <input name='Contact' value={this.state.formValues.Contact} onChange={(event) => { }} />
                                </div>
                                <div className="update_input">
                                    <h5>{{
                                        fr: 'Description',
                                        en: 'Description',
                                        sw: 'Maelezo'
                                    }[this.props.currentLanguage]}</h5>
                                    <textarea name='Description' onChange={(event) => { }} value={this.state.formValues.Description} />
                                </div>
                                <div className="update_input">
                                    <h5>{{
                                        fr: 'Annonce (Si il en a)',
                                        en: 'Announcement (if any)',
                                        sw: 'Tangazo (Kama ipo)'
                                    }[this.props.currentLanguage]}</h5>
                                    <textarea name='Info' onChange={(event) => { }} value={this.state.formValues.Info} />
                                </div>
                                <div className="divider" />
                            </form>
                        </fieldset>
                    </div>
                </div>
            </div>
            <div className='update_houses_screen_right'>
                <div style={{ height: '25rem', minHeight: '25rem', width: '100%' }}>
                    <Caroussel002
                        key={Math.random()}
                        max={15}
                        min={4}
                        hintText="Add images"
                        multiple={true}
                        extensions={['jpeg', 'jpg', 'png']}
                        initialValues={this.state.hotel.HotelImages}
                        video={this.state.hotel.Video}
                        entityIdObject={{ HotelId: this.state.hotel.id }}
                        builder={(index) => {
                            return Object.keys(this.state.hotel.HotelImages[index] ?? {}).length ? <img alt='' src={this.state.hotel.HotelImages[index]?.Url} style={{ height: '100%', width: '100%', objectFit: 'cover' }} /> : <></>
                        }}
                        uploadOptions={{
                            userUUID: this.props.userInformation.UID,
                            token: this.props.userInformation.accessToken,
                            field: hotelsImagesS3FolderNAme,
                            baseUrl: systemHotelsBaseUrl,
                            uploadEndpoint: `hotel/add-image`,
                            deleteEndpoint: (id) => `hotel/remove-image/${id}/${this.state.hotel.id}`,
                            uploadVideoEndpoint: `hotel/update/${this.state.hotel.id}`,
                        }}
                        onUpload={(urlObject) => {
                            this.setState(state => ({ hotel: { ...state.hotel, HotelImages: [urlObject, ...this.state.hotel.HotelImages] } }));
                        }}
                        onDelete={(id) => {
                            this.setState(state => ({ hotel: { ...state.hotel, HotelImages: state.hotel.HotelImages.filter((e) => e.id !== id) } }));
                        }}
                        onVideoChanged={(url) => {
                            this.setState(state => ({ hotel: { ...state.hotel, Video: url } }));
                        }}
                    />
                </div>
                <div className="divider" /><div className="divider" />
                <div className='enable_house_inputs'>
                    <div className='enable_house_inputs_item'>
                        <h5>{{
                            fr: 'Visible publiquement ',
                            en: 'Publicly visible',
                            sw: 'Inaonekana hadharani'
                        }[this.props.currentLanguage]}</h5>
                        <label className='switch'>
                            <input type='checkbox' checked={this.state.isVisible} value={this.state.isVisible} onChange={(event) => {
                                event.stopPropagation();
                                this.makeVisible(event.target.checked);
                            }} />
                            <span className='slider round' />
                        </label>
                    </div>
                    {this.state.isAsyncCall && <i id='spinner_loader' className='fa-solid fa-spinner fa-spin' />}
                </div>
                <div className='divider' />
                <HotelRoom hotelId={hotel.id} rooms={hotel.Rooms ?? []} />
                <div className='divider' />
            </div>
        </div>

    }

    GetEntityObject() {
        this.setState({ isAsyncObjectCall: true });
        const restApiServices = new ApiProvider();
        restApiServices.provider({
            baseUrl: systemHotelsBaseUrl,
            endpoint: `api/hotel/my-own-hotel/${this.props.hotel.id}`,
            apiMethod: ApiMethods.get,
            successCodeExpectation: 200,
            requestData: {
                token: {
                    accessToken: this.props.userInformation?.accessToken
                }
            },
        }).then((response) => {
            if (typeof (response.error) === "undefined") {
                const object = response.response;
                this.setState({
                    hotel: object,
                    formValues: this.getFormValues(object),
                    isAsyncObjectCall: false,
                    isVisible: object.Actif ? true : false,
                });
            } else {
                this.setState({ isAsyncObjectCall: false, errorObjectMessage: getErrorMessage(response.error, 'en') });
            }
        });
    };

    utilsFetcher() {
        this.setState({ isAsyncCall: true });
        const restApiServices = new ApiProvider();
        return restApiServices.provider({
            baseUrl: systemHotelsBaseUrl,
            endpoint: "api/utils",
            apiMethod: ApiMethods.get,
            successCodeExpectation: 200,
            requestData: {
                token: {
                    accessToken: localStorage.getItem(`${appName}act`) ?? ''
                }
            },
        }).then((response) => {
            if (typeof (response.error) === "undefined") {
                const { Rating, RoomType } = response.response;
                this.props.dispatch(copyWith({ ratingTypes: Rating ?? [], roomTypes: RoomType ?? [] }));
                this.setState({ isAsyncCall: false });
            } else {
                this.props.dispatch(copyWith({ ratingTypes: [], roomTypes: [] }));
                this.setState({ isAsyncCall: false });
            }
        });
    };


    makeVisible(state) {
        console.log(state);
        if (!this.state.isAsyncCall) {
            this.setState({ isAsyncCall: true });
            const restApiServices = new ApiProvider();
            restApiServices.provider({
                baseUrl: systemHotelsBaseUrl,
                endpoint: `api/hotel/toggle-state/${this.state.hotel.id}/${state}`,
                apiMethod: ApiMethods.patch,
                successCodeExpectation: 200,
                requestData: {
                    token: {
                        accessToken: localStorage.getItem(`${appName}act`) ?? ''
                    },
                    body: {},
                },
            }).then((response) => {
                if (typeof (response.error) === "undefined") {
                    this.setState({ isVisible: !this.state.isVisible, isAsyncCall: false });
                } else {
                    this.setState({ isAsyncCall: false, errorMessage: getErrorMessage(response.error, 'en') });
                }
            });
        }
    };


    onUpdate(values) {
        if (!this.state.isAsyncCall) {
            this.setState({ isAsyncCall: true });
            const restApiServices = new ApiProvider();
            restApiServices.provider({
                baseUrl: systemHotelsBaseUrl,
                endpoint: `api/hotel/update/${this.state.hotel.id}`,
                apiMethod: ApiMethods.patch,
                successCodeExpectation: 200,
                requestData: {
                    token: {
                        accessToken: localStorage.getItem(`${appName}act`) ?? ''
                    },
                    body: values,
                },
            }).then((response) => {
                if (typeof (response.error) === "undefined") {
                    this.setState({ formValuesToSend: {}, isAsyncCall: false });
                } else {
                    this.setState({ isAsyncCall: false, isDisabled: false, errorMessage: getErrorMessage(response.error, 'en') });
                }
            });
        }

    };
}

const mapStateToProps = (storeOf) => ({
    currentLanguage: storeOf.madoshome.currentLanguage.code,
    userInformation: storeOf.madoshome.userInformation,
    roomTypes: storeOf.madoshome.roomTypes,
    currencies: storeOf.madoshome.currencies,
    ratingTypes: storeOf.madoshome.ratingTypes,
});

export default connect(mapStateToProps)(UpdateHotel);
