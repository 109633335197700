import React, { Component } from 'react'
import { connect } from 'react-redux';
import { CalcCrow, capitalize } from '../../../utils/utils';
import RawTitleValue from '../title_value/title_value';

class TourSiteDetails extends Component {
    render() {
        const { tour } = this.props;
        return (
            <div>
                <div className='tour_site_card_details_price_like flex'>
                    <div className='hotel_card_details_star'>
                        <h2>{tour.Name}</h2>
                        {/* <h4><Numeral value={tour.Price} format={'0.0a'} /> {this.props?.currencies.filter((e) => e.id === tour.Currency)[0]?.Name ?? 'Unknown currency'}</h4> */}
                    </div>
                    {tour.SiteWeb && <a href={tour.SiteWeb} target='_blank' rel='noreferrer'>
                        <div className='action_button flex'>
                            <i className='fa fa-globe' style={{ color: 'var(--textColorOnColoredCard)' }} />
                            {/* <h6>Visit website</h6> */}
                        </div>
                    </a>}

                </div>
                <div className='divider' />
                {tour.History && <>
                    <div className='divider' />
                    <div className='divider' />
                    <h3>{{
                        fr: "Historique",
                        en: "History",
                        sw: "Historia"
                    }[`${this.props.currentLanguage}`]}</h3>
                    <div className="divider" />
                    <p>{tour.History}</p>
                    <div className='divider' />
                </>}
                <RawTitleValue title={{
                    fr: "Type",
                    en: "Type",
                    sw: "Aina"
                }[`${this.props.currentLanguage}`]} value={`${tour.TourismType[capitalize(this.props.currentLanguage)]}`} />
                <RawTitleValue title={{
                    fr: "Distance apartir de votre emplacement",
                    en: "Distance from your location",
                    sw: "Umbali kutoka eneo lako"
                }[`${this.props.currentLanguage}`]} value={`${CalcCrow(tour.Latitude, tour.Longitude, this.props.userLocation?.lat, this.props.userLocation?.lon)} ${{
                    fr: " Kilomètre de loins",
                    en: " Kilometer away",
                    sw: " Kilomita mbali"
                }[`${this.props.currentLanguage}`]}`} />
                <RawTitleValue title={{
                    fr: "Adresse",
                    en: "Address",
                    sw: "Anwani"
                }[`${this.props.currentLanguage}`]} value={`${tour.Address}`} />
                <RawTitleValue title={{
                    fr: "Construit en",
                    en: "Built in",
                    sw: "Ime jengwa mwaka"
                }[`${this.props.currentLanguage}`]} value={`${tour.BuiltIn}`} />
                <div className='divider' />
                {tour.PriceDetail ? <>
                    <div className='divider' /><div className='divider' />
                    <h3>{{
                       fr: "Tarification",
                       en: "Pricing ",
                       sw: "Bei "
                   }[`${this.props.currentLanguage}`]}</h3>
                    <div className="divider" />
                    <RawTitleValue title={{
                        fr: "Adulte nationaux",
                        en: "National adult",
                        sw: "Mtu mzima wa kitaifa"
                    }[`${this.props.currentLanguage}`]} value={`${tour.PriceDetail?.Local} ${this.props?.currencies.filter((i) => i.id === Number(tour?.PriceDetail?.Currency))[0]?.Name ?? 'Unknown currency'}`} />
                    <RawTitleValue title={{
                        fr: "Enfant nationaux",
                        en: "National kids",
                        sw: "Mtoto waki taifa"
                    }[`${this.props.currentLanguage}`]} value={`${tour.PriceDetail?.LocalChild} ${this.props?.currencies.filter((i) => i.id === Number(tour?.PriceDetail?.Currency))[0]?.Name ?? 'Unknown currency'}`} />
                    <RawTitleValue title={{
                        fr: "Adulte étranger",
                        en: "Foreigner adult",
                        sw: "Mtu mzima mgeni"
                    }[`${this.props.currentLanguage}`]} value={`${tour.PriceDetail?.Foreign} ${this.props?.currencies.filter((i) => i.id === Number(tour?.PriceDetail?.Currency))[0]?.Name ?? 'Unknown currency'}`} />
                    <RawTitleValue title={{
                        fr: "Enfant étranger",
                        en: "Foreigner kids",
                        sw: "Mtoto mgeni"
                    }[`${this.props.currentLanguage}`]} value={`${tour.PriceDetail?.ForeignChild} ${this.props?.currencies.filter((i) => i.id === Number(tour?.PriceDetail?.Currency))[0]?.Name ?? 'Unknown currency'}`} />
                    <div className='divider' />
                </> : <></>}
                {tour.Services.length ? <>
                    <div className='divider' /><div className='divider' />
                    <h3>{{
                       fr: "Services ou produits payants supplémentaires",
                       en: "Additional paid services or products ",
                       sw: "Huduma au bidhaa za ziada zinazolipwa "
                   }[`${this.props.currentLanguage}`]}</h3>
                    <div className="divider" />
                    {[...tour.Services ?? []].map((e) => {
                        return <RawTitleValue
                            key={e.id}
                            title={`${e.Name}`}
                            value={`${e.Price} ${this.props?.currencies.filter((i) => i.id === e.Currency)[0]?.Name ?? 'Unknown currency'}`}
                        />
                    })}
                    <div className='divider' />
                </> : <></>}
                {tour.Calendars.length ? <>
                    <div className='divider' /><div className='divider' />


                    <h3>{{
                       fr: "Jours ouverts",
                       en: "Open days ",
                       sw: "Siku za wazi "
                   }[`${this.props.currentLanguage}`]}</h3>



                    <div className="divider" />
                    {tour.Calendars.map((e) => {
                        return <RawTitleValue
                            key={e.id}
                            title={`${e.Day[capitalize(this.props.currentLanguage)]}`}
                            value={`${`${new Date(`01/01/2000 ${e.From}`).toLocaleTimeString({ seconds: '' })}`} - ${`${new Date(`01/01/2000 ${e.To}`).toLocaleTimeString()}`}`}
                        />
                    })}
                </> : <></>
                }
                <div className='divider' /><div className='divider' />
                <h3>{{
                    fr: "Description",
                    en: "Description",
                    sw: "Maelezo"
                }[`${this.props.currentLanguage}`]}</h3>
                <div className="divider" />
                <p>{tour.Description}</p>
                <div className='divider' />
            </div>
        )
    }
}


const mapStateToProps = (storeOf) => ({
    currentLanguage: storeOf.madoshome.currentLanguage.code,
    currencies: storeOf.madoshome.currencies,
    userLocation: storeOf.madoshome.userLocation,
});

export default connect(mapStateToProps)(TourSiteDetails);
