import React from 'react';
import { appName } from '../../../config/config';
import './splash.css';
import image from '../../../assets/images/mdsi.png';
import Loader from '../../components/loader/loader';


export default class SplashScreen extends React.Component {

    render() {
        return <div className='splash_screen'>
            <div className='splash_screen_large'>
                <img src={image} alt={`${appName} logo`} />
            </div>
            <div className='full_space_loader'><Loader /></div>
        </div>
    }
}