import React from 'react';
// import { connect } from 'react-redux';
// import { copyWith } from '../../../logic/slice';
// import Internationalisation from '../../../data/providers/internationalisation';

import './languageChanger.css'
import Multilanguage from '../multi_language/multilanguage';
import ManagerRepo from '../../../data/repositories/managerRepo';
import Internationalisation from '../../../data/providers/internationalisation';
import { copyWith } from '../../../logic/slice';

import { connect } from 'react-redux';



const Languages = ({ data, CurrentLanguage}) => {
    const data_ = Object.keys(data);
    return <>
        {data_.map(e => <option key={e} value={e} selected = {e === CurrentLanguage}>{data[e]}</option>)}
    </>
}

const languagevalue = {
    fr: {
        fr: 'Français',
        en: 'Anglais',
        sw: 'Swahili'
    },
    en: {
        fr: 'French',
        en: 'English',
        sw: 'Swahili'
    },
    sw: {
        fr: 'Kifaransa',
        en: 'Kingereza',
        sw: 'Kiswahili'
    }
}

class LanguageChanger extends React.Component {
    render() {

        return <select
            name="language"
            id="language"
            className='languageDisplayer'
            onClick={(e) => e.stopPropagation()}

            onChange={(event) => {
                this.OnlanguageChange(event.target.value)
                this.props.onLanguageChanged()
            }}
        >
            <Multilanguage ComponentTorender={Languages} data={languagevalue} />
        </select>
    }

    OnlanguageChange(langCode) {

        try {
            const managerRepo = new ManagerRepo();
            var newLanguage = managerRepo.onLanguageChanged(new Internationalisation().codeToType(langCode));
            this.props.dispatch(copyWith({
                currentLanguage: newLanguage,
            }));
            console.log("language changed")
        } catch (e) {
            console.log("couldn't change the language", e)
            this.props.dispatch(copyWith({
                errorMessage: `An error has occured while changing the app language ${e}`,
                isErrorOccured: true,
            }));
        }
        this.props.dispatch(copyWith({ isErrorOccured: false }));
    }
}

const mapStateToProps = (storeOf) => ({
    currentLanguage: storeOf.madoshome.currentLanguage,
})

export default connect(mapStateToProps)(LanguageChanger)