import React from "react";
import { connect } from "react-redux";
import { appName, systemHousesBaseUrl } from "../../../config/config";
import ApiProvider, { ApiMethods } from "../../../data/providers/api_services";
import { copyWith } from "../../../logic/slice";
import { amenities, capitalize } from "../../../utils/utils";
import MultiSelectInput from "../multi_select_input/multi_select_input";


class PropertySearchForm extends React.Component {

    state = {
        isAsyncCall: false,
    }

    componentDidMount() {
        if (!this.props.propertyTypes.length || !this.props.RentalTypes.length) {
            this.utilsFetcher();
        }
    }

    render() {
        const type = {
            fr: "Type",
            en: "Type",
            sw: "Aina"
        }[`${this.props.currentLanguage}`]
        const watingmsg = {
           
            fr: "Patientez s'il vous plait...",
            en: "Please wait...",
            sw: "Tafadhali subiri..."
    }[`${this.props.currentLanguage}`]
        const startYear = new Date().getFullYear() - 100;
        return <fieldset disabled={this.state.isAsyncCall} className="search_form">
            <select required={true} className="account_type" name="BuildingTypeId" defaultValue={0}>
                <option value={0} disabled={true}>{this.state.isAsyncCall ? watingmsg : type } </option>
                {this.props.propertyTypes.map((e, i) => {
                    return <option key={i} value={e.id}>{e[capitalize(this.props.currentLanguage.code)]}</option>
                })}
            </select>
            <MultiSelectInput isResetTime={false} placeholder="Amenities" items={amenities.map((e) => e.name[`${this.props.currentLanguage}`])} onConfirm={(selectedIndex) => {
                this.props.onAmenities(selectedIndex);
            }} />
            <div className='row_inputs_search flex'>
                <input type="number" name="LivingRoom" placeholder={{
                    fr: "Salon",
                    en: "Living room",
                    sw: "Sebuleni"
                }[`${this.props.currentLanguage}`]} />
                <input type="number" name="DinningRoom" placeholder={
                    {
                        fr: "Salle à manger",
                        en: "Dinner room",
                        sw: "Chumba cha kulia"
                    }[`${this.props.currentLanguage}`]
                } />
            </div>
            <div className='row_inputs_search flex'>
                <input type="number" name="BathRoom" placeholder={
                    {
                        fr: "Douche",
                        en: "Bathroom",
                        sw: "Bafuni"
                    }[`${this.props.currentLanguage}`]
                } />
                <input type="number" name="BedRoomLength" placeholder={
                    {
                        fr: "Chambre",
                        en: "Bedroom",
                        sw: "Vyumba"
                    }[`${this.props.currentLanguage}`]
                } />
            </div>
            <div className='row_inputs_search flex'>
                <input type="number" name="Parking" placeholder={
                    {
                        fr: "Parking",
                        en: "Parking",
                        sw: "Maegesho"
                    }[`${this.props.currentLanguage}`]
                } />
                <input type="number" name="Garage" placeholder={
                    {
                        fr: "Garage",
                        en: "Garage",
                        sw: "Gereji"
                    }[`${this.props.currentLanguage}`]
                } />
            </div>
            <div className='row_inputs_search flex'>
                <input type="number" name="Balcony" placeholder={
                    {
                        fr: "Balcon",
                        en: "Balcony",
                        sw: "Balcony"
                    }[`${this.props.currentLanguage}`]
                } />
                <input type="number" name="Kitchen" placeholder={
                    {
                        fr: "Cuisine",
                        en: "Kitchen",
                        sw: "Jikoni"
                    }[`${this.props.currentLanguage}`]
                } />
            </div>
            <div className='row_inputs_search flex'>
                <input type="number" name="Size" placeholder={
                    {
                        fr: "Taille de la parcelle (m²)",
                        en: "Plot size (m²)",
                        sw: "Ukubwa wa kiwanja (m²)"
                    }[`${this.props.currentLanguage}`]
                } />
                <input type="number" name="Office" placeholder={
                    {
                        fr: "Bureau",
                        en: "Office",
                        sw: "Ofisi"
                    }[`${this.props.currentLanguage}`]
                } />
            </div>
            <select required={true} className="account_type" name="BuiltIn" defaultValue={0}>
                <option value={0} disabled={true}>{{
                    fr: "Construit en",
                    en: "Built in",
                    sw: "Ime jengwa mwaka"
                }[`${this.props.currentLanguage}`]}</option>
                {Array.from({ length: new Date().getFullYear() - startYear }, (_, i) => {
                    return new Date().getFullYear() - i;
                }).map((e, i) => {
                    return <option key={i} value={Number(e)}>{e}</option>
                })}
            </select>
        </fieldset>
    }

    utilsFetcher() {
        this.setState({ isAsyncCall: true });
        const restApiServices = new ApiProvider();
        return restApiServices.provider({
            baseUrl: systemHousesBaseUrl,
            endpoint: "api/utils",
            apiMethod: ApiMethods.get,
            successCodeExpectation: 200,
            requestData: {
                token: {
                    accessToken: localStorage.getItem(`${appName}act`) ?? ''
                }
            },
        }).then((response) => {
            if (typeof (response.error) === "undefined") {
                const { BuildingType, RentalType } = response.response;
                this.props.dispatch(copyWith({ propertyTypes: BuildingType ?? [], RentalTypes: RentalType ?? [] }));
                this.setState({ isAsyncCall: false });
            } else {
                this.props.dispatch(copyWith({ propertyTypes: [], RentalTypes: [] }));
                this.setState({ isAsyncCall: false });
            }
        });
    };
}

const mapStateToProps = (storeOf) => ({
    currentLanguage: storeOf.madoshome.currentLanguage.code,
    userInformation: storeOf.madoshome.userInformation,
    propertyTypes: storeOf.madoshome.propertyTypes,
    RentalTypes: storeOf.madoshome.RentalTypes,
});

export default connect(mapStateToProps)(PropertySearchForm);

