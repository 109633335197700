
import React, { createRef } from "react";
import { connect } from "react-redux";
import Loader from "../../components/loader/loader";
import './delete_account.css';
import { getErrorMessage, isEmpty } from "../../../utils/utils";
import ApiProvider, { ApiMethods } from "../../../data/providers/api_services";
import { systemAuthBaseUrl } from "../../../config/config";
import Notifier from "../../components/notifier/notifier";
import Joi from "joi";
import { copyWith } from '../../../logic/slice';






class DeleteAccount extends React.Component {

    state = {
        hasAgreed: false,
        isAsyncCall: false,
        errorMessage: '',
        successMessage: '',
        formValues: {},
    }

    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState(state => ({ ...state, formValues: { ...state.formValues, [name]: value } }));
    }

    formRef = createRef();

    render() {
        const waitingmsg = {
            'fr': "Patientez s'il vous plait...",
            'en': "Please wait...",
            'sw': "Tafadhali subiri..."
        }[`${this.props.currentLanguage}`]
        const deleteaccount = {
            'fr': "Supprimer mon compte",
            'en': "Delete my account",
            'sw': "Futa akaunti yangu"
        }[`${this.props.currentLanguage}`]
        const joiSchema = Joi.object({ VerificationCode: Joi.number().required(), Reason: Joi.string().default('') });
        return <div className="modal_rideau">
            <div className="dif_modal">
                <div style={{padding: '1rem'}}>
                    <div className="flex call_to_subcsription_header update_user_informations_header">
                        <h2>{{
                            fr : "Supprimer votre compte",
                            en : "Delete your account",
                            sw : "Futa akaunti yako"
                        }[`${this.props.currentLanguage}`]}</h2>
                        <span id='cross_container' onClick={() => { this.props.onClosePressed() }}>
                            <i className="fa-solid fa-xmark"></i>
                        </span>
                    </div>
                    <div className="divider" />
                    {!this.state.hasAgreed
                        ? <p>{{
                            fr : "La suppression de votre compte supprimera toutes vos informations de notre base de données. ça ne peut pas être annulé.",
                            en : "Deleting your account will remove all of your informations from our database. This cannot be undone.",
                            sw : "Kufuta akaunti yako kutaondoa taarifa zako zote kutoka kwa hifadhidata yetu. hii haiwezi kutenduliwa"
                        }[`${this.props.currentLanguage}`]}</p>
                        : <p>{{
                            fr : `Un code de vérification a été envoyé à ${this.props?.userInformation?.Email} Consultew la boîte de réception pour vefifier votre compte.`,
                            en : `A verification code has been sent to ${this.props?.userInformation?.Email}. Consult the inbox to verify your account.`,
                            sw : `Nambari ya kuthibitisha imetumwa kwa ${this.props?.userInformation?.Email}. Angaliya kisanduku pokezi ili uthibitishe akaunti yako.`
                        }[`${this.props.currentLanguage}`]}</p>
                    }
                    <div className="divider" />
                    {!isEmpty(this.state.errorMessage) && <>
                        <Notifier type='error' message={this.state.errorMessage} />
                        <div className="divider" />
                    </>}
                    {!isEmpty(this.state.successMessage) && <>
                        <Notifier type='success' message={this.state.successMessage} />
                        <div className="divider" />
                    </>}
                    {!this.state.hasAgreed
                        ? <> {this.state.isAsyncCall
                            ? <div className="full_space_loader"><Loader /></div>
                            : <div className="accept_bank_transfer_conditions" onClick={(event) => {
                                event.stopPropagation();
                                this.onDeletingAccountIntent();
                            }}>
                                <p>{{
                                    fr: "J'ai lu et j'accepte de supprimer mon compte",
                                    en : "I have read and agree to delete my account",
                                    sw : "Nimesoma na kukubali kufuta akaunti yangu"
                                }[`${this.props.currentLanguage}`]}</p>
                                <i className="fa-solid fa-arrow-right-long" />
                            </div>
                        }
                        </>
                        : <fieldset disabled={this.state.isAsyncCall}>
                            <form ref={this.formRef} onChange={this.handleChange} onSubmit={(event) => {
                                event.preventDefault();
                                this.setState({ errorMessage: '' });
                                joiSchema.validateAsync(this.state.formValues).then((formValues) => {
                                    this.onDeletingAccountCommit(formValues.VerificationCode, formValues.Reason);
                                }).catch((error) => {
                                    this.setState({ errorMessage: `${error.details.map((e) => e.message)}` });
                                });
                            }}>
                                <input type="text" name="VerificationCode" placeholder={{
                                    fr : "Code de vérification",
                                    en : "Verification code",
                                    sw : "Nambari ya uthibitishaji"
                                }[`${this.props.currentLanguage}`]} />
                                <textarea name='Reason' placeholder={{
                                    fr : "Pouvez-vous s'il vous plaît partager avec nous ce qui n'a pas fonctionné ? Nous corrigeons les bogues tant que nous les repérons. Si quelque chose nous glisse entre les doigts, nous serions très reconnaissants d'en être conscients et de le réparer. Votre explication est entièrement facultative.",
                                    en  : "Can you please share to us what was not workin ? We are fixing bugs as long as we spot them. If something slipped through our fingers we'd so grateful to be aware of it and fix it. Your explanation is entirely optional.",
                                    sw : "Tafadhali unaweza kushiriki nasi nini kilienda vibaya ? Tunarekebisha hitilafu mradi tu tunaziona. Ikiwa kitu kitaingia kwenye vidole vyetu, tungeshukuru sana kulifahamu na kulirekebisha. Maelezo yako ni ya hiari kabisa."
                                }[`${this.props.currentLanguage}`]} />
                                <div className="divider"/>
                                <button className={`${this.state.isAsyncCall ? 'delete_account_button button_on_async' : "delete_account_button"}`} type={'submit'}>{this.state.isAsyncCall ? waitingmsg : deleteaccount }</button>
                            </form>
                        </fieldset>}
                </div>
            </div>
        </div>
    }

    onDeletingAccountIntent() {
        this.setState({ isAsyncCall: true, errorMessage: '', successMessage: '' });
        const restApiServices = new ApiProvider();
        restApiServices.provider({
            baseUrl: systemAuthBaseUrl,
            endpoint: 'api/mail-checker',
            apiMethod: ApiMethods.post,
            successCodeExpectation: 200,
            requestData: {
                token: { accessToken: this.props?.userInformation?.accessToken },
                body: {
                    Email: this.props?.userInformation?.Email,
                }
            },
        }).then((response) => {
            if (typeof (response.error) === 'undefined') {
                console.log(response.response);
                this.setState({ isAsyncCall: false, hasAgreed: true });
            } else {
                this.setState({ isAsyncCall: false, errorMessage: getErrorMessage('e13', 'en') });
            }
        });
    }

    onDeletingAccountCommit(Code, Reason) {
        this.setState({ isAsyncCall: true, errorMessage: '' });
        const restApiServices = new ApiProvider();
        restApiServices.provider({
            baseUrl: systemAuthBaseUrl,
            endpoint: 'api/delete-my-account',
            apiMethod: ApiMethods.delete,
            successCodeExpectation: 200,
            requestData: {
                token: { accessToken: this.props?.userInformation?.accessToken },
                body: {
                    Code: Code,
                    Reason: Reason,
                }
            },
        }).then((response) => {
            if (typeof (response.error) === 'undefined') {
                console.log(response.response);
                this.setState({ isAsyncCall: false, successMessage: 'You account has been deleted successfully' });
                this.formRef.current.reset();
                this.props.dispatch(copyWith({
                    userInformation: {}
                }));
                localStorage.clear();
            } else {
                this.setState({ isAsyncCall: false, errorMessage: getErrorMessage('e13', 'en') });
            }
        });
    }
}

const mapStateToProps = (storeOf) => ({
    currentLanguage: storeOf.madoshome.currentLanguage.code,
    userInformation: storeOf.madoshome.userInformation,
    subscriptionPlans: storeOf.madoshome.subscriptionPlans,
});

export default connect(mapStateToProps)(DeleteAccount);