import React from 'react';
import './details_modal.css';


export default class DetailsModal extends React.Component {
    render() {
        return <div className='modal_rideau  details_modal_body' onClick={(event) => { event.stopPropagation()}}>
            <div className='details_modal_body_holder'>
                <div className='details_modal_body_holder_title'>
                    <div className='details_modal_body_holder_title_close_button' onClick={() => { this.props.onClose() }}>
                        <i className='fa fa-close' />
                    </div>
                </div>
                <div className='details_modal_body_holder_body'>
                    {this.props.body}
                </div>
            </div>
        </div>
    }
}