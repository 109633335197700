import './home.css';
import React from 'react';
import NavigationMenu, { Menu } from '../../components/navigationMenu/navigation';
import Displayer from '../../components/displayer/displayer';
import { Route, Routes } from 'react-router-dom';
import SearchDisplayer from '../../components/search_displayer/search_displayer';
import Myaccount from '../my_account/myaccount';
import { navigationHelper } from '../navigation_helper/navigation_helper';
import { connect } from 'react-redux';
import { geoUserLocation, isEmpty } from '../../../utils/utils';
import ManagerRepo from '../../../data/repositories/managerRepo';
import { copyWith } from '../../../logic/slice';
import ApiProvider, { ApiMethods } from '../../../data/providers/api_services';
import { appName, systemAuthBaseUrl } from '../../../config/config';
import Splash from '../splash/splash';
// import Notifier from '../../components/notifier/notifier';
import { faveCategories, homeCategories } from './categories';
import ProfilePage from '../profile_page/profile_page';
import AppTheme from '../../styles/themes';


class Home extends React.Component {

    state = {
        isModaloginformDisplayed: false,
    }
    Menu = (hasLoggedIn) => [
        { listName: "", route: '', IconName: "house", canBeDisplayed: true },
        { listName: "", route: 'favorites', IconName: "heart", canBeDisplayed: hasLoggedIn },
        { listName: "", route: 'search', IconName: "search", canBeDisplayed: true },
        { listName: "", route: 'my-account', IconName: "user", canBeDisplayed: true },
    ].filter((element) => element.canBeDisplayed === true);

    componentDidMount() {
        let currentIndex = localStorage.getItem(`${appName}clm`) ?? 0;
        this.setState({ isClicked: Number(currentIndex) });
        const managerRepo = new ManagerRepo();
        var settings = managerRepo.loadAppSettings();
        this.props.dispatch(copyWith({
            isDarkModeOn: settings.isDarkModeOn,
            currentLanguage: settings.currentLanguage,
        }));
        if (!this.props.isInitialized) {
            this.appInit();
        }
    }


    render() {
        const appTheme = new AppTheme();
        // console.log(this.Menu(Object.keys(this.props.userInformation).length > 0));
        return (
            <>
                {!this.props.isInitialized ? <Splash /> : <div className='Home_page' style={appTheme.getThemeCssStyle(this.props.isDarkModeOn ? appTheme.dark : appTheme.light)}>

                    {/* <Notifier type='info' message='For the best experience use our mobile app' /> */}
                    <NavigationMenu />
                    <Routes>
                        <Route path='/' element={<Displayer key={'home'} categories={homeCategories(
                            {
                                lat: this.props.userLocation?.lat,
                                lng: this.props.userLocation?.lon,
                                region: this.props.userLocation?.regionName,
                                accessToken: localStorage.getItem(`${appName}act`) ?? '',
                            }
                        )} />} />
                        <Route path='/favorites' element={<Displayer key={'fav'} categories={faveCategories(
                            {
                                lat: this.props.userLocation?.lat,
                                lng: this.props.userLocation?.lon,
                                region: this.props.userLocation?.regionName,
                                accessToken: this.props.userInformation.accessToken ?? '',
                            }
                        )} />} />
                        <Route path='/search' element={<SearchDisplayer />} />
                        <Route path='/my-account/*' element={<Myaccount />} />
                        <Route path='/users'>
                            <Route path=':uuid' element={<ProfilePage key={Math.random() * 162416} />} />
                        </Route>
                        <Route path='*' element={<Displayer key={'home'} categories={homeCategories(
                            {
                                lat: this.props.userLocation?.lat,
                                lng: this.props.userLocation?.lon,
                                region: this.props.userLocation?.regionName,
                                accessToken: localStorage.getItem(`${appName}act`) ?? '',
                            }
                        )} />} />
                    </Routes>
                    <div className='footer'>
                        <div className='powered'>
                            <p>Powered by <a target="-blanck" href='https://madosgroup.com'>Mados Group</a></p>
                            <span>&#8226;</span>
                            <p>
                                <a target="-blanck" href={`https://madoshome.com/privacy-policy/${this.props.currentLanguage.code === 'en' ? '' : `${this.props.currentLanguage.code}/`}`}>
                                    {{
                                        fr: "Politique de confidentialité",
                                        en: "Privacy policy",
                                        sw: "Sera ya faragha"
                                    }[`${this.props.currentLanguage.code}`]}
                                </a>
                            </p>
                            <span>&#8226;</span>
                            <p>

                                <a target="-blanck" href={`https://madoshome.com/terms-and-conditions/${this.props.currentLanguage.code === 'en' ? '' : `${this.props.currentLanguage.code}/`}`}>
                                    {{
                                        fr: "Termes et conditions",
                                        en: "Terms and Conditions",
                                        sw: "Sheria na Masharti"
                                    }[`${this.props.currentLanguage.code}`]}
                                </a>
                                

                            </p>
                        </div>
                        <p>&copy; {new Date().getFullYear()}</p>

                        <ul className='Menu_container'>
                            {this.Menu(Object.keys(this.props.userInformation).length > 0).map((e, i) => {
                                // const isSelected = i === this.state.isClicked;
                                // console.log(e);
                                return (
                                    <Menu
                                        key={i}
                                        isClicked={e.route === String(window.location.pathname).replaceAll('/','')}
                                        route={e.route}
                                        listName={e.listName}
                                        onPressed={() => {
                                            localStorage.setItem(`${appName}}clm`, i);
                                            this.setState({ isClicked: i });
                                        }}
                                        iconName={e.IconName}
                                    />
                                )
                            })}
                        </ul>
                    </div>
                </div>
                }
            </>
        )
    }

    onFetchingUtils() {
        const restApiServices = new ApiProvider();
        return restApiServices.provider({
            baseUrl: systemAuthBaseUrl,
            endpoint: 'api/utils',
            apiMethod: ApiMethods.get,
            successCodeExpectation: 200,
            requestData: {},
        }).then((response) => {
            if (typeof (response.error) === 'undefined') {
                const utils = response.response;
                return {
                    subscriptionPlans: utils.SubscriptionTypeList ?? [],
                    accountTypes: utils.UserTypeList ?? [],
                    currencies: utils.SupportedCurrency ?? [],
                };
            } else {
                return {};
            }
        });
    }

    appInit() {
        geoUserLocation({
            onSuccess: async (location) => {
                console.log(location);
                const managerRepo = new ManagerRepo();
                var settings = managerRepo.loadAppSettings();
                const utils = await this.onFetchingUtils();
                if (Object.keys(utils).length) {
                    if (!isEmpty(settings.refreshToken) && !isEmpty(settings.accessToken)) {
                        const userInformations = await this.onRefreshingToken({ refreshToken: settings.refreshToken, accessToken: settings.accessToken });
                        this.initialSettings({ userInformation: userInformations, utils: utils, location: location });
                    } else {
                        this.initialSettings({ userInformation: {}, utils: utils, location: location });
                    }
                }
            }, onError: (message) => {
                console.log(message);
            }
        });
    }

    initialSettings({ userInformation = {}, utils = { subscriptionPlans: [], accountTypes: [], currencies: [] }, location = { lat: 0.0, lngt: 0.0 } }) {
        this.props.dispatch(copyWith({
            subscriptionPlans: utils.subscriptionPlans,
            accountTypes: utils.accountTypes,
            currencies: utils.currencies,
            userInformation: userInformation,
            userLocation: location,
            isInitialized: true,
        }));
    }

    onRefreshingToken(tokens) {
        const { accessToken, refreshToken } = tokens;
        const restApiServices = new ApiProvider();
        return restApiServices.provider({
            baseUrl: systemAuthBaseUrl,
            endpoint: 'api/refresh-token',
            apiMethod: ApiMethods.get,
            successCodeExpectation: 200,
            requestData: {
                token: {
                    refreshToken: refreshToken,
                    accessToken: accessToken,
                },
            },
        }).then((response) => {
            if (typeof (response.error) === 'undefined') {
                const managerRepo = new ManagerRepo();
                const { refreshToken, accessToken } = response.response;
                managerRepo.onSetRefreshToken({ refreshToken: refreshToken ?? '', accessToken: accessToken ?? '' });
                return response.response;
            } else {
                localStorage.clear();
                return {};
            }
        });
    }
}

const mapStateToProps = (storeOf) => ({
    currentLanguage: storeOf.madoshome.currentLanguage,
    isInitialized: storeOf.madoshome.isInitialized,
    userLocation: storeOf.madoshome.userLocation,
    userInformation: storeOf.madoshome.userInformation,
    isDarkModeOn: storeOf.madoshome.isDarkModeOn,
});

export default connect(mapStateToProps)(navigationHelper(Home));
