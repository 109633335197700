import React from "react";
import { connect } from "react-redux";
import { appName, systemHotelsBaseUrl } from "../../../config/config";
import ApiProvider, { ApiMethods } from "../../../data/providers/api_services";
import { capitalize, getErrorMessage, isEmpty } from "../../../utils/utils";
import AddRoom from './add_room';
import Joi from "joi";
import Notifier from "../notifier/notifier";
import Caroussel002 from "../caroussel/caroussel002";


class HotelRoom extends React.Component {

    state = {
        wannaAdd: false,
        rooms: [],
        currentIndex: 0,
        isAsyncCall: false,
        isAddRoomTime: false,
    }

    componentDidMount() {
        this.setState({
            rooms: this.props.rooms.map((e) => {
                return {
                    ...e, card: <Rooms
                        userInformation={this.props.userInformation}
                        hotelId={this.props.hotelId}
                        initialValue={e}
                        roomType={this.props.roomTypes}
                        currencies={this.props.currencies ?? []}
                        currentLanguage={this.props.currentLanguage}
                        onUpdate={(room) => {
                            // this.setState({ rooms: [room, ...this.state.rooms] });
                        }}
                        onDelete={(id) => {
                            this.setState({ rooms: this.state.rooms.filter((element) => element.id !== id) });
                        }}
                    />
                }
            })
        });
    }

    render() {

        return <div className="hotel_room">
            {this.state.isAddRoomTime && <AddRoom
                hotelId={this.props.hotelId}
                types={this.props.roomTypes}
                onClosePressed={() => { this.setState({ isAddRoomTime: false }) }}
                onAddRoom={(room) => {
                    this.setState({ rooms: [room, ...this.state.rooms] })
                }}
            />}
            <div className="hotel_room_header">
                <h3>{{
                    fr: 'Chambres',
                    en: 'Rooms',
                    sw: 'Vyumba'
                }[this.props.currentLanguage]}</h3>
                <div className='action_button flex' onClick={() => { this.setState({ isAddRoomTime: true }) }}>
                    <i className='fa fa-add' style={{ color: 'var(--textColorOnColoredCard)' }} />
                </div>
            </div>
            <div className="hotels_rooms_types_names flex">
                {this.state.rooms.map((e, i) => {
                    var isS = i === this.state.currentIndex;
                    return <h4
                        key={e.id}
                        className='hotels_rooms_types_names_items'
                        id={`${isS && "hotels_rooms_types_names_items_selected"}`}
                        onClick={(event) => {
                            event.stopPropagation();
                            this.setState({ currentIndex: i })
                        }}
                    >{capitalize(e?.RoomType?.Label)}</h4>
                    // >{capitalize(this.props.roomTypes.filter((element) => element.id === Number(e.RoomTypeId))[0]?.Label)}</h4>
                })}
            </div>
            <div key={Math.random() * 73265763257}>{this.state.rooms[this.state.currentIndex]?.card}</div>
        </div>
    }
}

const mapStateToProps = (storeOf) => ({
    currentLanguage: storeOf.madoshome.currentLanguage.code,
    userInformation: storeOf.madoshome.userInformation,
    currencies: storeOf.madoshome.currencies,
    roomTypes: storeOf.madoshome.roomTypes,

});

export default connect(mapStateToProps)(HotelRoom);







class Rooms extends React.Component {

    state = {
        isDisabled: true,
        formValues: {},
        formValuesToSend: {},
        errorMessage: '',
        room: {},
    }

    componentDidMount() {
        const { Price, Currency, RoomTypeId, Description } = this.props.initialValue ?? {};
        this.setState({
            room: this.props.initialValue,
            formValues: {
                Currency: Currency ?? 0,
                Price: Price,
                RoomTypeId: RoomTypeId ?? 0,
                Description: Description,
            }
        })
    }

    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState(state => ({ ...state, formValues: { ...state.formValues, [name]: value }, formValuesToSend: { ...state.formValuesToSend, [name]: value } }));
    }

    joiSchema = Joi.object({
        RoomTypeId: Joi.number().min(0).required(),
        Currency: Joi.number().min(0).required(),
        Price: Joi.number().min(0).required(),
        Description: Joi.string().allow(null, ''),
    });

    render() {
        const { Price, Currency, Description, RoomTypeId } = this.state.formValues;
        const isDisabled = this.state.isDisabled;
        console.log(this.props);
        return <div className='hotels_rooms_types'>
            <div className='update_actions'>
                {isDisabled && <i className='fa fa-lock' onClick={(event) => {
                    event.stopPropagation();
                    this.setState({ isDisabled: !isDisabled });
                }} />}
                {!isDisabled && <i id='spinner_loader' className='fa fa-unlock' onClick={(event) => {
                    event.stopPropagation();
                    if (Object.keys(this.state.formValuesToSend).length) {
                        this.setState({ errorMessage: '', successMessage: '', infoMessage: '' });
                        this.joiSchema.validateAsync(this.state.formValues).then((formValues) => {
                            this.onUpdate(this.props.initialValue.id, this.state.formValuesToSend);
                        }).catch((error) => {
                            this.setState({ errorMessage: `${error.details.map((e) => e.message)}` });
                        });
                    } else {
                        this.setState({ isDisabled: !isDisabled });
                    }
                }} />}
                <i className='fa fa-trash' onClick={(event) => {
                    event.stopPropagation();
                    this.onDelete(this.props.initialValue.id);
                }} />
                {this.state.isAsyncCall && <i id='spinner_loader' className='fa-solid fa-spinner fa-spin' />}
            </div>
            {!isEmpty(this.state.errorMessage) && <>
                <Notifier type='error' message={this.state.errorMessage} />
                <div className="divider" />
            </>}
            <div className="divider" />
            <div style={{ height: '18rem', minHeight: '18rem', width: '100%' }}>
                <Caroussel002
                    isVideoUseless={true}
                    max={15}
                    min={4}
                    hintText="Add images"
                    multiple={true}
                    extensions={['jpeg', 'jpg', 'png']}
                    initialValues={this.state?.room?.RoomImages ?? []}
                    entityIdObject={{ RoomId: this.props.initialValue.id }}
                    builder={(index) => {
                        return Object.keys(this.state?.room?.RoomImages[index] ?? {}).length ? <img alt='' src={this.state?.room?.RoomImages[index]?.Url} style={{ height: '100%', width: '100%', objectFit: 'cover' }} /> : <></>
                    }}
                    uploadOptions={{
                        userUUID: this.props.userInformation.UID,
                        token: this.props.userInformation.accessToken,
                        field: 'Uploaded-hotelRooms',
                        baseUrl: systemHotelsBaseUrl,
                        uploadEndpoint: `hotel/add-room-image`,
                        deleteEndpoint: (id) => `hotel/remove-room-image/${id}/${this.props.initialValue.id}`,
                    }}
                    onUpload={(urlObject) => {
                        this.setState(state => ({ room: { ...state.room, RoomImages: [urlObject, ...this.state?.room?.RoomImages] } }));
                    }}
                    onDelete={(id) => {
                        this.setState(state => ({ room: { ...state.room, RoomImages: state.room.RoomImages.filter((e) => e.id !== id) } }));
                    }}
                />
            </div>
            <fieldset disabled={this.state.isAsyncCall || isDisabled}>
                <form className="" onChange={this.handleChange}>
                    <div className="update_input">
                        <h5>{{
                            fr: 'Type',
                            en: 'Type',
                            sw: 'Aina'
                        }[this.props.currentLanguage]}</h5>
                        <select className="account_type" name="RoomTypeId" value={RoomTypeId} onChange={(event) => { }}>
                            <option value={0} disabled={true}>Types</option>
                            {this.props?.roomType?.map((e, i) => {
                                return <option key={i} value={e.id}>{e.Label}</option>
                            })}
                        </select>
                    </div>
                    <div className="flex forget_pass_kn">
                        <div className="update_input">
                            <h5>{{
                                fr: 'Prix',
                                en: 'Price',
                                sw: 'Bei'
                            }[this.props.currentLanguage]}</h5>
                            <input type="number" name="Price" placeholder="Price" value={Price} onChange={(event) => { }} />
                        </div>
                        <div className="update_input">
                            <h5>{{
                                fr: 'Devise',
                                en: 'Currency',
                                sw: 'Fadha'
                            }[this.props.currentLanguage]}</h5>
                            <select onChange={() => { }} className="account_type" name="Currency" value={Currency} >
                                <option value={0} disabled={true}>{{
                                    fr: 'Devise',
                                    en: 'Currency',
                                    sw: 'Fadha'
                                }[this.props.currentLanguage]}</option>
                                {this.props.currencies.map((e, i) => {
                                    return <option key={i} value={e.id}>{e.Name}</option>
                                })}
                            </select>
                        </div>
                    </div>
                    <div className="update_input">
                        <h5>{{
                            fr: 'Description',
                            en: 'Description',
                            sw: 'Maelezo'
                        }[this.props.currentLanguage]}</h5>
                        <textarea name='Description' onChange={() => { }} value={Description} />
                    </div>
                </form>
            </fieldset>
        </div>
    }

    onUpdate(id, values) {
        console.log(values);
        this.setState({ isAsyncCall: true });
        const restApiServices = new ApiProvider();
        restApiServices.provider({
            baseUrl: systemHotelsBaseUrl,
            endpoint: `api/hotel/update-room/${id}/${this.props.hotelId}`,
            apiMethod: ApiMethods.patch,
            successCodeExpectation: 200,
            requestData: {
                token: {
                    accessToken: localStorage.getItem(`${appName}act`) ?? ''
                },
                body: values,
            },
        }).then((response) => {
            if (typeof (response.error) === "undefined") {
                this.setState({ isAsyncCall: false, isDisabled: true, });
                this.props.onUpdate({ ...this.props.initialValue, values });
            } else {
                this.setState({ isAsyncCall: false, isDisabled: false, errorMessage: getErrorMessage(response.error, 'en') });
            }
        });
    };

    onDelete(id) {
        this.setState({ isAsyncCall: true });
        const restApiServices = new ApiProvider();
        restApiServices.provider({
            baseUrl: systemHotelsBaseUrl,
            endpoint: `api/hotel/remove-room/${id}/${this.props.hotelId}`,
            apiMethod: ApiMethods.delete,
            successCodeExpectation: 200,
            requestData: {
                token: {
                    accessToken: localStorage.getItem(`${appName}act`) ?? ''
                },
            },
        }).then((response) => {
            if (typeof (response.error) === "undefined") {
                this.setState({ isAsyncCall: false, isDisabled: true, });
                this.props.onDelete(id);
            } else {
                this.setState({ isAsyncCall: false, isDisabled: false, errorMessage: getErrorMessage(response.error, 'en') });
            }
        });
    };
}
