import React, { Component } from 'react'
// import { connect } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom'
import { ShimmerCategoryItem, ShimmerCircularImage } from 'react-shimmer-effects';
import { appName, systemAuthBaseUrl } from '../../../config/config';
import ApiProvider, { ApiMethods } from '../../../data/providers/api_services';
import { capitalize, getErrorMessage, madoshomeUSer } from '../../../utils/utils';
import Displayer from '../../components/displayer/displayer';
import { randomUserCategories } from '../home/categories';
import Report from '../my_account/report';
import emptyProfil from '../../../assets/images/empty_picture_password.png'
import './profile_page.css';
import { useSelector } from 'react-redux';
import Authentication from '../Authentication/authentication';






export default function ProfilePage() {
    const { userInformation, currentLanguage } = useSelector(state => state.madoshome)
    let { uuid } = useParams();
    let location = useLocation();
    console.log(location);
    return <ProfileUi uuid={uuid} id={location?.state?.id} currentLanguage={currentLanguage?.code} userInformation={userInformation} />
}




class ProfileUi extends Component {

    state = {
        user: {},
        isAsyncCall: false,
        wantReport: false,
        callToLogin: false,
    }

    componentDidMount() {
        this.gettingUser(this.props.uuid);
    }


    render() {
        const user = this.state.user;
        const { Metadata } = this.state.user;


        console.log('USERRRR',user)

        return (
            <div className='profile_page'>
                {this.state.callToLogin && <Authentication onClosePressed={() => {
                    this.setState({ callToLogin: false })
                }} />}

                {this.state.wantReport &&
                    <Report
                        endpoint={'add-report-user'}
                        entity={{ key: 'idReportedUser', value: Number(this.state.user.id) }}
                        moreValues={{ 'idUser': this?.props?.userInformation?.id ?? null }}
                        onClosePressed={() => { this.setState({ wantReport: !this.state.wantReport }) }}
                    />
                }
                {this.state.isAsyncCall
                    ? <div className='profile_page_left profile_page_left_loading dif_modal'>
                        <ShimmerCircularImage size={150} />
                        <div className='' style={{ width: '100%' }}>
                            <ShimmerCategoryItem />
                        </div>
                    </div>
                    : Object.keys(user).length ? <div className=' profile_page_left dif_modal'>
                        <div className='profile_page_left_profile'>
                            <div className='main_profile_container_user_information'>
                                <div className='img_profil_container'>
                                    <img src={user.ProfilImage ?? emptyProfil} alt="" />
                                </div>
                                <div className='name_verified mobile_view'>
                                    <div className='user_identification_informations'>
                                        <h3>{user.Fullname ?? `${appName} user`}</h3>
                                        <p>{user?.UserType[capitalize(this.props?.currentLanguage)] ?? ''}</p>
                                        <span><p>{{
                                                fr: 'Inscrit depuis',
                                                en: 'Joined since',
                                                sw: 'Amejiunga tangu'
                                            }[this.props.currentLanguage]} {new Date(user.createdAt ?? new Date()).getFullYear()}</p>
                                        </span>
                                    </div>

                                </div>
                                <div className='contact_informations_hidden'>
                                    <div>
                                        <span onClick={(event) => {
                                            event.stopPropagation();
                                            Object.keys(this.props.userInformation).length === 0
                                                ? this.setState({
                                                    callToLogin: true
                                                })
                                                : this.setState({
                                                    wantReport: !this.state.wantReport
                                                });
                                        }}>
                                            <i className='fa fa-flag' id='flag'/>
                                        </span>

                                        <a href={`mailto:${user.Email ?? `${appName}example.com`}`}>
                                            <i className='fa fa-envelope' />
                                        </a>
                                    </div>
                                    <div>
                                        <a href={`tel:${user.PhoneNumber ?? '+000 1111 222 333'}`}>
                                            <i className="fa fa-phone" />
                                        </a>
                                        <a href={`https://wa.me/${String(user.WhatsappNumber ?? `+000 1111 222 333`)
                                            .replaceAll("+", "")
                                            .replaceAll("-", "")
                                            .replaceAll("(", "")
                                            .replaceAll(")", "")
                                            }`} target="_blank" rel='noreferrer'>
                                            <i className="fa-brands fa-whatsapp" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            {/* <div>
                                {this.props.userInformation}
                            </div> */}
                            <div className='flex Supplementary_information' style={{ columnGap: '.5rem' }}>
                                <a href={`mailto:${user?.Email ?? `${String(appName).toLocaleLowerCase()}user@example.com`}`}>
                                    <div className='action_button flex'>
                                        <i className='fa fa-envelope' />
                                        <h6>E-Mail</h6>
                                    </div>
                                </a>
                                <div className='action_button flex' onClick={(event) => {
                                    event.stopPropagation();
                                    Object.keys(this.props.userInformation).length === 0 ? this.setState({ callToLogin: true }) : this.setState({ wantReport: !this.state.wantReport });
                                }}>
                                    <i className='fa fa-flag' />
                                    <h6>{{
                                        fr: 'Signaler',
                                        en: 'Report',
                                        sw: 'Ripoti'
                                    }[this.props.currentLanguage]}</h6>
                                </div>
                            </div>

                        </div>
                        <div className='profile_page_left_profile_stat Supplementary_information'>
                            <div className='profile_page_left_profile_stat_item'>
                                <i className='fa fa-house' />
                                <p>{Metadata?.House?.ActifEntity ?? 0}</p>
                            </div>
                            <div className='profile_page_left_profile_stat_item'>
                                <i className='fa fa-hotel' />
                                <p>{Metadata?.Hotel?.ActifEntity ?? 0}</p>
                            </div>
                            <div className='profile_page_left_profile_stat_item'>
                                <i className='fa fa-mountain-city' />
                                <p>{Metadata?.Tourism?.ActifEntity ?? 0}</p>
                            </div>
                        </div>
                        <div style={{ width: '100%' }} className="Supplementary_information">
                            <div className="tiles_single_content">
                                <i id="inside_icon" className="fa fa-envelope" />
                                <div className="title_and_details_container">
                                    <h4>E-mail</h4>
                                    <p>{user.Email ?? `${String(appName).toLocaleLowerCase()}user@example.com`}</p>
                                </div>
                            </div>
                            <div className="tiles_single_content">
                                <i id="inside_icon" className="fa fa-phone" />
                                <div className="title_and_details_container">
                                    <h4>{{
                                        fr: 'Numéro de téléphone',
                                        en: 'Phone number',
                                        sw: 'Nambari ya simu'
                                    }[this.props.currentLanguage]}</h4>
                                    <p>{user.PhoneNumber ?? `+000 1111 222 333`}</p>
                                </div>
                            </div>
                            <div className="tiles_single_content">
                                <i id="inside_icon" className="fa-brands fa-whatsapp" />
                                <div className="title_and_details_container">
                                    <h4>Whatsapp</h4>
                                    <p>{user.WhatsappNumber ?? `+000 1111 222 333`}</p>
                                </div>
                            </div>
                            <div className="tiles_single_content">
                                <i id="inside_icon" className="fa fa-right-to-bracket" />
                                <div className="title_and_details_container">
                                    <h4>{{
                                        fr: 'Inscrit depuis',
                                        en: 'Joined since',
                                        sw: 'Amejiunga tangu'
                                    }[this.props.currentLanguage]}</h4>
                                    <p>{new Date(user.createdAt ?? new Date()).getFullYear()}</p>
                                </div>
                            </div>
                        </div>
                    </div> : <></>
                }
                {(!this.state.isAsyncCall && Object.keys(this.state.user).length > 0)
                    ? <Displayer key={'userH'} categories={randomUserCategories({ id: Number(this.state.user.id) })} /> : <></>}

            </div>
        )
    }


    gettingUser(uuid) {
        this.setState({ isAsyncCall: true });
        const restApiServices = new ApiProvider();
        return restApiServices.provider({
            baseUrl: systemAuthBaseUrl,
            endpoint: `api/user-overview-by-uid/${uuid}?metadata=1`,
            apiMethod: ApiMethods.get,
            successCodeExpectation: 200,
            requestData: {
                token: {
                    accessToken: ''
                }
            },
        }).then((response) => {
            if (typeof (response.error) === "undefined") {
                this.setState({ user: response.response, isAsyncCall: false, });
            } else {
                this.setState({ isAsyncCall: false, user: madoshomeUSer, errorMessage: getErrorMessage(response.error, 'en') });
            }
        });
    };
}






