import './notifier.css'





export function Notifierpopup({ Illustration, msg, onClose }) {
    return (
        <div className='curtain_small modal_rideau' onClick={(event) => {
            event.stopPropagation()
            onClose()
        }}>
            <div className="popup_container">

                <img src={Illustration} alt="Illustration" />
                <div className="text_popup_container">
                    <p>
                        {msg}
                    </p>

                    <div className='close_popup' onClick={(event) => {
                        event.stopPropagation()
                        onClose()
                    }}>
                        Close
                    </div>
                </div>
            </div>
        </div>
    )
}
export default function Notifier({ type = 'success', message = '' }) {
    return <div className={`${type} notifier flex`}><h6>{message}</h6></div>
}


