import React from "react";
import CategoryDisplayer from "../../components/category_displayer/category_displayer";
import './publish.css';
import PublishHotelForm from "./publish_hotel_form";
import PublishPropertyForm from "./publish_property_form";
import PublishTourism from "./publish_tourism";





export default class Publish extends React.Component {

    state = {
        currentFormIndex: 0,
    }
    render() {
        const currenForm = [<PublishPropertyForm />, <PublishHotelForm />, <PublishTourism />][this.state.currentFormIndex];
        return <div className="modal_rideau">
            <div className="publish_modal dif_modal">
                <div className="flex publish_displayer_header">
                    <CategoryDisplayer onChange={(i) => {
                        this.setState({ currentFormIndex: i });
                    }} />
                    <span id='cross_container' onClick={() => { this.props.onClosePressed() }}>
                        <i className="fa-solid fa-xmark"></i>
                    </span>
                </div>
                {currenForm}
                <div className="divider" /><div className="divider" />
            </div>
        </div>
    }
}



