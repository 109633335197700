import React from "react";
import { connect } from "react-redux";
import ApiProvider, { ApiMethods } from "../../../data/providers/api_services";
import { capitalize, getErrorMessage, isEmpty, madoshomeUSer } from "../../../utils/utils";
import Authentication from "../../pages/Authentication/authentication";
// import CustomCarousel from "../caroussel/caroussel";
import Loader from "../loader/loader";
import Notifier from "../notifier/notifier";
import './details_displayer.css';
import { ShimmerCategoryList } from "react-shimmer-effects";
import { systemAuthBaseUrl, systemFavoriteBaseUrl } from "../../../config/config";
import CommentsDisplayer from "../comments/comments";
import ViewOverComments from "../comments/view_over_comments";
import Report from "../../pages/my_account/report";
import Caroussel002 from "../caroussel/caroussel002";
import { Link } from "react-router-dom";
// import EmbeddedMap from "../map/map";



class DetailsDisplayer extends React.Component {

    state = {
        errorMessage: '',
        isAsyncCall: true,
        onAuth: false,
        likes: 0,
        unlikes: 0,
        comments: 0,
        entityObject: {},
        publisher: {},
        hasLiked: false,
        hasUnliked: false,
        isLikeActionCall: false,
        viewComment: false,
        wantReport: false,
        onMapLoaded: 0,
    }

    componentDidMount() {
        this.GetEntityObject(this.props.detailsOptions.entityId);
    }

    onActions({ callBack = (() => { }) }) {
        console.log(this.props);
        if (Object.keys(this.props.userInformation).length) {
            callBack();
        } else {
            this.setState({ onAuth: true });
        }
    }

    defaultOptions = { images: [], video: '', views: 0, entityId: 0, likes: 0, unlikes: 0, comments: 0, baseUrl: 'https://example.com', likeEndpoint: 'like', unlikeEndpoint: 'unlike', entityDetailsUi: <div />, commentEndpoint: 'comment', fetchingCommentsEnpoint: 'get-comments' };

    render() {
        const { entityId, images, baseUrl, likeEndpoint, unlikeEndpoint, entityDetailsUi, commentEndpoint, fetchingCommentsEnpoint, deleteCommentEndpoint, reportEndpoint, reportKey, commentBody, bookmarkEndpoint } = this.props.detailsOptions ?? this.defaultOptions;
        const entityObject = this.state.entityObject;
        const hasLoggedIn = Object.keys(this.props.userInformation).length > 0;
        return <div className='details_displayer_screen'>
            {this.state.onAuth === true &&
                <Authentication
                    onClosePressed={() => { this.setState({ onAuth: !this.state.onAuth }) }}
                />
            }
            {this.state.wantReport === true &&
                <Report
                    endpoint={reportEndpoint}
                    entity={{ key: reportKey, value: entityId }}
                    onClosePressed={() => { this.setState({ wantReport: !this.state.wantReport }) }}
                />
            }
            {(this.state.isAsyncCall || !isEmpty(this.state.errorMessage)) && <div className="details_displayer_screen_message_loader">
                {this.state.isAsyncCall && <Loader />}
                <div className="divider" /><div className="divider" />
                {!isEmpty(this.state.errorMessage) && <>
                    <Notifier type='error' message={this.state.errorMessage} />
                    <div className="divider" />
                </>}
            </div>}
            {(!this.state.isAsyncCall && isEmpty(this.state.errorMessage)) && <div className='details_displayer_screen_displayer'>
                <div className='details_displayer_screen_displayer_left' >
                    <Caroussel002
                        video={entityObject.Video}
                        initialValues={images}
                        builder={(index) => {
                            return Object.keys(images[index] ?? {}).length ? <img alt='' src={images[index]?.Url} style={{ height: '100%', width: '100%', objectFit: 'cover' }} /> : <></>
                        }}
                    />
                    {/* <CustomCarousel images={images.map((e) => { return e.Url })} isLarge={true} videoUrl={entityObject.Video && `${entityObject.Video}`} /> */}
                    <div className='details_displayer_screen_displayer_left_likes'>
                        <div className='details_displayer_screen_displayer_left_likes_left flex'>
                            <div className='details_displayer_screen_displayer_left_likes_left_item flex'>
                                <i className='fa fa-eye' />
                                <h6>{entityObject.View}</h6>
                            </div>
                            <div className={`details_displayer_screen_displayer_left_likes_left_item flex ${this.state.hasLiked && 'i_active'}`}>
                                <i className='fa fa-thumbs-up' onClick={() => {
                                    this.onActions({
                                        callBack: () => {
                                            if (!this.state.hasLiked) {
                                                this.likeAndUnActions({
                                                    entityId: entityId, baseUrl: baseUrl, endpoint: likeEndpoint, onSucess: () => {
                                                        this.setState({ hasLiked: true, hasUnliked: false, unlikes: this.state.hasUnliked ? Number(this.state.unlikes) - 1 : this.state.unlikes, likes: Number(this.state.likes) + 1 });
                                                    }
                                                });
                                            }
                                        }
                                    });
                                }} />
                                <h6>{this.state.likes}</h6>
                            </div>
                            <div className={`details_displayer_screen_displayer_left_likes_left_item flex ${this.state.hasUnliked && 'i_active'}`}>
                                <i className='fa fa-thumbs-down' onClick={() => {
                                    this.onActions({
                                        callBack: () => {
                                            if (!this.state.hasUnliked) {
                                                this.likeAndUnActions({
                                                    entityId: entityId, baseUrl: baseUrl, endpoint: unlikeEndpoint, onSucess: () => {
                                                        this.setState({ hasLiked: false, hasUnliked: true, likes: this.state.hasLiked ? Number(this.state.likes) - 1 : this.state.likes, unlikes: Number(this.state.unlikes) + 1 });
                                                    }
                                                });
                                            }
                                        }
                                    });
                                }} />
                                <h6>{this.state.unlikes}</h6>
                            </div>
                            <div className='details_displayer_screen_displayer_left_likes_left_item flex'>
                                <i className='fa fa-comments' onClick={(event) => {
                                    event.stopPropagation();
                                    if (Number(this.state.comments)) {
                                        this.setState({ viewComment: !this.state.viewComment });
                                    }
                                }} />
                                <h6>{this.state.comments}</h6>
                            </div>
                        </div>
                        <div className='share_to_social_media flex'>
                            {hasLoggedIn ? <SaveToFavorite
                                entityId={entityId}
                                commentBody={commentBody}
                                bookmarkEndpoint={bookmarkEndpoint}
                                token={this.props.userInformation?.accessToken}
                            /> : <></>}
                            <i className='fa fa-flag' onClick={(event) => {
                                event.stopPropagation();
                                this.onActions({
                                    callBack: () => {
                                        this.setState({ wantReport: !this.state.wantReport });
                                    }
                                });
                            }} />
                        </div>
                    </div>
                    {this.state.viewComment ? <ViewOverComments
                        onClosePressed={() => { this.setState({ viewComment: !this.state.viewComment }) }}
                        options={{
                            comments: this.state.comments,
                            entityId: entityId,
                            currentUser: this.props.userInformation?.id,
                            baseUrl: baseUrl,
                            commentBody: commentBody,
                            commentEndpoint: commentEndpoint,
                            deleteCommentEndpoint: deleteCommentEndpoint,
                            fetchingCommentsEnpoint: fetchingCommentsEnpoint,
                            onCommentsStateUpdate: (comments) => {
                                this.setState({ comments: comments });
                            }

                        }} /> : <></>}

                    <CommentsDisplayer options={{
                        comments: this.state.comments,
                        entityId: entityId,
                        currentUser: this.props.userInformation?.id,
                        baseUrl: baseUrl,
                        commentBody: commentBody,
                        commentEndpoint: commentEndpoint,
                        deleteCommentEndpoint: deleteCommentEndpoint,
                        fetchingCommentsEnpoint: fetchingCommentsEnpoint,
                        onCommentsStateUpdate: (comments) => {
                            this.setState({ comments: comments });
                        }
                    }} />
                </div>
                <div className='details_displayer_screen_displayer_right'>

                    {entityDetailsUi}
                    <div className='divider' />
                    {/* <div className="this_map_holder">
                        <EmbeddedMap
                            coordinatesBuilder={(index) => {
                                return { lat: Number(entityObject.Latitude), lng: Number(entityObject.Longitude) }
                            }}
                            markerCount={1}
                            infoWindoW={<div className="this_map_holder_info">
                                <p>{{
                                    fr: "Adresse complète",
                                    en: "Full address",
                                    sw: "Anwani kamili"
                                }[`${this.props.currentLanguage.code}`]}</p>
                                <h6 className="this_map_holder_info_details">{entityObject?.Address}</h6>
                            </div>}

                        />
                    </div> */}
                    <div className="mapouter" style={{ position: 'relative', textAlign: 'right', height: '40rem', width: '100%' }}>
                        <div className="gmap_canvas">
                            <iframe
                                title="Map"
                                width="100%"
                                height="20rem"
                                id="gmap_canvas"
                                style={{ overflow: 'hidden', background: 'non!important', height: '40rem', width: '100%', borderRadius: '.3rem' }}
                                src={`https://maps.google.com/maps?q=${Number(entityObject.Latitude)},${Number(entityObject.Longitude)}&z=15&output=embed`}
                                frameBorder="0"
                                scrolling="no"
                                marginHeight="0"
                                marginWidth="0" />
                            <br />
                        </div>
                    </div>
                    <div className='divider' />
                    <PublisherCard id={entityObject.Publisher} userInformation={this.props.userInformation} currentLanguage={this.props.currentLanguage} />
                    <div className='divider' />
                </div>
            </div>}
        </div>
    }

    GetEntityObject(id) {
        this.setState({ isAsyncCall: true });
        const restApiServices = new ApiProvider();
        restApiServices.provider({
            baseUrl: this.props.detailsOptions.baseUrl,
            endpoint: `api/${this.props.detailsOptions.entityEndpoint}/${id}`,
            apiMethod: ApiMethods.get,
            successCodeExpectation: 200,
            requestData: {
                token: {
                    accessToken: this.props.userInformation?.accessToken
                }
            },
        }).then((response) => {
            if (typeof (response.error) === "undefined") {
                const { object, liked, unliked } = response.response;
                this.props.detailsOptions.onObjectReady(object);
                this.setState({
                    entityObject: object,
                    isAsyncCall: false,
                    hasLiked: liked,
                    hasUnliked: unliked,
                    likes: object.Love,
                    unlikes: object.UnLike,
                    comments: object.Comment
                });
            } else {
                this.setState({ isAsyncCall: false, errorMessage: getErrorMessage(response.error, 'en') });
            }
        });
    };

    likeAndUnActions({ entityId = 0, endpoint = 'test', baseUrl = 'https://example.com', onSucess = () => { } }) {
        if (!this.state.isLikeActionCall) {
            this.setState({ isLikeActionCall: true });
            const restApiServices = new ApiProvider();
            restApiServices.provider({
                baseUrl: baseUrl,
                endpoint: `api/${endpoint}/${entityId}`,
                apiMethod: ApiMethods.get,
                successCodeExpectation: 200,
                requestData: {
                    token: {
                        accessToken: this.props.userInformation?.accessToken
                    }
                },
            }).then((response) => {
                if (typeof (response.error) === "undefined") {
                    this.setState({ isLikeActionCall: false });
                    onSucess();
                } else {
                    this.setState({ isLikeActionCall: false });
                }
            });
        }
    }


}

const mapStateToProps = (storeOf) => ({
    currentLanguage: storeOf.madoshome.currentLanguage,
    userInformation: storeOf.madoshome.userInformation,
});

export default connect(mapStateToProps)(DetailsDisplayer);




class PublisherCard extends React.Component {
    state = {
        currentPublisher: {},
        errorMessage: '',
        isAsyncCall: true,
    }
    componentDidMount() {
        this.onGettingPublisherInfo(this.props.id);
    }
    render() {
        const publisher = this.state.currentPublisher;
        console.log(publisher)
        return <>
            {this.state.isAsyncCall ? <ShimmerCategoryList title items={1} categoryStyle="STYLE_SEVEN" />
                : <div className='publisher_card'>
                    <img className='publisher_card_left' src={publisher.ProfilImage ?? `https://avatars.dicebear.com/api/personas/male/${Math.floor(Math.random() * 4777777770)}.png`} alt='' />
                    <div className="user_information_container">
                        <Link to={`/users/${publisher.UID}`}><h3>{publisher.Fullname}</h3></Link>
                        <p id='usertype'>{ 
                            publisher?.UserType[capitalize(this.props?.currentLanguage?.code)]
                        }</p>
                        <a href={`mailto:${publisher?.Email ?? ''}`}>
                            <h5 id='email'>{publisher.Email ?? "uiellele@gmail.com"}</h5>
                        </a>
                        <div className="contact_user">
                            {publisher.PhoneNumber ? <span>
                                <i className="fa-solid fa-phone"></i>
                                <a href={`tel:${publisher?.PhoneNumber ?? ''}`}>
                                    <h5>{publisher.PhoneNumber}</h5>
                                </a>

                            </span> : <></>}
                            {publisher.PhoneNumber ? <span>
                                <i className="fa-brands fa-whatsapp"></i>
                                <a href={`https://wa.me/${String(publisher?.WhatsappNumber)
                                    .replaceAll("+", "")
                                    .replaceAll("-", "")
                                    .replaceAll("(", "")
                                    .replaceAll(")", "")
                                    }`} target="_blank" rel='noreferrer'>
                                    <h5>{publisher.WhatsappNumber}</h5>
                                </a>

                            </span> : <></>}
                        </div>
                    </div>
                </div>}
        </>
    }

    onGettingPublisherInfo(id) {
        if (id === this.props.userInformation.id) {
            this.setState({ currentPublisher: this.props.userInformation, isAsyncCall: false, });
        } else {
            this.setState({ isAsyncCall: true });
            const restApiServices = new ApiProvider();
            return restApiServices.provider({
                baseUrl: systemAuthBaseUrl,
                endpoint: `api/user-overview-by-id/${id}`,
                apiMethod: ApiMethods.get,
                successCodeExpectation: 200,
                requestData: {
                    token: {
                        accessToken: ''
                    }
                },
            }).then((response) => {
                if (typeof (response.error) === "undefined") {
                    this.setState({ currentPublisher: response.response, isAsyncCall: false, });
                } else {
                    this.setState({ isAsyncCall: false, currentPublisher: madoshomeUSer, errorMessage: getErrorMessage(response.error, 'en') });
                }
            });
        }
    };
}


class SaveToFavorite extends React.Component {


    state = {
        hasAddToBookMark: false,
        isLikeActionCall: false,
        favorite: {},
    }

    componentDidMount() {
        this.checkIfExist({ onSucess: () => { } });
    }

    checkIfExist({ onSucess = () => { } }) {
        if (!this.state.isLikeActionCall) {
            const { token, entityId, bookmarkEndpoint } = this.props;
            this.setState({ isLikeActionCall: true });
            const restApiServices = new ApiProvider();
            restApiServices.provider({
                baseUrl: systemFavoriteBaseUrl,
                endpoint: `api/${bookmarkEndpoint}/is-a-favorite/${entityId}`,
                apiMethod: ApiMethods.get,
                successCodeExpectation: 200,
                requestData: {
                    token: { accessToken: token },
                },
            }).then((response) => {
                if (typeof (response.error) === "undefined") {
                    this.setState({ isLikeActionCall: false, hasAddToBookMark: true, favorite: response.response });
                    // onSucess(response.response);
                } else {
                    this.setState({ isLikeActionCall: false });
                }
            });
        }
    }


    removeToFavorite({ entityId = 0, endpoint = 'test' }, onSucess = () => { }) {
        if (!this.state.isLikeActionCall) {
            const { token } = this.props;
            this.setState({ isLikeActionCall: true });
            const restApiServices = new ApiProvider();
            restApiServices.provider({
                baseUrl: systemFavoriteBaseUrl,
                endpoint: `api/${endpoint}/delete-favorite/${entityId}`,
                apiMethod: ApiMethods.delete,
                successCodeExpectation: 200,
                requestData: {
                    token: { accessToken: token },
                    body: {}
                },
            }).then((response) => {
                if (typeof (response.error) === "undefined") {
                    this.setState({ isLikeActionCall: false, hasAddToBookMark: false, favorite: false });
                    onSucess();
                } else {
                    this.setState({ isLikeActionCall: false });
                }
            });
        }
    }


    addToBookMark({ entityId = 0, body = {}, endpoint = 'test', onSucess = () => { } }) {
        if (!this.state.isLikeActionCall) {
            const { token, } = this.props;
            this.setState({ isLikeActionCall: true });
            const restApiServices = new ApiProvider();
            restApiServices.provider({
                baseUrl: systemFavoriteBaseUrl,
                endpoint: `api/${endpoint}/add-favorite`,
                apiMethod: ApiMethods.post,
                successCodeExpectation: 200,
                requestData: {
                    token: {
                        accessToken: token,
                    },
                    body: {
                        ...body,
                    }
                },
            }).then((response) => {
                if (typeof (response.error) === "undefined") {
                    this.setState({ isLikeActionCall: false, hasAddToBookMark: true, favorite: response.response });
                    onSucess();
                } else {
                    this.setState({ isLikeActionCall: false, hasAddToBookMark: false });
                }
            });
        }
    }

    render() {
        const { entityId, commentBody, bookmarkEndpoint } = this.props;
        return (
            <div className={`details_displayer_screen_displayer_left_likes_left_item flex ${this.state.hasAddToBookMark && 'i_active'}`}>
                {this.state.isLikeActionCall
                    ? <i className='fa fa-spinner fa-spin' />
                    : <i className='fa fa-bookmark' onClick={() => {
                        if (!this.state.hasAddToBookMark) {
                            this.addToBookMark({
                                entityId: entityId,
                                body: commentBody,
                                endpoint: bookmarkEndpoint,
                                onSucess: () => {
                                    // this.setState({ hasAddToBookMark: !this.state.hasAddToBookMark });
                                }
                            });
                        } else {
                            this.removeToFavorite({
                                entityId: this.state.favorite.id,
                                endpoint: bookmarkEndpoint,
                                onSucess: () => {
                                }
                            });
                        }
                    }} />
                }
            </div>
        )
    }
}
