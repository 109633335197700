import React from 'react';
import DetailsModal from '../../pages/details_modal/details_modal';
import DetailsDisplayer from '../details_displayer/details_displayer';
import CustomCarousel from '../caroussel/caroussel';
import './hotel_card.css';
import { systemHotelsBaseUrl } from '../../../config/config';
import Numeral from 'react-numeral';
import { CalcCrow } from '../../../utils/utils';
import UpdateHotel from './hotel_updates';
import { HotelDetailsUI } from './hotel_details';
import { connect } from 'react-redux';



class HotelCard extends React.Component {

    state = {
        isDetailsDisplayed: false,
        hotel: {},
    }

    render() {
        const { id, hotelId, Actif, HotelImages, Name, Latitude, Longitude, Rating } = this.props.hotel;
        return <div className='hotel_card' >
            {(this.props.isUpdateTime && !Actif) ? <div className='show_actif_status' id={`${!Actif && 'show_actif_status_disabled'}`}>{Actif ? <i className='fa fa-eye' /> : <i className='fa fa-eye-slash' />}</div> : <></>}
            {this.state.isDetailsDisplayed && <DetailsModal
                body={<DetailsDisplayer detailsOptions={{
                    entityId: hotelId ? hotelId : id,
                    images: HotelImages,
                    baseUrl: systemHotelsBaseUrl,
                    entityEndpoint: 'hotel/view-over-hotel',
                    likeEndpoint: 'like',
                    unlikeEndpoint: 'unlike',
                    commentBody: { HotelId: hotelId ? hotelId : id },
                    entityDetailsUi: <HotelDetailsUI
                        key={Math.random() * 9038023801}
                        hotel={this.state.hotel}
                        types={this.props.roomTypes}
                        currencies={this.props.currencies}
                    />,
                    commentEndpoint: 'comment/add-comment',
                    deleteCommentEndpoint: 'comment/remove-comment',
                    fetchingCommentsEnpoint: 'comment/filter-comment',
                    reportEndpoint: 'add-report-Hotel',
                    bookmarkEndpoint: 'hotel',
                    reportKey: 'idhotel',
                    onObjectReady: (object) => { this.setState({ hotel: object }) },
                }} />}
                onClose={() => { this.setState({ isDetailsDisplayed: !this.state.isDetailsDisplayed }) }} />
            }
            {this.state.isUpdateTime && <DetailsModal
                body={<UpdateHotel hotel={this.props.hotel} onDeleteObject={() => {
                    this.setState({ isUpdateTime: false })
                }} />}
                onClose={() => { this.setState({ isUpdateTime: false }) }}
            />
            }
            <CustomCarousel images={HotelImages.map((e) => { return e.Url })} />
            <div className='hotel_card_details' onClick={(event) => {
                event.stopPropagation();
                if (this.props.isUpdateTime) {
                    this.setState({ isUpdateTime: !this.state.isUpdateTime })
                } else {
                    this.setState({ isDetailsDisplayed: !this.state.isDetailsDisplayed })
                }
            }}>
                <div className='hotel_card_details_price_like flex'>
                    <div className='hotel_card_details_star'>
                        <h4>{Name ?? ''}</h4>
                        {Array.from({ length: Rating?.Start ?? 0 }, (_, i) => {
                            return <i key={i} className='fa fa-star' />
                        })}
                    </div>
                    {/* 
                    <i className='fa fa-heart' onClick={(event) => {
                        event.stopPropagation();
                        console.log('You liked this hotel dudeeee');
                    }} /> */}
                </div>
                <div className='houses_card_details_more flex'>
                    <i className='fa fa-map-location-dot' />
                    <h6><Numeral value={CalcCrow(Latitude, Longitude, this.props.userLocation?.lat, this.props.userLocation?.lon)} format={'0.0a'} />{{
                        fr: " Kilomètre de loins",
                        en: " Kilometer away",
                        sw: " Kilomita mbali"
                    }[`${this.props.currentLanguage}`]}</h6>
                </div>
            </div>
        </div>
    }
}

const mapStateToProps = (storeOf) => ({
    currentLanguage: storeOf.madoshome.currentLanguage.code,
    userInformation: storeOf.madoshome.userInformation,
    userLocation: storeOf.madoshome.userLocation,
    currencies: storeOf.madoshome.currencies,
    roomTypes: storeOf.madoshome.roomTypes,
});

export default connect(mapStateToProps)(HotelCard);


