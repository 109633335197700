import React from "react";
import Numeral from "react-numeral";
import { capitalize, isEmpty } from "../../../utils/utils";
import { useSelector } from "react-redux";
import Caroussel002 from "../caroussel/caroussel002";
import Notifier from "../notifier/notifier";
import RawTitleValue from "../title_value/title_value";

export function HotelDetailsUI({ hotel = {}, types = [], currencies = [] }) {
    const currentLanguage = useSelector(state => state.madoshome.currentLanguage.code)
    return <div  className='hotel_details_screen_displayer_right'>
        {(hotel.Info && !isEmpty(hotel.Info)) ? <Notifier message={hotel.Info} type='info' /> : <></>}
        <div className="divider" />
        <div className="divider" />
        <div className='hotel_details_screen_displayer_right_header flex'>
            <div className='hotel_card_details_star'>
                <h3>{hotel?.Name ?? ''}</h3>
                {Array.from({ length: hotel?.Rating?.Start ?? 0 }, (_, i) => {
                    return <i key={i} className='fa fa-star' />
                })}
            </div>
            <div className="flex details_actions_separator">
                {hotel.Website && <a href={hotel.Website} target='_blank' rel='noreferrer'>
                    <div className='action_button flex'>
                        <i className='fa fa-globe' style={{ color: 'var(--textColorOnColoredCard)' }} />
                    </div>
                </a>}
                {hotel.Email && <a href={`mailto:${hotel.Email}`} target='_blank' rel='noreferrer'>
                    <div className='action_button flex'>
                        <i className='fa fa-envelope' style={{ color: 'var(--textColorOnColoredCard)' }} />
                    </div>
                </a>}
            </div>
        </div>
        <div>
            <RawTitleValue title={{
                fr : "Numéro de téléphone",
                en : "Phone number",
                sw : "Nambari ya simu"
            }[`${currentLanguage}`]} value={`${hotel?.Contact}`} />
            <RawTitleValue title={{
                fr : "Adresse",
                en : "Address",
                sw : "Anwani"
            }[`${currentLanguage}`]} value={`${hotel?.Address}`} />
        </div>

        {hotel?.Description ? <>
            <div className='divider' /><div className='divider' />
            <h3>{{
                fr : "Description",
                en : "Description",
                sw : "Maelezo"
            }[`${currentLanguage}`]}</h3>
            <div className="divider" />
            <p>{hotel?.Description}</p>
            <div className='divider' />
        </> : <></>}


        {hotel?.Rooms?.length ? <>
            <div className='divider' /><div className='divider' />
            <h3>{{
                fr : "Pièce ",
                en : "Rooms ",
                sw : "Vyumba "
            }[`${currentLanguage}`]}</h3>
            <div className="divider" />
            <HotelsRooms rooms={hotel.Rooms ?? []} types={types} currencies={currencies} currentLanguage ={currentLanguage} />
            <div className='divider' />
        </> : <></>}
    </div>
}



class HotelsRooms extends React.Component {

    state = {
        currentIndex: 0,
    }

    render() {
        console.log(this.props)
        const { Price, Currency, RoomImages, Description } = this.props.rooms[this.state.currentIndex] ?? {};
        return this.props?.rooms?.length ? <div className='hotels_rooms_types'>
            <div className='hotels_rooms_types_names flex'>
                {this.props.rooms.map((e, i) => {
                    var isS = i === this.state.currentIndex;
                    return <h4
                        key={e.id}
                        className='hotels_rooms_types_names_items'
                        id={`${isS && "hotels_rooms_types_names_items_selected"}`}
                        onClick={(event) => {
                            event.stopPropagation();
                            this.setState({ currentIndex: i })
                        }}
                    // >{capitalize(this.props.types.filter((element) => element.id === Number(e.RoomTypeId))[0]?.Label)}</h4>
                    >{capitalize(e?.RoomType?.Label)}</h4>
                })}
            </div>
            <div className='divider' />
            <div className='hotels_rooms_types_names_car_holder' >
                <div style={{ height: '25rem', minHeight: '25rem', width: '100%' }}>
                    <Caroussel002
                        key={Math.random()}
                        initialValues={RoomImages ?? []}
                        builder={(index) => {
                            return Object.keys(RoomImages[index] ?? {}).length ? <img alt='' src={RoomImages[index]?.Url} style={{ height: '100%', width: '100%', objectFit: 'cover' }} /> : <></>
                        }} 
                    />
                </div>
            </div>
            <div  className="houses_card_details_price_like flex">
                <div className='house_card_details_star'>
                    <h3>{this.props?.currencies.filter((e) => e.id === Number(Currency))[0]?.Name} <Numeral value={Price} format={'0.0a'} /></h3>
                    <p>{{
                        fr : "par nuit",
                        en : "Per Night",
                        sw : "Kwa usiku"
                    }[`${this.props.currentLanguage}`]}</p>
                </div>
            </div>
            <div className='divider' /><div className='divider' />
            <p>{Description}</p>
            <div className='divider' />
        </div> : <></>
    }
}

