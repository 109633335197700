import React, { createRef } from "react";
import { getErrorMessage, isEmpty } from "../../../utils/utils";
import Notifier from "../../components/notifier/notifier";
import './forget_password.css';
import Joi from "joi";
import ApiProvider, { ApiMethods } from "../../../data/providers/api_services";
import { systemAuthBaseUrl } from "../../../config/config";
import { connect } from "react-redux";


class SignUpVerification extends React.Component {

    state = {
        isAsyncCall: false,
        errorMessage: '',
        successMessage: '',
        currentEmailOrUsername: '',
        formValues: {},
        doUserExistKnown: false,
    }

    componentDidMount() {
        this.onSendingMail();
    }

    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState(state => ({ ...state, formValues: { ...state.formValues, [name]: value } }));
    }


    formRef = createRef();

    render() {
        const joiSchema = Joi.object({
            VerificationCode: Joi.number().required(),
        });
        // console.log(this.props)
        return <div className="modal_rideau">
            <div id="forget_password" className="dif_modal">
                <div className="flex call_to_subcsription_header update_user_informations_header">
                    <div>
                        <h3>{{
                            fr: "Code de vérification",
                            en: "Verification code",
                            sw: "Nambari ya uthibitishaji"
                        }[`${this.props.currentLanguage}`]}</h3>
                        <p>
                            {{
                                fr: `Un code de vérification a été envoyé à ${this.props?.email} Consultez votre boîte de réception pour vefifier votre compte.`,
                                en: `A verification code has been sent to ${this.props?.email}. Consult your inbox to verify your account.`,
                                sw: `Nambari ya kuthibitisha imetumwa kwa ${this.props?.email}. Angaliya kisanduku pokezi ili uthibitishe akaunti yako.`
                            }[`${this.props.currentLanguage}`]}
                        </p>
                    </div>
                    <span id='cross_container' onClick={() => { this.props.onClosePressed() }}>
                        <i className="fa-solid fa-xmark"></i>
                    </span>
                </div>
                <div className="divider" />
                {!isEmpty(this.state.errorMessage) && <>
                    <Notifier type='error' message={this.state.errorMessage} />
                    <div className="divider" />
                </>}
                {!isEmpty(this.state.successMessage) && <>
                    <Notifier type='success' message={this.state.successMessage} />
                    <div className="divider" />
                </>}
                <fieldset disabled={this.state.isAsyncCall}>
                    <form ref={this.formRef} className="" onChange={this.handleChange} onSubmit={(event) => {
                        event.preventDefault();
                        this.setState({ errorMessage: '' });
                        joiSchema.validateAsync(this.state.formValues).then((formValues) => {
                            this.onUpdateInfo(formValues);
                        }).catch((error) => {
                            this.setState({ errorMessage: `${error.details.map((e) => e.message)}` });
                        });
                    }}>
                        <input type="text" name="VerificationCode" placeholder={{
                            fr: "Code de vérification",
                            en: "Verification code",
                            sw: "Nambari ya uthibitishaji"
                        }[`${this.props.currentLanguage}`]} />
                        <button className={`${this.state.isAsyncCall && 'button_on_async'}`} type={'submit'}>{{
                            fr: "Continuer",
                            en: "Continue",
                            sw: "Endeleya"
                        }[`${this.props.currentLanguage}`]}</button>
                    </form>
                </fieldset>
            </div>
        </div>
    }

    onSendingMail() {
        this.setState({ isAsyncCall: true });
        const restApiServices = new ApiProvider();
        restApiServices.provider({
            baseUrl: systemAuthBaseUrl,
            endpoint: 'api/mail-checker',
            apiMethod: ApiMethods.post,
            successCodeExpectation: 200,
            requestData: {
                token: {},
                body: {
                    Email: this.props.email,
                }
            },
        }).then((response) => {
            if (typeof (response.error) === 'undefined') {
                this.setState({ isAsyncCall: false });
            } else {
                console.log(response.error);
                this.setState({ isAsyncCall: false});
                this.props.onVerificationError(getErrorMessage("e13", 'en'))
            }
        });
    }

    onUpdateInfo(values) {
        const { VerificationCode } = values;
        this.setState({ isAsyncCall: true });
        const restApiServices = new ApiProvider();
        restApiServices.provider({
            baseUrl: systemAuthBaseUrl,
            endpoint: 'api/signup',
            apiMethod: ApiMethods.post,
            successCodeExpectation: 200,
            requestData: {
                token: {
                    accessToken: this.props?.userInformation?.accessToken,
                },
                body: {
                    ...this.props?.otherValues,
                    Email: {
                        Code: VerificationCode,
                        Email: this.props.email,
                    }
                }
            },
        }).then((response) => {
            if (typeof (response.error) === 'undefined') {
                this.formRef.current.reset();
                this.props.onUpdate();
                this.setState({ isAsyncCall: false });
                this.props.onClosePressed()
            } else {

                this.setState({ isAsyncCall: false});
                this.props.onVerificationError(getErrorMessage(response.error, 'en'))
                
            }
        });
    }
}

const mapStateToProps = (storeOf) => ({
    currentLanguage: storeOf.madoshome.currentLanguage.code,
    userInformation: storeOf.madoshome.userInformation,
    accountTypes: storeOf.madoshome.accountTypes,
});

export default connect(mapStateToProps)(SignUpVerification);