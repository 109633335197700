import { connect } from "react-redux";
import { appName, systemHotelsBaseUrl } from "../../../config/config";
import ApiProvider, { ApiMethods } from "../../../data/providers/api_services";
import { copyWith } from "../../../logic/slice";
import React from "react";

class HotelSearchForm extends React.Component {

    state = {
        isAsyncCall: false,
    }

    componentDidMount() {
        if (!this.props.ratingTypes.length || !this.props.roomTypes.length) {
            this.utilsFetcher();
        }
    }

    render() {
        return <fieldset disabled={this.state.isAsyncCall} className='search_form'>
            <input type={"text"} name={"Name"} placeholder="Name" />
            <select required={true} className="account_type" name="RatingId" defaultValue={0}>
                <option value={0} disabled={true}>{this.state.isAsyncCall ? 'Please wait..':'Stars'}</option>
                {this.props.ratingTypes.map((e, i) => {
                    return <option key={i} value={e.id}>{e.Start}</option>
                })}
            </select>
        </fieldset>
    }

    utilsFetcher() {
        this.setState({ isAsyncCall: true });
        const restApiServices = new ApiProvider();
        return restApiServices.provider({
            baseUrl: systemHotelsBaseUrl,
            endpoint: "api/utils",
            apiMethod: ApiMethods.get,
            successCodeExpectation: 200,
            requestData: {
                token: {
                    accessToken: localStorage.getItem(`${appName}act`) ?? ''
                }
            },
        }).then((response) => {
            if (typeof (response.error) === "undefined") {
                const { Rating, RoomType } = response.response;
                this.props.dispatch(copyWith({ ratingTypes: Rating ?? [], roomTypes: RoomType ?? [] }));
                this.setState({ isAsyncCall: false });
            } else {
                this.props.dispatch(copyWith({ ratingTypes: [], roomTypes: [] }));
                this.setState({ isAsyncCall: false });
            }
        });
    };
}

const mapStateToPropsHotel = (storeOf) => ({
    roomTypes: storeOf.madoshome.roomTypes,
    ratingTypes: storeOf.madoshome.ratingTypes,
});

export default connect(mapStateToPropsHotel)(HotelSearchForm);