/* eslint-disable no-loop-func */
import React from "react";
import imageCompression from 'browser-image-compression';

export default class FilePicker extends React.Component {
    render() {

        const { extensions, isMultiple, builder, isEnabled, max, onLoading } = this.props;
        const inputId = `${Math.random() * 287168762187}`;
        return (
            <fieldset disabled={!isEnabled}>
                <input id={inputId} accept={extensions} type='file' multiple={isMultiple} style={{ display: 'none' }} onChange={(event) => {
                    event.stopPropagation();
                    const fileListObject = event.target.files;
                    const f = Object.keys(fileListObject).map((e, i) => { return fileListObject[i] });
                    this.handleFileChange({ files: f, max: max, loadingCallBack: onLoading });
                }} />
                {builder(inputId)}
            </fieldset>
        )
    }

    getSize(l) {
        return l * 0.000001;
    }


    handleFileChange({ files = [], max = 0, loadingCallBack = () => { } }) {
        loadingCallBack(true);
        console.log("RECEIVED", files.length, "FILES BUT THE MAXIMUM EXPECTED NUMBER OF FILES IS", max);
        this.filesComputer({ files: files, max: max }).then((alloweFiles) => {
            this.props.onChange(alloweFiles);
            loadingCallBack(false);
        }).catch((e) => {
            this.props.onChange([]);
            loadingCallBack(false);
        })

    }

    async filesComputer({ files = [], max = 0 }) {
        const maxSize = 0.6;
        var allowedFiles = [];
        if(files.length > max){
            return;
        }
        for (var i = 0; i < files.length; i++) {
            console.log(files[i]);
            var currentF = files[i];
            var isCompressable = true;
            var fileSizeInMb = this.getSize(currentF.size);
            console.log("FILE AT INDEX", i, 'SIZE =>', fileSizeInMb);
            if (fileSizeInMb <= maxSize) {
                allowedFiles.push(currentF);
                console.log("FILE AT INDEX", i, "CAN GO WITHOUT COMPRESSION", allowedFiles.length);

            } else {
                console.log("FILE AT INDEX", i, "MUST GO AFTER COMPRESSION");
                try {
                    do {
                        console.log("LOOP CALLDED");
                        await this.compressorF(currentF).then((result) => {
                            console.log(result, 'REESSSS');
                            if (result !== null) {
                                fileSizeInMb = this.getSize(result.size);
                                isCompressable = true;
                                currentF = result;
                                if (fileSizeInMb <= maxSize) {
                                    isCompressable = false;
                                    allowedFiles = [...allowedFiles, currentF];
                                    console.log("FILE AT INDEX", i, "SUCCESS COMPRESSION can go", allowedFiles);

                                }
                            } else {
                                isCompressable = false;
                                console.log("FILE AT INDEX", i, "FAILED COMPRESSION can go anyway", currentF);
                                allowedFiles.push(currentF);
                            }
                        });
                    } while (isCompressable && (fileSizeInMb > maxSize))
                } catch (e) {
                    console.log("What's the matter ?", e);
                    isCompressable = false;
                    allowedFiles.push(currentF);
                    console.log("FILE AT INDEX", i, "FAILED COMPRESSION can go anyway");
                }
            }

        }
        console.log("BEFORE RETURN ", allowedFiles);
        return allowedFiles;
    }


    async compressorF(oGfile) {
        const options = {
            maxSizeMB: 0.5,
            useWebWorker: true
        }
        return await imageCompression (oGfile, options);
    }

}


