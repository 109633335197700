/* eslint-disable no-loop-func */
import React from "react";
import { connect } from "react-redux";
import { systemAuthBaseUrl } from "../../../config/config";
import ApiProvider, { ApiMethods } from "../../../data/providers/api_services";
import { capitalize, deleteFilesS3, getErrorMessage, isEmpty, uploadFilesS3 } from "../../../utils/utils";
import Notifier from "../../components/notifier/notifier";
import './update_user_info.css';
import Joi from "joi";
import { copyWith } from "../../../logic/slice";
import UpdateEmail from "../Authentication/update_email";
import FilePicker from "../../components/file_picker/file_picker";






class UpdateUserInfo extends React.Component {

    state = {
        isAsyncCall: false,
        errorMessage: '',
        successMessage: '',
        formValues: {
            Email: this.props.userInformation.Email,
            Fullname: this.props.userInformation.Fullname,
            PhoneNumber: this.props.userInformation.PhoneNumber,
            UserTypeId: this.props.userInformation.UserTypeId,
            WhatsappNumber: this.props.userInformation.WhatsappNumber,
        },
        formValuesToSend: {},
        didUpdateEmail: false,
    }

    setFormValues(userInformation) {
        const { Fullname, PhoneNumber, UserTypeId, WhatsappNumber, Email } = userInformation;
        this.setState({
            formValues: {
                Email: Email ?? '',
                Fullname: Fullname ?? '',
                PhoneNumber: PhoneNumber ?? '',
                UserTypeId: UserTypeId ?? '',
                WhatsappNumber: WhatsappNumber ?? '',
            }
        });
    }

    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState(state => ({ ...state, formValues: { ...state.formValues, [name]: value }, formValuesToSend: { ...state.formValuesToSend, [name]: value } }));
    }

    componentDidMount() {
        this.setFormValues(this.props.userInformation);
    }

    acceptOnly = ['jpeg', 'jpg', 'png'];


    render() {
        const waitingmsg = {
            'fr': "Patientez s'il vous plait...",
            'en': "Please wait...",
            'sw': "Tafadhali subiri..."
        }[`${this.props.currentLanguage}`]

        const update = {
            'fr': "Mettre à jours ",
            'en': "Update ",
            'sw': "Sasisha"
        }[`${this.props.currentLanguage}`]
        const joiSchema = Joi.object({
            Username: Joi.string(),
            Fullname: Joi.string().required(),
            Email: Joi.string().email({ tlds: { allow: false } }).required(),
            PhoneNumber: Joi.string(),
            WhatsappNumber: Joi.string(),
            UserTypeId: Joi.number().min(1).required(),
        });
        const extensions = String([...this.acceptOnly].map((e) => `.${e}`));
        return <div className="modal_rideau">
            <div className="update_user_informations dif_modal">
                {this.state.didUpdateEmail === true &&
                    <UpdateEmail
                        email={this.state.formValuesToSend.Email}
                        otherValues={this.state.formValuesToSend}
                        onClosePressed={() => { this.setState({ didUpdateEmail: !this.state.didUpdateEmail }) }}
                        onUpdate={() => { this.setState({ formValuesToSend: {} }) }}
                    />
                }
                <div className="flex call_to_subcsription_header update_user_informations_header">
                    <h2>{{
                        fr: "Mettez à jour vos informations d'utilisateur",
                        en: "Update your user information",
                        sw: "Sasisha maelezo yako ya mtumiaji"
                    }[`${this.props.currentLanguage}`]}</h2>
                    <span id='cross_container' onClick={() => { this.props.onClosePressed() }}>
                        <i className="fa-solid fa-xmark"></i>
                    </span>
                </div>
                <div className="divider" />
                <div className="update_user_informations_body">
                    <div className="update_user_informations_left">
                        <img src={this.props.userInformation.ProfilImage ?? `https://avatars.dicebear.com/api/personas/male/${Math.floor(Math.random() * 4777777770)}.png`} alt='' />
                        <div className="divider" />
                        <div>
                            <FilePicker
                                extensions={extensions}
                                isMultiple={false}
                                isEnabled={true}
                                max={1}
                                onChange={(files) => {
                                    if (files.length) {
                                        const uploadOptions = {
                                            userUUID: this.props.userInformation.UID,
                                            field: 'ProfileImages',
                                        }
                                        this.setState({ errorMessage: '', successMessage: '' });
                                        deleteFilesS3(this.props.userInformation.ProfilImage).then((deleted) => {
                                            uploadFilesS3(files[0], { userUUID: uploadOptions.userUUID, field: uploadOptions.field }).then((url) => {
                                                if (!isEmpty(url)) {
                                                    this.onUpdatingUserInformations({ ProfilImage: url });
                                                } else {
                                                    this.onUpdatingUserInformations({ ProfilImage: `https://avatars.dicebear.com/api/personas/male/${Math.floor(Math.random() * 4777777770)}.png`, });
                                                }
                                            });
                                        })
                                    }
                                }}
                                onLoading={(isAsyncl) => {
                                    this.setState({ isAsyncCall: true });
                                }}
                                builder={(inputId) => {
                                    return <label className="changeProfilePhotoUpdateLabel" htmlFor={inputId}>{{
                                        fr: "Changer votre photo de profil",
                                        en: "Change your profile picture",
                                        sw: "Badilisha picha ya wasifu"
                                    }[`${this.props.currentLanguage}`]}</label>
                                }}
                            />
                        </div>
                    </div>
                    <fieldset disabled={this.state.isAsyncCall} className="update_user_informations_right">
                        {!isEmpty(this.state.errorMessage) && <>
                            <Notifier type='error' message={this.state.errorMessage} />
                            <div className="divider" />
                        </>}
                        {!isEmpty(this.state.successMessage) && <>
                            <Notifier type='success' message={this.state.successMessage} />
                            <div className="divider" />
                        </>}
                        <form onChange={this.handleChange} onSubmit={(event) => {
                            event.preventDefault();
                            if (Object.keys(this.state.formValuesToSend).length) {
                                this.setState({ errorMessage: '', successMessage: '', infoMessage: '' });
                                joiSchema.validateAsync(this.state.formValues).then((formValues) => {
                                    if (typeof this.state.formValuesToSend.Email !== "undefined" && !isEmpty(this.state.formValuesToSend.Email) && this.state.formValuesToSend.Email !== this.props.userInformation.Email) {
                                        this.setState({ didUpdateEmail: !this.state.didUpdateEmail });
                                    } else {
                                        this.onUpdatingUserInformations(this.state.formValuesToSend);
                                    }
                                }).catch((error) => {
                                    this.setState({ errorMessage: `${error.details.map((e) => e.message)}` });
                                });
                            }
                        }}>
                            <input type="text" name="Username" placeholder={{
                                fr: "Nouveau nom d'utilisateur",
                                en: "New username",
                                sw: "Jina jipya la mtumiaji"
                            }[`${this.props.currentLanguage}`]} />
                            <select className="account_type" name="UserTypeId" value={Number(this.state.formValues.UserTypeId)} onChange={(event) => { }}>
                                {[...this.props.accountTypes].map((e) => {
                                    return <option key={e.id} value={e.id}>{e[capitalize(this.props?.currentLanguage)]}</option>
                                })}
                            </select>
                            <input type="text" name="Fullname" placeholder="Full name" value={this.state.formValues.Fullname} onChange={(event) => { }} />
                            <div className='row_inputs flex'>
                                <input type="email" name="Email" placeholder="Email" value={this.state.formValues.Email} onChange={(event) => { }} />
                            </div>
                            <div className='row_inputs flex'>
                                <input type="tel" name="PhoneNumber" placeholder="Phone number" value={this.state.formValues.PhoneNumber} onChange={(event) => { }} />
                                <input type="tel" name="WhatsappNumber" placeholder="Whatsapp number" value={this.state.formValues.WhatsappNumber} onChange={(event) => { }} />
                            </div>
                            <div className="divider" />
                            <button className={`${this.state.isAsyncCall && 'button_on_async'}`} type={'submit'}>{this.state.isAsyncCall ? waitingmsg : update}</button>
                        </form>
                    </fieldset>
                </div>
                <div className="divider" />
            </div>
        </div>
    }

    onUpdatingUserInformations(payload) {
        this.setState({ isAsyncCall: true, errorMessage: '', successMessage: '' });
        const restApiServices = new ApiProvider();
        restApiServices.provider({
            baseUrl: systemAuthBaseUrl,
            endpoint: 'api/alter-user-information',
            apiMethod: ApiMethods.patch,
            successCodeExpectation: 200,
            requestData: {
                token: {
                    accessToken: this.props?.userInformation?.accessToken,
                },
                body: payload,
            },
        }).then((response) => {
            if (typeof (response.error) === 'undefined') {
                this.setState({
                    isAsyncCall: false, formValuesToSend: {}, successMessage: {
                        'fr': 'Vos informations ont été mises à jour avec succès',
                        'en': 'Your information has been updated successfully',
                        'sw': 'Maelezo yako yamesasishwa kwa mafanikio',
                    }[this.props.currentLanguage]
                });
                const newUser = { ...response.response };
                this.props.dispatch(copyWith({
                    userInformation: { ...this.props.userInformation, ...newUser },
                }));
            } else {
                this.setState({ isAsyncCall: false, errorMessage: getErrorMessage(response.error, 'en') });
            }
        });
    }
}

const mapStateToProps = (storeOf) => ({
    currentLanguage: storeOf.madoshome.currentLanguage.code,
    userInformation: storeOf.madoshome.userInformation,
    accountTypes: storeOf.madoshome.accountTypes,
});

export default connect(mapStateToProps)(UpdateUserInfo);