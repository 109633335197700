import React from "react";
import Joi from "joi";
import { connect } from "react-redux";
import ApiProvider, { ApiMethods } from "../../../data/providers/api_services";
import { appName, systemTourismBaseUrl } from "../../../config/config";
import { getErrorMessage } from "../../../utils/utils";



class TourSitePricing extends React.Component {

    state = {
        isAsyncCall: false,
        isDisabled: true,
        formValues: {},
        formValuesToSend: {},
        errorMessage: '',
    }

    componentDidMount() {
        const { Local, LocalChild, Foreign, ForeignChild, Currency } = this.props.initialValue ?? {};
        this.setState({
            formValues: {
                Currency: Currency ?? 0,
                Local: Local,
                LocalChild: LocalChild,
                Foreign: Foreign,
                ForeignChild: ForeignChild,
            }
        })
    }

    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState(state => ({ ...state, formValues: { ...state.formValues, [name]: value }, formValuesToSend: { ...state.formValuesToSend, [name]: value } }));
    }

    joiSchema = Joi.object({
        Local: Joi.number().required(),
        LocalChild: Joi.number().required(),
        Foreign: Joi.number().required(),
        ForeignChild: Joi.number().required(),
        Currency: Joi.number().required(),
    });
    render() {
        const isDisabled = this.state.isDisabled;
        const { Local, LocalChild, Foreign, ForeignChild, Currency } = this.state.formValues;
        const priceDetails = this.props.initialValue ?? {};
        return <div className="tour_site_pricing">
            <div className='tour_site_pricing_header'>
                <h2>{{
                    fr: 'Tarification',
                    en: 'Pricing',
                    sw: 'Bei'
                }[this.props.currentLanguage]}</h2>
                {isDisabled && <i className='fa fa-lock' onClick={(event) => {
                    event.stopPropagation();
                    this.setState({ isDisabled: !isDisabled });

                }} />}
                {!isDisabled && <i id='spinner_loader' className='fa fa-unlock' onClick={(event) => {
                    event.stopPropagation();
                    if (Object.keys(this.state.formValuesToSend).length) {
                        this.setState({ errorMessage: '', successMessage: '', infoMessage: '' });
                        this.joiSchema.validateAsync(this.state.formValues).then((formValues) => {
                            this.setState({ isDisabled: !isDisabled });
                            if (Object.keys(priceDetails).length > 0) {
                                this.onUpdate(this.props.initialValue.id, this.state.formValuesToSend);
                            } else {
                                this.onSave(this.state.formValues);
                            }
                        }).catch((error) => {
                            console.log(error)
                            this.setState({ errorMessage: `${error.details.map((e) => e.message)}` });
                        });
                    } else {
                        this.setState({ isDisabled: !isDisabled });
                    }
                }} />}
                {this.state.isAsyncCall && <i id='spinner_loader' className='fa-solid fa-spinner fa-spin' />}
            </div>
            <div className="divider" />
            <fieldset disabled={this.state.isAsyncCall || isDisabled}>
                <form className="" onChange={this.handleChange}>
                    <div>
                        <div className="update_input">
                            <h5>{{
                                fr: 'Devise',
                                en: 'Currency',
                                sw: 'Fadha'
                            }[this.props.currentLanguage]}</h5>
                            <select onChange={(event) => { }} className="account_type" name="Currency" value={Currency} >
                                <option value={0} disabled={true}>{{
                                    fr: 'Devise',
                                    en: 'Currency',
                                    sw: 'Fadha'
                                }[this.props.currentLanguage]}</option>
                                {this.props.currencies.map((e, i) => {
                                    return <option key={i} value={e.id}>{e.Name}</option>
                                })}
                            </select>
                        </div>
                        <div className="flex forget_pass_kn">
                            <div className="update_input">
                                <h5>{{
                                    fr: "Tarif adultes nationaux",
                                    en: "National adult price",
                                    sw: "Bei kwa watu wazima wa kimataifa"
                                }[this.props.currentLanguage]}</h5>
                                <input type="number" name="Local" placeholder={{
                                    fr: "Adultes",
                                    en: "Adults",
                                    sw: "Watu wazima"
                                }[this.props.currentLanguage]} value={Local} />
                            </div>
                            <div className="update_input">
                                <h5>{{
                                    fr: "Tarif enfants nationaux",
                                    en: "National child price",
                                    sw: "Bei kwa watoto wa kimataifa"
                                }[this.props.currentLanguage]}</h5>
                                <input type="number" name="LocalChild" placeholder={{
                                    fr: "Enfants",
                                    en: "Children",
                                    sw: "Watoto"
                                }[this.props.currentLanguage]} value={LocalChild} />
                            </div>
                        </div>
                        <div className="flex forget_pass_kn">
                            <div className="update_input">
                                <h5>{{
                                    fr: "Tarif adulte étranger",
                                    en: "Foreign adult price",
                                    sw: "Bei kwa watu wazima wa kigeni"
                                }[this.props.currentLanguage]}</h5>
                                <input type="number" name="Foreign" placeholder={{
                                    fr: "Adultes",
                                    en: "Adults",
                                    sw: "Watu wazima"
                                }[this.props.currentLanguage]} value={Foreign} />
                            </div>
                            <div className="update_input">
                                <h5>{{
                                    fr: "Tarif enfant étranger",
                                    en: "Foreign child price",
                                    sw: "Bei kwa watoto wa kigeni"
                                }[this.props.currentLanguage]}</h5>
                                <input type="number" name="ForeignChild" placeholder={{
                                    fr: "Enfants",
                                    en: "Children",
                                    sw: "Watoto"
                                }[this.props.currentLanguage]} value={ForeignChild} />
                            </div>
                        </div>
                    </div>
                </form>
            </fieldset>
        </div>
    }

    onSave(values) {
        this.setState({ isAsyncCall: true });
        const restApiServices = new ApiProvider();
        restApiServices.provider({
            baseUrl: systemTourismBaseUrl,
            endpoint: 'api/tourism/add-price-detail',
            apiMethod: ApiMethods.post,
            successCodeExpectation: 200,
            requestData: {
                token: {
                    accessToken: localStorage.getItem(`${appName}act`) ?? ''
                },
                body: {
                    ...values,
                    TourismId: this.props.tourId,
                },
            },
        }).then((response) => {
            if (typeof (response.error) === "undefined") {
                this.setState({ isAsyncCall: false, isDisabled: true, });
            } else {
                this.setState({ isAsyncCall: false, isDisabled: false, errorMessage: getErrorMessage(response.error, 'en') });
            }
        });
    };

    onUpdate(id, values) {
        this.setState({ isAsyncCall: true });
        const restApiServices = new ApiProvider();
        restApiServices.provider({
            baseUrl: systemTourismBaseUrl,
            endpoint: `api/tourism/update-price-detail/${id}/${this.props.tourId}`,
            apiMethod: ApiMethods.patch,
            successCodeExpectation: 200,
            requestData: {
                token: {
                    accessToken: localStorage.getItem(`${appName}act`) ?? ''
                },
                body: values,
            },
        }).then((response) => {
            if (typeof (response.error) === "undefined") {
                this.setState({ isAsyncCall: false, isDisabled: true, });
            } else {
                this.setState({ isAsyncCall: false, isDisabled: false, errorMessage: getErrorMessage(response.error, 'en') });
            }
        });
    };
}

const mapStateToProps = (storeOf) => ({
    currentLanguage: storeOf.madoshome.currentLanguage.code,
    userInformation: storeOf.madoshome.userInformation,
    currencies: storeOf.madoshome.currencies,
});

export default connect(mapStateToProps)(TourSitePricing);