import React, { createRef } from "react";
import FileUploaderInput from "../../components/image_input/file_uploader_input";
import LocationPickerInput from "../../components/location_picker/location_picker";
import Joi from "joi";
import Notifier from "../../components/notifier/notifier";
import { capitalize, getErrorMessage, isEmpty } from "../../../utils/utils";
import { connect } from "react-redux";
import ApiProvider, { ApiMethods } from "../../../data/providers/api_services";
import { systemTourismBaseUrl, tourismImagesS3FolderNAme } from "../../../config/config";
import { appName } from "../../../config/config";
import { copyWith } from "../../../logic/slice";



class PublishTourismForm extends React.Component {

    state = {
        isAsyncCall: false,
        isAsyncFileUploadingCall: false,
        formValues: {},
        errorMessage: '',
        successMessage: '',
        isFileUploadTime: false,
        imageUploadSuccess: false,
        videoUploadSuccess: false,
        tourismTypes: [],
        noSubscription: false,
        isResetTime: false,
        pickedImages: 0,
    }

    joiSchema = Joi.object({
        Name: Joi.string().required(),
        TourismTypeId: Joi.number().min(0).required(),
        Latitude: Joi.number().not(0.0).required(),
        Longitude: Joi.number().not(0.0).required(),
        Address: Joi.string().required(),
        SiteWeb: Joi.string().uri(),
        BuiltIn: Joi.number().min(0),
        Images: Joi.array().items(Joi.object({
            Url: Joi.string().uri(),
        })),
        Video: Joi.string().uri(),
        Description: Joi.string().required(),
        History: Joi.string(),
    });



    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState(state => ({ ...state, formValues: { ...state.formValues, [name]: value } }));
    }


    async handleSubmit({ imageUploadSuccess = this.state.imageUploadSuccess, videoUploadSuccess = this.state.videoUploadSuccess }) {
        await this.setState({ imageUploadSuccess: imageUploadSuccess, videoUploadSuccess: videoUploadSuccess });
        if (this.state.videoUploadSuccess && this.state.imageUploadSuccess) {
            this.onPublishTourism(this.state.formValues);
        }
    }

    formRef = createRef();


    componentDidMount() {
        if (!this.props.tourismTypes.length || !this.props.days.length) {
            this.utilsFetcher();
        }
    }

    render() {
        const waitingmsg = {
            'fr': "Patientez s'il vous plait...",
            'en': "Please wait...",
            'sw': "Tafadhali subiri..."
        }[`${this.props.currentLanguage}`]

        const publish = {
            'fr': "Publier ",
            'en': "Publish ",
            'sw': "Kuchapisha"
        }[`${this.props.currentLanguage}`]
        const startYear = new Date().getFullYear() - new Date().getFullYear();
        return <fieldset className="form_for_publish" disabled={this.state.isAsyncCall}>
            {!isEmpty(this.state.errorMessage) && <>
                <Notifier type='error' message={this.state.errorMessage} />
                <div className="divider" />
            </>}
            {!isEmpty(this.state.successMessage) && <>
                <Notifier type='success' message={this.state.successMessage} />
                <div className="divider" />
            </>}
            <form ref={this.formRef} onChange={this.handleChange} onSubmit={(event) => {
                event.preventDefault();
                console.log(this.state.formValues);
                this.setState({ errorMessage: '', successMessage: '' });
                this.joiSchema.validateAsync(this.state.formValues).then((formValues) => {
                    if (this.state.pickedImages >= 4) {
                        if (this.state.imageUploadSuccess && this.state.videoUploadSuccess) {
                            this.handleSubmit(true, true);
                        } else {
                            this.setState({ isFileUploadTime: true, isAsyncCall: true });
                        }
                    } else {
                        console.log(this.state.pickedImages);
                        this.setState({ errorMessage: "At least 4 photo should be provided." });
                    }
                }).catch((error) => {
                    this.setState({ errorMessage: `${error.details.map((e) => e.message)}` });
                });

            }}>
                <input type="text" name="Name" placeholder={{
                    fr: "Dénomination",
                    en: "Denomination",
                    sw: "Dhehebu"
                }[`${this.props.currentLanguage}`]} />

                <select required={true} className="account_type" name="TourismTypeId" defaultValue={0}>
                    <option value={0} disabled={true}>{{
                        fr: "Type",
                        en: "Type ",
                        sw: "Aina"
                    }[`${this.props.currentLanguage}`]}</option>
                    {this.props.tourismTypes.map((e, i) => {
                        return <option key={i} value={e.id}>{e[capitalize(this.props.currentLanguage)]}</option>
                    })}
                </select>

                <LocationPickerInput onChange={(lat, lng) => {
                    this.setState(state => ({ ...state, formValues: { ...state.formValues, Latitude: lat, Longitude: lng } }));
                }} />

                <div className='row_inputs flex'>
                    <input type="text" name="Address" placeholder=
                        {{
                            fr: "Adresse complète",
                            en: "Full address",
                            sw: "Anwani kamili"
                        }[`${this.props.currentLanguage}`]} />
                    <select required={true} className="account_type" name="BuiltIn" defaultValue={0}>
                        <option value={0} disabled={true}>{{
                            fr: "Construit en",
                            en: "Built in",
                            sw: "Ime jengwa mwaka"
                        }[`${this.props.currentLanguage}`]}</option>
                        {Array.from({ length: new Date().getFullYear() - startYear }, (_, i) => {
                            return new Date().getFullYear() - i;
                        }).map((e, i) => {
                            return <option key={i} value={Number(e)}>{e}</option>
                        })}
                    </select>
                </div>

                <textarea name='History' placeholder={{
                    fr: "Historique du site (Facultatif)",
                    en: "History about the site (Optional)",
                    sw: "Historia kuhusu utali (Si lazima)"
                }[`${this.props.currentLanguage}`]} />

                <FileUploaderInput

                    hintText={{
                        fr: 'Photos (15 photos maximum)',
                        en: 'Photos (15 photos max)',
                        sw: 'Picha (picha zisizozidi 15)'
                    }[`${this.props.currentLanguage}`]}
                    multiple={true}
                    extensions={['jpeg', 'jpg', 'png']}
                    max={15}
                    onChange={(numberOfPickedFiles) => {
                        this.setState({ pickedImages: numberOfPickedFiles, isFileUploadTime: false });
                    }}
                    uploadOptions={{
                        userUUID: this.props.userInformation.UID,
                        field: tourismImagesS3FolderNAme,
                        itsUploadTime: this.state.isFileUploadTime,
                        onUploadSuccess: (urls) => {
                            this.setState(state => ({ ...state, formValues: { ...state.formValues, Images: [...urls].filter((e) => !isEmpty(e)).map((e) => { return { Url: e } }) } }));
                            this.handleSubmit({ imageUploadSuccess: true });
                        }
                    }}
                />
                <FileUploaderInput
                    hintText={{
                        fr: 'Vidéo (taille de fichier maximale de 100 Mo)',
                        en: 'Video (maximum file size 100MB)',
                        sw: 'Video (kiwango cha juu zaidi cha faili 100MB)'
                    }[`${this.props.currentLanguage}`]}
                    multiple={false}
                    extensions={['webm', 'mp4']}
                    max={1}
                    onChange={(files) => { }}
                    uploadOptions={{
                        userUUID: this.props.userInformation.UID,
                        field: tourismImagesS3FolderNAme,
                        itsUploadTime: this.state.isFileUploadTime,
                        onUploadSuccess: (urls) => {
                            this.setState(state => ({ ...state, formValues: { ...state.formValues, Video: [...urls][0] } }));
                            this.handleSubmit({ videoUploadSuccess: true });
                        }
                    }}
                />
                <textarea name='Description' placeholder={{
                    fr: "Description",
                    en: "Description",
                    sw: "Maelezo"
                }[`${this.props.currentLanguage}`]} />


                {!isEmpty(this.state.errorMessage) && <>
                    <Notifier type='error' message={this.state.errorMessage} />
                    <div className="divider" />
                </>}
                {!isEmpty(this.state.successMessage) && <>
                    <Notifier type='success' message={this.state.successMessage} />
                    <div className="divider" />
                </>}
                <div className="divider" />
                <button className={`${this.state.isAsyncCall && 'button_on_async'}`} type={'submit'}>{this.state.isAsyncCall ? waitingmsg : publish}</button>
            </form>
        </fieldset>
    }

    onPublishTourism(house) {
        this.setState({ isAsyncCall: true });
        const restApiServices = new ApiProvider();
        return restApiServices.provider({
            baseUrl: systemTourismBaseUrl,
            endpoint: "api/tourism/insert",
            apiMethod: ApiMethods.post,
            successCodeExpectation: 200,
            requestData: {
                token: {
                    accessToken: localStorage.getItem(`${appName}act`) ?? ''
                },
                body: house,
            },
        }).then((response) => {
            if (typeof (response.error) === "undefined") {
                this.formRef.current.reset();
                this.setState({ isResetTime: true, isFileUploadTime: false, isAsyncCall: false, successMessage: {
                    en: "Has been added successfully",
                    fr: "A été ajoutée avec succès",
                    sw: "Iliongezwa kwa mafanikio",
                }[this.props.currentLanguage] });
            } else {
                this.setState({ isAsyncCall: false, errorMessage: getErrorMessage(response.error, 'en') });
            }
        });
    };

    utilsFetcher() {
        this.setState({ isAsyncCall: true });
        const restApiServices = new ApiProvider();
        return restApiServices.provider({
            baseUrl: systemTourismBaseUrl,
            endpoint: "api/utils",
            apiMethod: ApiMethods.get,
            successCodeExpectation: 200,
            requestData: {
                token: {
                    accessToken: localStorage.getItem(`${appName}act`) ?? ''
                }
            },
        }).then((response) => {
            if (typeof (response.error) === "undefined") {
                const { TourismType, Days } = response.response;
                this.props.dispatch(copyWith({ tourismTypes: TourismType ?? [], days: Days ?? [] }));
                this.setState({ isAsyncCall: false });
            } else {
                this.props.dispatch(copyWith({ tourismTypes: [], days: [] }));
                this.setState({ isAsyncCall: false });
            }
        });
    };
}

const mapStateToProps = (storeOf) => ({
    currentLanguage: storeOf.madoshome.currentLanguage.code,
    userInformation: storeOf.madoshome.userInformation,
    tourismTypes: storeOf.madoshome.tourismTypes,
    days: storeOf.madoshome.days,
});

export default connect(mapStateToProps)(PublishTourismForm);