import React, { Component } from 'react'
import { connect } from 'react-redux';
import Joi from "joi";
import { capitalize, getErrorMessage } from '../../../utils/utils';
import ApiProvider, { ApiMethods } from '../../../data/providers/api_services';
import { appName, systemTourismBaseUrl } from '../../../config/config';

class TourWorkingDays extends Component {

    state = {
        isAsyncCall: false,
        isDisabled: true,
        formValues: {},
        formValuesToSend: {},
        errorMessage: '',
    }

    componentDidMount() {
        const { Day, To, From } = this.props.initialValue ?? {};
        this.setState({
            formValues: {
                DayId: Day?.id ?? 0,
                From: From ?? 0,
                To: To ?? 0,
            }
        })
    }

    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState(state => ({ ...state, formValues: { ...state.formValues, [name]: value }, formValuesToSend: { ...state.formValuesToSend, [name]: value } }));
    }

    joiSchema = Joi.object({
        DayId: Joi.number().required(),
        From: Joi.string().required(),
        To: Joi.string().required(),
    });
    render() {
        const isDisabled = this.state.isDisabled;
        return (
            <div className='rental_form'>
                <form className='horizontal_form flex' onChange={this.handleChange}>
                    <fieldset disabled={this.state.isDisabled}>
                        <div className="update_input">
                            <h5>{{
                                fr: 'Jour',
                                en: 'Day',
                                sw: 'Siku'
                            }[this.props.currentLanguage]}</h5>
                            <select onChange={(event) => { }} className="account_type" name="DayId" value={this.state.formValues.DayId} >
                                <option value={0} disabled={true}>{{
                                    fr: 'Jour',
                                    en: 'Day',
                                    sw: 'Siku'
                                }[this.props.currentLanguage]}</option>
                                {this.props?.days?.map((e, i) => {
                                    return <option key={i} value={e.id}>{e[capitalize(this.props.currentLanguage)]}</option>
                                })}
                            </select>
                        </div>
                        <div className="update_input">
                            <h5>{{
                                fr: 'De',
                                en: 'From',
                                sw: 'Kutoka'
                            }[this.props.currentLanguage]}</h5>
                            <input name="From" type='time' placeholder='From' onChange={(event) => { }} value={this.state.formValues.From} />
                        </div>
                        <div className="update_input">
                            <h5>{{
                                fr: 'A',
                                en: 'To',
                                sw: 'Hadi'
                            }[this.props.currentLanguage]}</h5>
                            <input name="To" type='time' placeholder='To' onChange={(event) => { }} value={this.state.formValues.To} />
                        </div>
                    </fieldset>
                    <div className='update-flex-actions'>
                        <h5 style={{ opacity: 0 }}>faLock</h5>
                        {isDisabled && <i className='fa fa-lock' onClick={(event) => {
                            event.stopPropagation();
                            this.setState({ isDisabled: !isDisabled });
                        }} />}
                        {!isDisabled && <i id='spinner_loader' className='fa fa-unlock' onClick={(event) => {
                            event.stopPropagation();
                            if (Object.keys(this.state.formValuesToSend).length) {
                                this.joiSchema.validateAsync(this.state.formValues).then((formValues) => {
                                    if (this.props.isUpdate) {
                                        this.onUpdate(this.props.initialValue.id, this.state.formValuesToSend);
                                    } else {
                                        this.onSave(this.state.formValues)
                                    }
                                    this.onSetForSale(formValues);
                                }).catch((error) => {
                                    this.setState({ errorMessage: `${error?.details?.map((e) => e.message)}` });
                                });
                            } else {
                                this.setState({ isAsyncCall: false, isDisabled: true, });
                            }

                        }} />}
                    </div>
                    <div className='update-flex-actions'>
                        <h5 style={{ opacity: 0 }}>faLock</h5>
                        {this.props.isUpdate && <i className='fa fa-trash' onClick={(event) => {
                            event.stopPropagation();
                            this.onDelete(this.props.initialValue.id);
                        }} />}
                    </div>
                    {this.state.isAsyncCall && <i id='spinner_loader' className='fa-solid fa-spinner fa-spin' />}
                </form>
            </div>
        )
    }

    onSave(values) {
        this.setState({ isAsyncCall: true });
        const restApiServices = new ApiProvider();
        restApiServices.provider({
            baseUrl: systemTourismBaseUrl,
            endpoint: `api/tourism/add-calendar`,
            apiMethod: ApiMethods.post,
            successCodeExpectation: 200,
            requestData: {
                token: {
                    accessToken: localStorage.getItem(`${appName}act`) ?? ''
                },
                body: {
                    ...values,
                    TourismId: this.props.tourId,
                },
            },
        }).then((response) => {
            if (typeof (response.error) === "undefined") {
                this.setState({ isAsyncCall: false, isDisabled: true, });
                this.props.onInsert({ ...response.response, Day: this.props.days.filter((element) => element.id === response.response.DayId)[0] });
            } else {
                this.setState({ isAsyncCall: false, isDisabled: false, errorMessage: getErrorMessage(response.error, 'en') });
            }
        });
    };

    onUpdate(id, values) {
        this.setState({ isAsyncCall: true });
        const restApiServices = new ApiProvider();
        restApiServices.provider({
            baseUrl: systemTourismBaseUrl,
            endpoint: `api/tourism/update-calendar/${id}/${this.props.tourId}`,
            apiMethod: ApiMethods.patch,
            successCodeExpectation: 200,
            requestData: {
                token: {
                    accessToken: localStorage.getItem(`${appName}act`) ?? ''
                },
                body: values,
            },
        }).then((response) => {
            if (typeof (response.error) === "undefined") {
                this.setState({ isAsyncCall: false, isDisabled: true, });
                this.props.onUpdate({ ...this.props.initialValue, values });
            } else {
                this.setState({ isAsyncCall: false, isDisabled: false, errorMessage: getErrorMessage(response.error, 'en') });
            }
        });
    };

    onDelete(id) {
        this.setState({ isAsyncCall: true });
        const restApiServices = new ApiProvider();
        restApiServices.provider({
            baseUrl: systemTourismBaseUrl,
            endpoint: `api/tourism/remove-calendar/${id}/${this.props.tourId}`,
            apiMethod: ApiMethods.delete,
            successCodeExpectation: 200,
            requestData: {
                token: {
                    accessToken: localStorage.getItem(`${appName}act`) ?? ''
                },
            },
        }).then((response) => {
            if (typeof (response.error) === "undefined") {
                this.setState({ isAsyncCall: false, isDisabled: true, });
                this.props.onDelete(id);
            } else {
                this.setState({ isAsyncCall: false, isDisabled: false, errorMessage: getErrorMessage(response.error, 'en') });
            }
        });
    };
}

const mapStateToProps = (storeOf) => ({
    currentLanguage: storeOf.madoshome.currentLanguage.code,
    userInformation: storeOf.madoshome.userInformation,
    currencies: storeOf.madoshome.currencies,
});

export default connect(mapStateToProps)(TourWorkingDays);