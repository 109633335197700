import React from "react";
import './location_picker.css';
import EmbeddedMap from "../map/map";
import PopUpInput from "../pop_up_input/pop_up_input";
import { connect } from "react-redux";


class LocationPickerInput extends React.Component {
    state = {
        isMap: false,
        lat: 0.0,
        lng: 0.0,
    }

    componentDidMount() {
        if (this.props.isResetTime) {
            this.setState({ lat: 0.0, lng: 0.0, });
            this.props.onChange(0.0, 0.0);
        }
        if (this.props.intialValue) {
            const { lat, lng } = this.props.intialValue;
            this.setState({ lat: lat ?? 0.0, lng: lng ?? 0.0 });
        }
    }



    render() {
        const isDisabled = !this.state.lat > 0 && !this.state.lng > 0;
        const inputId = `${Math.random() * 28368762318}`;
        const isLocked = this.props.isDisabled;
        return <div className="file_uploader_input location_picker_input">
            <PopUpInput
                isDisplayed={this.state.isMap}
                body={<EmbeddedMap onChange={(lat, lng) => {
                    this.props.onChange(lat, lng);
                    this.setState({ lat: lat, lng: lng, isMap: false });
                }} />}
                actions={[
                    { label: 'Close', onClick: () => { this.setState({ isMap: !this.state.isMap }); } },
                ]} />
            {isDisabled ? <>
                <input type='text' id={inputId} style={{ display: 'none' }} onClick={(event) => {
                    event.stopPropagation();
                    this.setState({ isMap: !this.state.isMap });
                }} />
                <label className="input_like_div file_uploader_input_hint_style file_uplaoder_input_field_label" htmlFor={inputId}>{{
                    fr : "Piquer les coordonnées",
                    en : "Pick coordinates",
                    sw : "Chagua kuratibu"
                }[`${this.props.currentLanguage}`]}</label>
            </> : <></>}
            {!isDisabled ? <div className="multi_select_input multi_select_input_not_empty">
                <div className="flex multi_select_input_not_empty_title">
                    <p id="multi_select_input_title">{Number(this.state.lat).toFixed(4)}, {Number(this.state.lng).toFixed(4)}</p>
                    <i className="fa fa-close" onClick={(event) => {
                        event.stopPropagation(); event.preventDefault();
                        if (!isLocked) {
                            this.setState({ lat: 0.0, lng: 0.0, });
                            this.props.onChange(0.0, 0.0);
                        }

                    }} />
                </div>
            </div> : <></>}

        </div>
    }
}

const mapStateToProps = (storeOf) => ({
    currentLanguage : storeOf.madoshome.currentLanguage.code

})

export default connect(mapStateToProps)(LocationPickerInput)