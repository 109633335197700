import React, { Component } from 'react'
import { connect } from 'react-redux';
import Joi from "joi";
import { getErrorMessage } from '../../../utils/utils';
// import Notifier from '../notifier/notifier';
import ApiProvider, { ApiMethods } from '../../../data/providers/api_services';
import { appName, systemTourismBaseUrl } from '../../../config/config';

class TourServices extends Component {

    state = {
        isAsyncCall: false,
        isDisabled: true,
        formValues: {},
        formValuesToSend: {},
        errorMessage: '',
    }

    componentDidMount() {
        const { Name, Currency, Price } = this.props.initialValue ?? {};
        this.setState({
            formValues: {
                Name: Name ?? '',
                Price: Price ?? 0,
                Currency: Currency ?? 0,
            }
        })
    }

    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState(state => ({ ...state, formValues: { ...state.formValues, [name]: value }, formValuesToSend: { ...state.formValuesToSend, [name]: value } }));
    }

    joiSchema = Joi.object({
        Name: Joi.string().required(),
        Price: Joi.number().min(1).required(),
        Currency: Joi.number().required(),
    });
    render() {
        const isDisabled = this.state.isDisabled;
        return (
            <div className='rental_form'>
                {/* {!isEmpty(this.state.errorMessage) && <>
                    <Notifier type='error' message={this.state.errorMessage} />
                    <div className="divider" />
                </>} */}
                <form className='horizontal_form flex' onChange={this.handleChange}>
                    <fieldset disabled={this.state.isDisabled}>
                        <div className="update_input">
                            <h5>{{
                                fr: "Dénomination",
                                en: "Denomination",
                                sw: "Dhehebu"
                            }[this.props.currentLanguage]}</h5>
                            <input name="Name" type='text' placeholder='Name' onChange={(event) => { }} value={this.state.formValues.Name} />
                        </div>
                        <div className="update_input">
                            <h5>{{
                                fr: 'Prix',
                                en: 'Price',
                                sw: 'Bei'
                            }[this.props.currentLanguage]}</h5>
                            <input name="Price" type='number' placeholder='Price' onChange={(event) => { }} value={this.state.formValues.Price} />
                        </div>
                        <div className="update_input">
                            <h5>{{
                                fr: 'Devise',
                                en: 'Currency',
                                sw: 'Fadha'
                            }[this.props.currentLanguage]}</h5>
                            <select onChange={(event) => { }} className="account_type" name="Currency" value={this.state.formValues.Currency} >
                                <option value={0} disabled={true}>Currency</option>
                                {this.props.currencies.map((e, i) => {
                                    return <option key={i} value={e.id}>{e.Name}</option>
                                })}
                            </select>
                        </div>
                    </fieldset>
                    <div className='update-flex-actions'>
                        <h5 style={{ opacity: 0 }}>faLock</h5>
                        {isDisabled && <i className='fa fa-lock' onClick={(event) => {
                            event.stopPropagation();
                            this.setState({ isDisabled: !isDisabled });
                        }} />}
                        {!isDisabled && <i id='spinner_loader' className='fa fa-unlock' onClick={(event) => {
                            event.stopPropagation();
                            if (Object.keys(this.state.formValuesToSend).length) {
                                this.joiSchema.validateAsync(this.state.formValues).then((formValues) => {
                                    if (this.props.isUpdate) {
                                        this.onUpdate(this.props.initialValue.id, this.state.formValuesToSend);
                                    } else {
                                        this.onSave(this.state.formValues)
                                    }
                                }).catch((error) => {
                                    this.setState({ errorMessage: `${error?.details?.map((e) => e.message)}` });
                                });
                            } else {
                                this.setState({ isAsyncCall: false, isDisabled: true, });
                            }
                        }} />}
                    </div>
                    <div className='update-flex-actions'>
                        <h5 style={{ opacity: 0 }}>faLock</h5>
                        {this.props.isUpdate && <i className='fa fa-trash' onClick={(event) => {
                            event.stopPropagation();
                            this.onDelete(this.props.initialValue.id);
                        }} />}
                    </div>
                    {this.state.isAsyncCall && <i id='spinner_loader' className='fa-solid fa-spinner fa-spin' />}
                </form>
            </div>
        )
    }

    onSave(values) {
        this.setState({ isAsyncCall: true });
        const restApiServices = new ApiProvider();
        restApiServices.provider({
            baseUrl: systemTourismBaseUrl,
            endpoint: `api/tourism/add-service`,
            apiMethod: ApiMethods.post,
            successCodeExpectation: 200,
            requestData: {
                token: {
                    accessToken: localStorage.getItem(`${appName}act`) ?? ''
                },
                body: {
                    ...values,
                    TourismId: this.props.tourId,
                },
            },
        }).then((response) => {
            if (typeof (response.error) === "undefined") {
                this.setState({ isAsyncCall: false, isDisabled: true, });
                this.props.onInsert(response.response);
            } else {
                this.setState({ isAsyncCall: false, isDisabled: false, errorMessage: getErrorMessage(response.error, 'en') });
            }
        });
    };

    onUpdate(id, values) {
        this.setState({ isAsyncCall: true });
        const restApiServices = new ApiProvider();
        restApiServices.provider({
            baseUrl: systemTourismBaseUrl,
            endpoint: `api/tourism/update-service/${id}/${this.props.tourId}`,
            apiMethod: ApiMethods.patch,
            successCodeExpectation: 200,
            requestData: {
                token: {
                    accessToken: localStorage.getItem(`${appName}act`) ?? ''
                },
                body: values,
            },
        }).then((response) => {
            if (typeof (response.error) === "undefined") {
                this.setState({ isAsyncCall: false, isDisabled: true, });
                this.props.onUpdate({ ...this.props.initialValue, values });
            } else {
                this.setState({ isAsyncCall: false, isDisabled: false, errorMessage: getErrorMessage(response.error, 'en') });
            }
        });
    };

    onDelete(id) {
        this.setState({ isAsyncCall: true });
        const restApiServices = new ApiProvider();
        restApiServices.provider({
            baseUrl: systemTourismBaseUrl,
            endpoint: `api/tourism/remove-service/${id}/${this.props.tourId}`,
            apiMethod: ApiMethods.delete,
            successCodeExpectation: 200,
            requestData: {
                token: {
                    accessToken: localStorage.getItem(`${appName}act`) ?? ''
                },
            },
        }).then((response) => {
            if (typeof (response.error) === "undefined") {
                this.setState({ isAsyncCall: false, isDisabled: true, });
                this.props.onDelete(id);
            } else {
                this.setState({ isAsyncCall: false, isDisabled: false, errorMessage: getErrorMessage(response.error, 'en') });
            }
        });
    };
}

const mapStateToProps = (storeOf) => ({
    currentLanguage: storeOf.madoshome.currentLanguage.code,
    userInformation: storeOf.madoshome.userInformation,
    currencies: storeOf.madoshome.currencies,
});

export default connect(mapStateToProps)(TourServices);