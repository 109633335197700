import { Link } from "react-router-dom";
import emptyProfil from '../../../assets/images/empty_picture_password.png'
import { capitalize } from "../../../utils/utils";

export default function UserFind({ UserID, ProfilImg, Fullname, Email, id, onSelect = () => { } }) {


    // return <Link to={`/users/${UserID}`} state={{ id: id }}>
    return <Link to={`/users/${UserID}`} state={{ id: id }} onClick={() => onSelect()}>
        <div className="comments_displayer_comment_card_header flex">
            <img src={ProfilImg ?? emptyProfil} alt="profil" />
            <div style={{ width: '100%' }}>
                <h4>{capitalize(Fullname)}</h4>
                <div className="comments_displayer_comment_card_header_footer flex">
                    <p className="comments_displayer_comment_card_comment_date">{Email}</p>
                    <div />
                </div>
            </div>
        </div>
        {/* <hr /> */}
        <div className="divider"></div>
    </Link>
}