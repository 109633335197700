import React, { createRef } from 'react';
import Joi from "joi";
import { connect } from 'react-redux';
import { getErrorMessage, isEmpty } from '../../../utils/utils';
import Notifier from '../notifier/notifier';
import ApiProvider, { ApiMethods } from '../../../data/providers/api_services';
import { appName, systemHotelsBaseUrl } from '../../../config/config';
import FileUploaderInput from '../image_input/file_uploader_input';

class AddRoom extends React.Component {
    state = {
        isAsyncCall: false,
        isAsyncFileUploadingCall: false,
        formValues: {},
        errorMessage: '',
        successMessage: '',
        isFileUploadTime: false,
        imageUploadSuccess: false,
        noSubscription: false,
        isResetTime: false,
        pickedImages: 0,
    }

    handleChange = (event) => {
        const { name, value } = event.target;
        console.log(name, value)
        this.setState(state => ({ ...state, formValues: { ...state.formValues, [name]: value } }));
    }


    async handleSubmit({ imageUploadSuccess = this.state.imageUploadSuccess }) {
        await this.setState({ imageUploadSuccess: imageUploadSuccess });
        if (this.state.imageUploadSuccess) {
            this.onAdding(this.state.formValues);
        }
    }

    formRef = createRef();

    joiSchema = Joi.object({
        RoomTypeId: Joi.number().min(0).required(),
        Currency: Joi.number().min(0).required(),
        Price: Joi.number().min(0).required(),
        RoomImages: Joi.array().items(Joi.object({
            Url: Joi.string().uri(),
        })),
        Description: Joi.string(),
    });

    render() {
        const waitingmsg = {
            'fr': "Patientez s'il vous plait...",
            'en': "Please wait...",
            'sw': "Tafadhali subiri..."
        }[`${this.props.currentLanguage}`]

        const addRooms = {
            'fr': "Ajouter ",
            'en': "Add ",
            'sw': "Ongeza"
        }[`${this.props.currentLanguage}`]
        return (
            <div className="modal_rideau">
                <div id="report_house_container" className="dif_modal">
                    <div className="flex call_to_subcsription_header update_user_informations_header">
                        <h2>{{
                            fr: 'Ajouter une chambre',
                            en: 'Add room',
                            sw: 'Ongeza chumba'
                        }[this.props.currentLanguage]}</h2>
                        <span id='cross_container' onClick={() => { this.props.onClosePressed() }}>
                            <i className="fa-solid fa-xmark"></i>
                        </span>
                    </div>
                    <div className="divider" />
                    <div className="message_cotainer">
                        {!isEmpty(this.state.errorMessage) && <>
                            <Notifier type='error' message={this.state.errorMessage} />
                            <div className="divider" />
                        </>}
                        {!isEmpty(this.state.successMessage) && <>
                            <Notifier type='success' message={this.state.successMessage} />
                            <div className="divider" />
                        </>}
                    </div>
                    <div className="divider" />
                    <form ref={this.formRef} className='report_form' onChange={this.handleChange} onSubmit={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        console.log(this.state.formValues);
                        this.setState({ errorMessage: '', successMessage: '' });
                        this.joiSchema.validateAsync(this.state.formValues).then((formValues) => {
                            if (this.state.pickedImages >= 4) {
                                if (this.state.imageUploadSuccess) {
                                    this.handleSubmit(true, true);
                                } else {
                                    this.setState({ isFileUploadTime: true, isAsyncCall: true });
                                }
                            } else {
                                console.log(this.state.pickedImages);
                                this.setState({ errorMessage: "At least 4 photo should be provided." });
                            }
                        }).catch((error) => {
                            this.setState({ errorMessage: `${error.details.map((e) => e.message)}` });
                        });
                    }}>
                        <select className="account_type" name="RoomTypeId" defaultValue={0} >
                            <option value={0} disabled={true}>{{
                                fr: 'Type',
                                en: 'Type',
                                sw: 'Aina'
                            }[this.props.currentLanguage]}</option>
                            {this.props.types?.map((e, i) => {
                                return <option key={i} value={e.id}>{e.Label}</option>
                            })}
                        </select>
                        <div className='row_inputs flex'>
                            <input name="Price" type='number' placeholder={{
                                fr: 'Prix',
                                en: 'Price',
                                sw: 'Bei'
                            }[this.props.currentLanguage]} />
                            <select className="account_type" defaultValue={0} name="Currency">
                                <option value={0} disabled={true}>{{
                                    fr: 'Devise',
                                    en: 'Currency',
                                    sw: 'Fadha'
                                }[this.props.currentLanguage]}</option>
                                {this.props.currencies.map((e, i) => {
                                    return <option key={i} value={e.id}>{e.Name}</option>
                                })}
                            </select>
                        </div>
                        <FileUploaderInput
                            hintText={{
                                fr: '5 photos maximum',
                                en: '5 photos maximum',
                                sw: 'Picha 5 upeo'
                            }[this.props.currentLanguage]}
                            multiple={true}
                            extensions={['jpeg', 'jpg', 'png']}
                            max={5}
                            onChange={(numberOfPickedFiles) => {
                                this.setState({ pickedImages: numberOfPickedFiles, isFileUploadTime: false });
                            }}
                            uploadOptions={{
                                userUUID: this.props.userInformation.UID,
                                field: 'Uploaded-hotelRooms',
                                itsUploadTime: this.state.isFileUploadTime,
                                onUploadSuccess: (urls) => {
                                    this.setState(state => ({ ...state, formValues: { ...state.formValues, RoomImages: [...urls].filter((e) => !isEmpty(e)).map((e) => { return { Url: e } }) } }));
                                    this.handleSubmit({ imageUploadSuccess: true });
                                }
                            }}
                        />
                        <textarea name='Description' placeholder={{
                            fr: 'Description',
                            en: 'Description',
                            sw: 'Maelezo'
                        }[this.props.currentLanguage]} />
                        <div className='divider' />
                        <button className={`${this.state.isAsyncCall && 'button_on_async'}`} type={'submit'} >{this.state.isAsyncCall ? waitingmsg : addRooms}</button>
                    </form>

                </div>
            </div>
        );
    }
    onAdding(Body) {
        this.setState({ isAsyncCall: true })
        const restApiServices = new ApiProvider();
        restApiServices.provider({
            baseUrl: systemHotelsBaseUrl,
            endpoint: `api/hotel/add-room`,
            apiMethod: ApiMethods.post,
            successCodeExpectation: 200,
            requestData: {
                token: {
                    accessToken: localStorage.getItem(`${appName}act`) ?? ''
                },
                body: {
                    HotelId: this.props.hotelId,
                    ...Body,
                }
            }

        }).then((response) => {
            if (typeof (response.error) === 'undefined') {
                console.log(response);
                this.setState({
                    isAsyncCall: false, successMessage: {
                        en: "The hotel room has been added successfully",
                        fr: "La chambre d'hôtel a été ajoutée avec succès",
                        sw: "Chumba cha hoteli kimeongezwa",
                    }[this.props.currentLanguage]
                });
                this.formRef.current.reset();
                this.props.onAddRoom(response.response);
            } else {
                console.log(response.error);
                this.setState({ isAsyncCall: false, errorMessage: getErrorMessage(response.error, 'en') });
            }
        })
    }
}

const mapStateToProps = (storeOf) => ({
    currentLanguage: storeOf.madoshome.currentLanguage.code,
    userInformation: storeOf.madoshome.userInformation,
    currencies: storeOf.madoshome.currencies,
});

export default connect(mapStateToProps)(AddRoom);
