// import Joi from "joi";
import React, { createRef } from 'react';
import './search_displayer.css';
import Notifier from '../notifier/notifier';
import { connect } from 'react-redux';
import EntityDisplayer from '../entity_displayer/entity_displayer';
import ApiProvider, { ApiMethods } from "../../../data/providers/api_services";
import { appName, systemHotelsBaseUrl, systemHousesBaseUrl, systemTourismBaseUrl } from "../../../config/config";
import HouseCard from "../housecard/house_card";
import { isEmpty, amenities } from "../../../utils/utils";
import LocationPickerInput from '../location_picker/location_picker';
import Joi from "joi";
import CategoryDisplayer from '../category_displayer/category_displayer';
import HotelSearch from './hotel_search';
import PropertySearchForm from './house_search';
import HotelCard from '../hotelcard/hotel_card';
import TourSiteCard from '../tour_site_card/tour_site_card';
import emptyScreenImg from '../../../assets/svgs/search.svg'
import {EmptyScreen} from '../../components/entity_displayer/itemsNotFound.jsx'




class SearchDisplayer extends React.Component {

    state = {
        currentSelectedTab: 0,
        sresults: <div />,
        entities: [],
        formValues: {},
        coordinates: {},
        errorMessage: '',
        successMessage: '',
    }

    joiSchema = Joi.object({
        Latitude: Joi.number().not(0.0).required(),
        Longitude: Joi.number().not(0.0).required(),

    });


    onSubmit({ values = {}, baseUrl = '', endpoint = '', builder = (entity) => { return <div /> } }) {
        this.setState({ entities: [] });
        this.joiSchema.validateAsync(this.state.coordinates).then((coordinates) => {
            const { Latitude, Longitude } = coordinates;
            this.setState({
                sresults: <EntityDisplayer
                    key={Math.random() * 1546456}
                    entities={[]}
                    builder={(entity) => { return builder(entity) }}
                    mapCoordinates={(entities) => { return [...entities].map((e) => { return { lat: e.Latitude, lng: e.Longitude } }) }}
                    onFetchingEntities={async () => {
                        const restApiServices = new ApiProvider();
                        return await restApiServices.provider({
                            baseUrl: baseUrl,
                            endpoint: `api/${endpoint}?offset=${this.state.entities.length ? this.state.entities[this.state.entities.length - 1].id : 0}`,
                            apiMethod: ApiMethods.post,
                            successCodeExpectation: 200,
                            requestData: {
                                token: { accessToken: localStorage.getItem(`${appName}act`) ?? '' },
                                headers: {
                                    latitude: Latitude,
                                    longitude: Longitude,
                                    region: this.props.userLocation?.regionName,
                                },
                                body: values,
                            },
                        }).then((response) => {
                            if (typeof (response.error) === "undefined") {
                                return response.response;
                            } else {
                                return [];
                            }
                        });
                    }}
                    onEntitiesFetched={(entities) => {
                        this.setState({ entities: entities });
                    }} />
            })
        }).catch((error) => {
            this.setState({ errorMessage: `${error.details.map((e) => e.message)}` });
        });

    }


    widgetRetuner(index, offset) {
        return [
            {
                options: {
                    baseurl: systemHousesBaseUrl,
                    endpoint: `building/search`,
                    builder: (entity) => { return <HouseCard key={entity.id} house={entity} isUpdateTime={false} /> },
                },
                form: <PropertySearchForm onAmenities={(selectedIndex) => {
                    var amenitiesForDb = {};
                    amenities.forEach((amenity, index) => {
                        amenitiesForDb = { ...amenitiesForDb, [amenity.key]: [...selectedIndex].filter((e) => e === index).length > 0 };
                    });
                    this.setState(state => ({ ...state, formValues: { ...state.formValues, ...amenitiesForDb } }));
                }} />,
            },
            {
                options: {
                    baseurl: systemHotelsBaseUrl,
                    endpoint: 'hotel/search',
                    builder: (entity) => { return <HotelCard key={entity.id} hotel={entity} isUpdateTime={false} /> },
                },
                form: <HotelSearch />,
            },
            {
                options: {
                    baseurl: systemTourismBaseUrl,
                    endpoint: 'tourism/search',
                    builder: (entity) => { return <TourSiteCard key={entity.id} tourSite={entity} isUpdateTime={false} /> },
                },
                form: <input type={"text"} name={"Name"} placeholder="Name" />,
            }
        ][index];
    }

    handleChange = (event) => {
        const { name, value } = event.target;
        if (value === String("")) {
            var keys = Object.keys(this.state.formValues);
            var newFormValues = {};
            for (var i = 0; i < keys.length; i++) {
                if (keys[i] !== name) {
                    newFormValues = { ...newFormValues, [keys[i]]: this.state.formValues[keys[i]] }
                }
            }
            this.setState(state => ({ ...state, formValues: newFormValues }));
        } else {
            this.setState(state => ({ ...state, formValues: { ...state.formValues, [name]: value } }));
        }
    }

    moreFilter = createRef();
    formRef = createRef();

    render() {
        
        const watingmsg = {
           
                fr: "Patientez s'il vous plait...",
                en: "Please wait...",
                sw: "Tafadhali subiri..."
        }[`${this.props.currentLanguage}`]

        const confirm = {
            fr  : "Confirmer",
            en : "Confirm",
            sw : "Hakikisha"
        }[`${this.props.currentLanguage}`]
        const search = {
            fr : "Rechercher",
            en : "Search",
            sw : "Tafuta"
        }[`${this.props.currentLanguage}`]
        
        return <div className='main_search_displayer'>
            <div className='main_search_displayer_currentScreen'>
                <div className='main_search_displayer_currentScreen_left'>
                    <div className="flex main_search_displayer_header">
                        <CategoryDisplayer onChange={(i) => { this.setState({ currentSelectedTab: i, sresults: <div />, formValues: {} }) }} />
                        <form className='search_displayer_form_header' onSubmit={(event) => {
                            event.preventDefault();
                            this.setState({ errorMessage: '', successMessage: '', infoMessage: '' });
                            const tab = this.widgetRetuner(this.state.currentSelectedTab,);
                            this.onSubmit({ values: this.state.formValues, endpoint: tab.options.endpoint, baseUrl: tab.options.baseurl, builder: tab.options.builder })
                        }}>


                            <LocationPickerInput onChange={(lat, lng) => {
                                this.setState({ coordinates: { Latitude: lat, Longitude: lng } })
                            }} />
                            <div id='search_icons_dis'>
                                <i className='fa fa-filter' onClick={(event) => {
                                    event.stopPropagation();
                                    this.moreFilter.current.style.transform = 'scaleX(1)';
                                    this.moreFilter.current.style.maxWidth = '28rem';
                                }} />
                                <i id='spinner_loader' className='fa fa-search' onClick={(event) => {
                                    event.stopPropagation();
                                    this.setState({ errorMessage: '', successMessage: '', infoMessage: '' });
                                    const tab = this.widgetRetuner(this.state.currentSelectedTab);
                                    this.onSubmit({ values: this.state.formValues, endpoint: tab.options.endpoint, baseUrl: tab.options.baseurl, builder: tab.options.builder })
                                }} />
                            </div>
                            <div id='search_buttons_dis' className='flex'>
                                <h6 id="row_inputs_search_h6" onClick={(event) => {
                                    event.stopPropagation();
                                    this.moreFilter.current.style.transform = 'scaleX(1)';
                                    this.moreFilter.current.style.maxWidth = '28rem';
                                }}>{{
                                    fr: "Filtre",
                                    en: "Filter",
                                    sw: "Chuja"
                                }[`${this.props.currentLanguage}`]}</h6>
                                <button className={`${this.state.isAsyncCall && 'button_on_async'}`} type={'submit'}>{this.state.isAsyncCall ? watingmsg : search }</button>
                            </div>
                        </form>
                    </div>
                    {!isEmpty(this.state.errorMessage) && <>
                        <Notifier type='error' message={this.state.errorMessage} />
                        <div className="divider" />
                    </>}
                    <div className="divider" />
                    <div className='main_displayer_currentScreen'>
                        {this.state.sresults}
                        {/* {console.log(this.state.sresults.type === 'div')} */}
                        {this.state.sresults.type === 'div' && <EmptyScreen 
                            Message={
                                {
                                    fr: "Le résultat de votre recherche s'affichera ici !",
                                    en: "Your search result will be displayed here !",
                                    sw: "Matokeo yako ya utafutaji yataonyeshwa hapa !"
                                }
                            }
                            imgToShow= {emptyScreenImg}
                        />}
                    </div>
                </div>
                <div ref={this.moreFilter} className='dif_modal main_search_displayer_currentScreen_right'>
                    <div className="view_over_comments_header">
                        <h3>{{
                            fr: "Filtre",
                            en: "Filter",
                            sw: "Chuja"
                        }[`${this.props.currentLanguage}`]}</h3>
                        <span id='cross_container' onClick={(event) => {
                            event.stopPropagation();
                            this.moreFilter.current.style.transform = 'scaleX(0)';
                            this.moreFilter.current.style.maxWidth = '0';
                        }}>
                            <i className="fa-solid fa-xmark"></i>
                        </span>
                    </div>
                    <div className="divider" />
                    {!isEmpty(this.state.errorMessage) && <>
                        <Notifier type='error' message={this.state.errorMessage} />
                        <div className="divider" />
                    </>}
                    {!isEmpty(this.state.successMessage) && <>
                        <Notifier type='success' message={this.state.successMessage} />
                        <div className="divider" />
                    </>}
                    <form ref={this.formRef} onChange={this.handleChange} className="main_search_displayer_currentScreen_right_form" onSubmit={(event) => {
                        event.preventDefault();
                        this.moreFilter.current.style.transform = 'scaleX(0)';
                        this.moreFilter.current.style.maxWidth = '0rem';
                        this.setState({ errorMessage: '', successMessage: '', infoMessage: '' });
                        const tab = this.widgetRetuner(this.state.currentSelectedTab);
                        this.onSubmit({ values: this.state.formValues, endpoint: tab.options.endpoint, baseUrl: tab.options.baseurl, builder: tab.options.builder })
                    }}>

                        {this.widgetRetuner(this.state.currentSelectedTab).form}
                        <div className="divider" />
                        <button className={`${this.state.isAsyncCall && 'button_on_async'}`} type={'submit'}>{confirm}</button>
                        <div className="divider" />
                    </form>
                </div>
            </div>
        </div>
    }
}

const mapStateToProps = (storeOf) => ({
    currentLanguage: storeOf.madoshome.currentLanguage.code,
    userInformation: storeOf.madoshome.userInformation,
    userLocation: storeOf.madoshome.userLocation,
});

export default connect(mapStateToProps)(SearchDisplayer);









