
export default class AppTheme {

    getThemeCssStyle(theme) {
        return {
            "--primaryColor": theme.primaryColor,
            "--bgColor": theme.backgroundColor,
            "--cardColor": theme.cardColor,
            "--textColor": theme.textColor,
            "--textColorOnColoredCard": theme.textColorOnColoredCard,
            "--scrollBarBgColor": theme.scrollBarBgColor,
            "--subtitleColor": theme.subtitleColor,
            "--dividerColor": theme.dividerColor,
        }
    }

    light = {
        primaryColor: '#00BFA6',
        backgroundColor: 'rgb(243, 243, 243)',
        cardColor: 'white',
        textColor: 'black',
        textColorOnColoredCard: 'white',
        scrollBarBgColor: '#d7d7d7',
        subtitleColor: 'gray',
        dividerColor: "rgb(221, 221, 221)"
    };
    dark = {
        primaryColor: '#00BFA6',
        backgroundColor: '#242526',
        cardColor: '#3A3B3C',
        textColor: '#E4E6EB',
        textColorOnColoredCard: 'white',
        scrollBarBgColor: '#282828',
        subtitleColor: '#B0B3B8',
        dividerColor: "gray"
    };
}