import React, { createRef } from 'react';
import './report.css'
import { connect } from 'react-redux';
import { appName, systemReportBaseUrl } from '../../../config/config';
import ApiProvider, { ApiMethods } from '../../../data/providers/api_services';
import { getErrorMessage, isEmpty } from '../../../utils/utils';
import Notifier from '../../components/notifier/notifier';
import Joi from "joi";
// import illustrationOnSuccess from '../../../assets/svgs/successOnlogin.svg'



class Report extends React.Component {
    state = {
        message: {},
        errorMessage: '',
        successMessage: '',
        isAsyncCall: false,
        isDisplayReport: true,
    }
    handlechange = (event) => {
        event.stopPropagation();
        event.preventDefault();
        const { name, value } = event.target;
        this.setState(state => ({ ...state, message: { ...state.message, [name]: value } }))
    }
    formRef = createRef();

    render() {
        const waitingmsg = {
            'fr': "Patientez s'il vous plait...",
            'en': "Please wait...",
            'sw': "Tafadhali subiri..."
        }[`${this.props.currentLanguage}`]

        const send = {
            'fr': "Envoyer ",
            'en': "Send  ",
            'sw': "Tuma"
        }[`${this.props.currentLanguage}`]
        const joiSchema = Joi.object({ Message: Joi.string().required() });
        return <div className="modal_rideau">
        <div id="report_house_container" className="dif_modal">
            <div className="flex call_to_subcsription_header update_user_informations_header">
                <h2>{{
                    fr: "Signaler",
                    en: "Report",
                    sw: "Ripoti"
                }[`${this.props.currentLanguage}`]}</h2>
                <span id='cross_container' onClick={() => { this.props.onClosePressed() }}>
                    <i className="fa-solid fa-xmark"></i>
                </span>
            </div>
            <div className="divider" />

            <div className="message_cotainer">
                {!isEmpty(this.state.errorMessage) && <>
                    <Notifier type='error' message={this.state.errorMessage} />
                    <div className="divider" />
                </>}
                {!isEmpty(this.state.successMessage) && <>
                    <Notifier type='success' message={this.state.successMessage} />
                    <div className="divider" />
                </>}
                <p>
                    <strong>{appName}</strong>
                    {{
                        fr: ` devrait être sans danger pour tout le monde,
                    nous prenons donc au sérieux les abus et le harcèlement.
                    Nous voulons entendre parler de comportements nuisibles sur le site
                    qui enfreint nos conditions d'utilisation.
                    Soyez assuré que nous garderons vos informations d'identification privées.`,
                        en: ` should be safe for everyone,
                    so we take abuse and harassment seriously.
                    We want to hear about harmful behavior on the site
                    that violates our Terms of Service.
                    Rest assured, we'll keep your identifying informations private.`
                        , sw: ` inapaswa kuwa salama kwa kila mtu,
                    kwa hivyo tunachukulia unyanyasaji na unyanyasaji kwa uzito.
                    Tunataka kusikia kuhusu tabia hatari kwenye tovuti
                    ambayo inakiuka Sheria na Masharti yetu.
                    Uwe na uhakika, tutaweka taarifa zako za kukutambulisha kuwa za faragha.`
                    }[`${this.props.currentLanguage}`]}
                </p>
            </div>
            <div className="divider" />

            <form ref={this.formRef} className='report_form' onChange={this.handlechange} onSubmit={(event) => {
                event.preventDefault();
                event.stopPropagation();
                this.setState({ errorMessage: '' })
                joiSchema.validateAsync(this.state.message).then((message) => {
                    this.onSubmitreport(message.Message);
                }).catch((error) => {
                    this.setState({ errorMessage: `${error.details.map((e) => e.message)}` })
                })
            }}>
                <textarea name="Message" id="report" cols="30" rows="10" placeholder={{
                    fr: "Taper votre commentaire ici",
                    en: "Write your comment here",
                    sw: "Andika komenti yako apa"
                }[`${this.props.currentLanguage}`]}>

                </textarea>
                <div className='divider' />
                <button className={`${this.state.isAsyncCall && 'button_on_async'}`} type={'submit'}>{this.state.isAsyncCall ? waitingmsg : send}</button>
            </form>

        </div>
    </div>
        // return <>
        //     {/* {!isEmpty(this.state.successMessage) && <Notifier
        //         illustration = {illustrationOnSuccess}
        //         msg = {this.state.successMessage}
        //         onClose= {(e)=>{
        //             e.stopPropagation();
        //             this.props.onClosePressed();
        //         }}
        //     />} */}
        //     {/* {this.state.isDisplayReport &&
        //         <div className="modal_rideau">
        //             <div id="report_house_container" className="dif_modal">
        //                 <div className="flex call_to_subcsription_header update_user_informations_header">
        //                     <h2>{{
        //                         fr: "Signaler",
        //                         en: "Report",
        //                         sw: "Ripoti"
        //                     }[`${this.props.currentLanguage}`]}</h2>
        //                     <span id='cross_container' onClick={() => { this.props.onClosePressed() }}>
        //                         <i className="fa-solid fa-xmark"></i>
        //                     </span>
        //                 </div>
        //                 <div className="divider" />

        //                 <div className="message_cotainer">
        //                     {!isEmpty(this.state.errorMessage) && <>
        //                         <Notifier type='error' message={this.state.errorMessage} />
        //                         <div className="divider" />
        //                     </>}
        //                     {!isEmpty(this.state.successMessage) && <>
        //                         <Notifier type='success' message={this.state.successMessage} />
        //                         <div className="divider" />
        //                     </>}
        //                     <p>
        //                         <strong>{appName}</strong>
        //                         {{
        //                             fr: ` devrait être sans danger pour tout le monde,
        //                         nous prenons donc au sérieux les abus et le harcèlement.
        //                         Nous voulons entendre parler de comportements nuisibles sur le site
        //                         qui enfreint nos conditions d'utilisation.
        //                         Soyez assuré que nous garderons vos informations d'identification privées.`,
        //                             en: ` should be safe for everyone,
        //                         so we take abuse and harassment seriously.
        //                         We want to hear about harmful behavior on the site
        //                         that violates our Terms of Service.
        //                         Rest assured, we'll keep your identifying informations private.`
        //                             , sw: ` inapaswa kuwa salama kwa kila mtu,
        //                         kwa hivyo tunachukulia unyanyasaji na unyanyasaji kwa uzito.
        //                         Tunataka kusikia kuhusu tabia hatari kwenye tovuti
        //                         ambayo inakiuka Sheria na Masharti yetu.
        //                         Uwe na uhakika, tutaweka taarifa zako za kukutambulisha kuwa za faragha.`
        //                         }[`${this.props.currentLanguage}`]}
        //                     </p>
        //                 </div>
        //                 <div className="divider" />

        //                 <form ref={this.formRef} className='report_form' onChange={this.handlechange} onSubmit={(event) => {
        //                     event.preventDefault();
        //                     event.stopPropagation();
        //                     this.setState({ errorMessage: '' })
        //                     joiSchema.validateAsync(this.state.message).then((message) => {
        //                         this.onSubmitreport(message.Message);
        //                     }).catch((error) => {
        //                         this.setState({ errorMessage: `${error.details.map((e) => e.message)}` })
        //                     })
        //                 }}>
        //                     <textarea name="Message" id="report" cols="30" rows="10" placeholder={{
        //                         fr: "Taper votre commentaire ici",
        //                         en: "Write your comment here",
        //                         sw: "Andika komenti yako apa"
        //                     }[`${this.props.currentLanguage}`]}>

        //                     </textarea>
        //                     <div className='divider' />
        //                     <button className={`${this.state.isAsyncCall && 'button_on_async'}`} type={'submit'}>{this.state.isAsyncCall ? waitingmsg : send}</button>
        //                 </form>

        //             </div>
        //         </div>

        //     } */}
        // </>
    }
    onSubmitreport(message) {
        this.setState({ isAsyncCall: true })
        const { endpoint, entity, userInformation, moreValues } = this.props;
        const restApiServices = new ApiProvider();
        var additionalValues = {};
        additionalValues[`${entity.key}`] = entity.value;

        restApiServices.provider({
            baseUrl: systemReportBaseUrl,
            endpoint: `api/${endpoint}`,
            apiMethod: ApiMethods.post,
            successCodeExpectation: 201,
            requestData: {
                body: {
                    idUser: userInformation.id,
                    Message: message,
                    ...additionalValues,
                    ...moreValues ?? {},
                }
            }

        }).then((response) => {
            if (typeof (response.error) === 'undefined') {
                this.setState({ isAsyncCall: false, successMessage: 'Your Message has been sent successfully'});
                this.formRef.current.reset();
            } else {
                console.log(response.error);
                this.setState({ isAsyncCall: false, errorMessage: getErrorMessage(response.error, 'en') });
            }
        })
    }
}

const mapStateToProps = (storeOf) => ({
    currentLanguage: storeOf.madoshome.currentLanguage.code,
    userInformation: storeOf.madoshome.userInformation,
});

export default connect(mapStateToProps)(Report);
