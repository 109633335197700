import React from "react";
import './cards_displayer.css';
import EmbeddedMap from "../map/map";
import { Fragment, createRef } from "react";
import { connect } from "react-redux";



class CardsDisplayer extends React.Component {

    state = {
        items: [],
        cordinates: []
    }

    builder({ itemCount, itemBuilder }) {
        return Array.from({ length: itemCount }, (_, i) => {
            return itemBuilder(i);
        })
    }

    componentDidMount() {
        this.setState({ items: this.builder({ itemCount: this.props.itemCount, itemBuilder: this.props.itemBuilder }) })
    }

    componentDidUpdate(prevProps) {
        if (this.props.itemCount !== prevProps.itemCount && this.props.itemBuilder !== prevProps.itemBuilder) {
            this.setState({ items: this.builder({ itemCount: this.props.itemCount, itemBuilder: this.props.itemBuilder }) })
        }
    }

    displayerRef = createRef();


    render() {
        const grid = {
            fr: "Grille",
            en: "Grid",
            sw: "Gridi"
        }[`${this.props.currentLanguage}`]
        const map = {
            fr: "Carte",
            en: "Map",
            sw: "Ramani"
        }[`${this.props.currentLanguage}`]
        const isLoading = typeof this.props.mapCoordinates === 'undefined';

        return <>
            {!this.state.isMapView ? <div ref={this.displayerRef} className='cards_displayer_wrapper' style={isLoading ? { overflow: 'hidden' } : {}}>
                {this.state.items.map((e, index) => {
                    const isTheLastOne = (this.state.items.length - 1) === index;
                    return isTheLastOne ? <CardObserver displayerRef={this.displayerRef} onReachBottom={this.props.onReachBottom} key={index}>{e}</CardObserver> : <Fragment key={index}>{e}</Fragment>
                })}
            </div> : <div style={{ height: '100%', width: '100%' }}>
                <EmbeddedMap
                    markerCount={[...this.props.mapCoordinates].length}

                    coordinatesBuilder={(index) => {
                        return [...this.props.mapCoordinates][index];
                    }}
                    infoWindoW={<div></div>}
                />

            </div>}
            {/* {console.log(this.props.mapCoordinates)} */}
            {this.props.mapCoordinates !== undefined ?
                this.props.mapCoordinates.length ? <div
                    id="view_type_button_holder_div"
                    className='view_type_button_holder action_button flex'
                    onClick={(event) => {
                        event.stopPropagation();
                        this.setState({ isMapView: !this.state.isMapView })
                    }}>
                    <i className={`fa fa-${this.state.isMapView ? "th-large" : "map-location-dot"}`} />
                    <h6>{this.state.isMapView ? grid : map}</h6>
                </div>
                    : <></>
                : console.log("No data in map cordinante... waiting...")
            }
        </>
    }
}


export class CardObserver extends React.Component {
    observerRef = createRef();

    componentDidMount() {
        this.interserctionObserver = new IntersectionObserver((entries) => {
            if (entries[0]?.isIntersecting) {
                this.props.onReachBottom();
            }
        }, { root: this.props.displayerRef.current, rootMargin: '0px', threshold: 0.2 });
        this.interserctionObserver.observe(this.observerRef.current);
    }
    render() {
        return <div ref={this.observerRef}>{this.props.children}</div>
    }

    componentWillUnmount() {
        this.interserctionObserver.unobserve(this.observerRef.current);
    }
}

const mapStateToProps = (storeOf) => ({
    currentLanguage: storeOf.madoshome.currentLanguage.code,

});

export default connect(mapStateToProps)(CardsDisplayer)