
import React from 'react';
import CategoryDisplayer from '../category_displayer/category_displayer';
import EntityDisplayer from '../entity_displayer/entity_displayer';

import './displayer.css';



export default class Displayer extends React.Component {

    state = {
        currentSelectedTab: 0,
        cache: {},
    }

    render() {
        return <div className='main_displayer'>
            <CategoryDisplayer onChange={(i) => { this.setState({ currentSelectedTab: i }) }} />
            <div className='divider' />
            <div className='main_displayer_currentScreen'>
                {this.props.categories.map((e) => {
                    return <EntityDisplayer
                        key={e.key}
                        currentLanguage {...this.props.currentLanguage}
                        entities={this.state.cache[e.key] ?? []}
                        builder={e.builder}
                        mapCoordinates={(entities) => {
                            return [...entities].map((e) => { return e !== null && { lat: e.Latitude, lng: e.Longitude }})
                        }}
                        onFetchingEntities={e.onFetchingEntities}
                        onEntitiesFetched={(entities) => {
                            this.setState(state => ({ ...state, cache: { ...state.cache, [e.key]: entities } }));
                        }} />
                })[this.state.currentSelectedTab]}
            </div>
        </div>
    }
}