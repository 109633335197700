import axios from 'axios';
import React, { Component, createRef } from 'react';
import { ShimmerCategoryItem } from 'react-shimmer-effects';
import { systemAuthBaseUrl } from '../../../config/config';
import ApiProvider, { ApiMethods } from '../../../data/providers/api_services';
import { getErrorMessage, isEmpty } from '../../../utils/utils';
import UserFind from './userFound.jsx'
import './search_users.css';
import { CardObserver } from '../cards_displayer/cards_displayer';
import { connect } from 'react-redux';

class SearchUsers extends Component {
    state = {
        results: [],
        isAsyncCall: false,
        isAsyncMore: false,
        maxReached: false,
        errorMessage: '',
        isTyping: false,
        valueInputed: '',
    }

    maxUsersTobeShown = 12;

    timeOuter = setTimeout(() => { }, 0);

    handleSearchInputChange = (event) => {
        event.stopPropagation();
        const { value } = event.target;
        this.setState({ isTyping: true, valueInputed: value, results: [], maxReached: false });
        this.source?.cancel('');
        this.source = null;
        clearTimeout(this.timeOuter);
        if (!isEmpty(value)) {
            this.timeOuter = setTimeout(() => {
                this.source = axios.CancelToken.source();
                this.onSearch(value, 0, { isStart: true });
            }, 3000);
        } else {
            this.setState({ isTyping: false, isAsyncCall: false });
        }
    }

    searchMe(value) {
        if (!isEmpty(value)) {
            this.timeOuter = setTimeout(() => {
                this.source = axios.CancelToken.source();
                this.onSearch(value);
            }, 1000);
        }
    }

    displayRef = createRef()
    sUser = createRef()
    sUser2 = createRef()

    render() {
        return (
            <div className="modal_rideau search_users_rideau"  ref={this.sUser}>
                <div className='dif_modal search_users' ref={this.sUser2}>
                    <div className='search_users_body'>
                        <div className='search_users_body_header'>
                            <input type='search' placeholder={{
                                'fr': `Entrer le nom complet de l'utilisateur`,
                                'en': 'Enter an user full name...',
                                'sw': 'Tiya jina nzima ya mutumikishaji'
                            }[this.props.currentLanguage]} onChange={this.handleSearchInputChange} />
                            <span id='cross_container' onClick={() => { this.props.onClosePressed() }}>
                                <i className="fa-solid fa-xmark"></i>
                            </span>
                        </div>
                        <div className='search_users_body_bottom' ref={this.displayRef}>
                            {(this.state.isAsyncCall || this.state.isTyping) && <div className='search_users_body_loading'>
                                <ShimmerCategoryItem hasImage imageType="circular" imageWidth={50} imageHeight={50} text />
                            </div>}
                            {(this.state.results.length)
                                ? <div className='search_users_body_results'>
                                    {this.state.results.map((e, i) => {
                                        const isTheLastOne = (this.state.results.length - 1) === i;
                                        return isTheLastOne
                                            ? <CardObserver
                                                displayerRef={this.displayRef}
                                                onReachBottom={() => {
                                                    console.log('Reached the bottom')
                                                    if (!this.state.maxReached) {
                                                        this.loading();
                                                    }
                                                }}
                                                key={e.id}>
                                                <UserFind
                                                    UserID={e.UID}
                                                    id={e.id}
                                                    Fullname={e.Fullname}
                                                    ProfilImg={e.ProfilImage}
                                                    Email={e.Email}
                                                    key={e.id}
                                                    onSelect={()=>{
                                                        this.props.onClosePressed()
                                                        // this.sUser.current.style.justifyContent = 'flex-end';
                                                        // this.sUser2.current.style.width = '28rem';
                                                    }}
                                                />
                                            </CardObserver>
                                            : <UserFind
                                                UserID={e.UID}
                                                id={e.id}
                                                Fullname={e.Fullname}
                                                ProfilImg={e.ProfilImage}
                                                Email={e.Email}
                                                key={e.id}
                                                onSelect={()=>{
                                                    this.props.onClosePressed()
                                                    // this.sUser.current.style.justifyContent = 'flex-end';
                                                    // this.sUser2.current.style.width = '28rem';
                                                }}
                                            />
                                    })}
                                </div>
                                : <></>
                            }
                            <div className={`${this.state.isAsyncMore && 'search_users_body_loading'}`}>
                                {this.state.isAsyncMore ? <ShimmerCategoryItem hasImage imageType={'circular'} imageWidth={50} imageHeight={50} text /> : <div />}
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        )
    }

    source = null;
    max = 50;

    loading() {
        if (!this.state.isAsyncMore) {
            this.setState({ isAsyncMore: true, isAsyncCall: false });
            this.onSearch(
                this.state.valueInputed,
                this.state.results.length ? this.state.results[this.state.results.length - 1].id : 0,
                { isStart: false },

            ).then(async (results) => {
                this.setState({ isAsyncMore: false, isAsyncCall: false });
                if (results.length) {
                    if (this.state.results.length < this.max) {
                        const returnedResult = [...this.state.results, ...results];
                        await this.setState({ results: returnedResult });
                    } else {
                        await this.setState({ results: results });
                    }
                } else {
                    await this.setState({ maxReached: true });
                }
            })
        }
    }

    async onSearch(value, offset, { isStart = false }) {
        this.setState({ errorMessage: '', isTyping: false, isAsyncCall: true });
        const restApiServices = new ApiProvider();
        return restApiServices.provider({
            baseUrl: systemAuthBaseUrl,
            endpoint: `api/search?query=${value}&offset=${offset}`,
            apiMethod: ApiMethods.get,
            successCodeExpectation: 200,
            requestData: {
                cancelToken: this.source.token,
            },
        }).then((response) => {
            if (typeof (response.error) === "undefined") {
                if (isStart) {
                    this.setState({ results: response.response, isAsyncCall: false });
                }
                return response.response;
            } else {
                this.setState({ results: [], errorMessage: getErrorMessage(response.error), isAsyncCall: false });
                return [];
            }
        });
    }

}

const mapStateToProps = (storeOf) => ({
    userInformation: storeOf.madoshome.userInformation
})
export default connect(mapStateToProps)(SearchUsers);
