import React from 'react';
import './caroussel.css';



export default class CustomCarousel extends React.Component {

    state = {
        currentIndex: 0,
        scrollItems: [],
    }


    componentDidMount() {
        this.setState({ scrollItems: this.builder(this.props.images) });
    }

    handleArrow(lenghtOfListOfImages) {
        if (!lenghtOfListOfImages) {
            this.setState({
                currentIndex: this.state.currentIndex > 0 ? this.state.currentIndex - 1 : this.state.currentIndex,
            })
        } else {
            this.setState({
                currentIndex: this.state.currentIndex < lenghtOfListOfImages - 1 ? this.state.currentIndex + 1 : this.state.currentIndex,
            })
        }
    }

    builder(images, videoUrl) {
        const length = [...images].length;
        const comp = Array.from({ length: length }, (_, i) => {
            return <div className='image_generator' style={{ backgroundImage: `url(${images[i]})`, width: '100%', height: '100%' }} />
        });
        if (videoUrl) {
            comp.push(<VideoComponent url={videoUrl} />);
        }
        return comp;
    }

    render() {
        const itemToDisplay = this.state.scrollItems;
        return <div className='custom_carroussel_body'>
            <div className='custom_carroussel_body_displayer flex'>
                <img key={Math.random()*154165} alt='' src={this.props.images[this.state.currentIndex]} style={{ height: '100%', width: '100%', objectFit: 'cover' }} />
                {/* {itemToDisplay[this.state.currentIndex]} */}
            </div>
            <div className='custom_carroussel_scroller_sc custom_carroussel_scroller_sc_left' onClick={(event) => { event.preventDefault(); this.handleArrow() }}>
                <i className='fa fa-arrow-left' />
            </div>
            <div className='custom_carroussel_scroller_sc' onClick={() => { this.handleArrow(itemToDisplay.length) }}>
                <i className='fa fa-arrow-right' />
            </div>
            {/* <div className='custom_carroussel_indicators flex'>
                {itemToDisplay.map((e, i) => {
                    var isSelected = i === this.state.currentIndex;
                    return <div key={i} className={`custom_carroussel_indicators_ind ${isSelected && "custom_carroussel_indicators_ind_selected"}`} />
                })}
            </div> */}
        </div>
    }
}

function VideoComponent({ url = 'https://player.vimeo.com/external/434413028.sd.mp4?s=23fba8aa08add8f908ae991ac3c834dcf98b57d2&profile_id=165&oauth2_token_id=57447761' }) {
    return <div className='custom_carroussel_body_video'>
        <video loop={true} autoPlay={true} controls={true}>
            <source src={url} />
        </video>
    </div>
}