export const appName = "MadosHome";
// export const systemFavoriteBaseUrl = "http://192.168.43.114:8400/favorite";
export const systemFavoriteBaseUrl = "https://backend.madoshome.com/favorite"; 
// export const systemAuthBaseUrl = "http://192.168.43.114:8000/auth";
// export const systemAuthBaseUrl = "http://localhost:8000/auth";
export const systemAuthBaseUrl = "https://backend.madoshome.com/auth"; 
// export const systemHousesBaseUrl = "http://192.168.43.114:8100/house";
export const systemHousesBaseUrl = "https://backend.madoshome.com/house";
// export const systemHotelsBaseUrl = "http://192.168.43.114:8300/hotel";
// export const systemHotelsBaseUrl = "http://192.168.43.114:8300/hotel";
export const systemHotelsBaseUrl = "https://backend.madoshome.com/hotel";
// export const systemTourismBaseUrl = "http://192.168.43.114:8200/tourism";
export const systemTourismBaseUrl = "https://backend.madoshome.com/tourism";
export const systemReportBaseUrl = 'https://backend.madoshome.com/report';
export const housesImagesS3FolderNAme = 'published-houses'; 
export const hotelsImagesS3FolderNAme = 'published-hotels'; 
export const tourismImagesS3FolderNAme = 'published-tourism'; 
