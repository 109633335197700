import { appName } from "../../config/config";
import Internationalisation from "./internationalisation";

export default class LocalStorageServices {

    onThemeChanged(isDarkMode) {
        localStorage.setItem(`${appName}idm`, isDarkMode ? 1 : 0);
    }

    setRefreshToken(refreshToken) {
        localStorage.setItem(`${appName}rft`, refreshToken ?? '');
    }

    getRefreshToken() {
        return localStorage.getItem(`${appName}rft`) ?? '';
    }

    setAccessToken(accessToken) {
        localStorage.setItem(`${appName}act`, accessToken ?? '');
    }

    getAccessToken() {
        return localStorage.getItem(`${appName}act`) ?? '';
    }

    isDarkTheme() {
        if (Number(localStorage.getItem(`${appName}idm`)) === 1) {
            return true;
        } else {
            return false;
        }
    }

    onLanguageChanged(language) {
        localStorage.setItem(`${appName}slctdlngg`, new Internationalisation().typeToCode(language));
    }

    getCurrentLanguage() {
        return localStorage.getItem(`${appName}slctdlngg`) ?? 'fr';
    }
}