import React, { Component } from 'react';
import './rental_form.css';
import Joi from "joi";
import { capitalize, getErrorMessage, isEmpty } from '../../../utils/utils';
import Notifier from '../notifier/notifier';
import { connect } from 'react-redux';
import ApiProvider, { ApiMethods } from '../../../data/providers/api_services';
import { appName, systemHousesBaseUrl } from '../../../config/config';


class RentalForm extends Component {

    state = {
        isAsyncCall: false,
        isDisabled: true,
        formValues: {},
        formValuesToSend: {},
        errorMessage: '',
    }

    componentDidMount() {
        const { Price, Currency, Disponible, RentalType } = this.props.initialValue ?? {};
        this.setState({
            formValues: {
                Price: Price ?? 0,
                Currency: Currency ?? 0,
                Disponible: Disponible,
                RentalTypeId: RentalType?.id ?? 0,
            }
        })
    }

    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState(state => ({ ...state, formValues: { ...state.formValues, [name]: value }, formValuesToSend: { ...state.formValuesToSend, [name]: value } }));
    }

    joiSchema = Joi.object({
        Price: Joi.number().min(1).required(),
        Currency: Joi.number().required(),
        RentalTypeId: Joi.number().required(),
        Disponible: Joi.date().allow('',null),
    });

    render() {
        const isDisabled = this.state.isDisabled;
        const infos = this.props.initialValue ?? {};
        return (
            <div className='rental_form'>
                {!isEmpty(this.state.errorMessage) && <>
                    <Notifier type='error' message={this.state.errorMessage} />
                    <div className="divider" />
                </>}
                <form className='horizontal_form flex' onChange={this.handleChange}>
                    <fieldset disabled={this.state.isDisabled}>
                        <div className="update_input">
                            <h5>{{
                                fr : 'Prix de location',
                                en : 'Rental price',
                                sw : 'Bei ya kukodisha'
                            }[this.props.currentLanguage]}</h5>
                            <input name="Price" type='number' onChange={(event) => { }} value={this.state.formValues.Price} />
                        </div>
                        <div className="update_input">
                            <h5>{{
                                fr : 'Devise de la location',
                                en : 'Rental currency',
                                sw : 'Fedha ya kukodisha'
                            }[this.props.currentLanguage]}</h5>
                            <select onChange={(event) => { }} className="account_type" name="Currency" value={this.state.formValues.Currency}>
                                <option value={0} disabled={true}>Currency</option>
                                {this.props.currencies.map((e, i) => {
                                    return <option key={i} value={e.id}>{e.Name}</option>
                                })}
                            </select>
                        </div>
                        <div className="update_input">
                            <h5>{{
                                fr : 'Disponibilité de la location',
                                en : 'Rental availability',
                                sw : 'Upatikanaji wa kukodisha'
                            }[this.props.currentLanguage]}</h5>
                            <input name="Disponible" type='date' onChange={(event) => { }} value={this.state.formValues.Disponible} />
                        </div>
                        <div className="update_input">
                            <h5>{{
                                fr :'Durée de location',
                                en : 'Rental term',
                                sw : 'Muda wa kukodisha'
                            }[this.props.currentLanguage]}</h5>
                            
                            <select onChange={(event) => { }} className="account_type" name="RentalTypeId" value={this.state.formValues.RentalTypeId}>
                                <option value={0} disabled={true}>{{
                                    fr : 'Type',
                                    en : 'Type',
                                    sw : 'Aina'
                                }[this.props.currentLanguage]}</option>
                                {this.props.terms.map((e, i) => {
                                    return <option key={i} value={e.id}>{e[capitalize(this.props.currentLanguage)]}</option>
                                })}
                            </select>
                        </div>
                    </fieldset>
                    <div className='update-flex-actions'>
                        <h5 style={{ opacity: 0 }}>faLock</h5>
                        {isDisabled && <i className='fa fa-lock' onClick={(event) => {
                            event.stopPropagation();
                            this.setState({ isDisabled: !isDisabled });
                        }} />}
                        {!isDisabled && <i id='spinner_loader' className='fa fa-unlock' onClick={(event) => {
                            event.stopPropagation();
                            event.preventDefault();
                            this.setState({ errorMessage: '', successMessage: '', infoMessage: '' });
                            this.joiSchema.validateAsync(this.state.formValues).then((formValues) => {
                                if (Object.keys(infos).length > 0) {
                                    this.onUpdate(this.props.initialValue.id, this.state.formValuesToSend);
                                } else {
                                    this.onSave(this.state.formValues);
                                }
                            }).catch((error) => {
                                this.setState({ errorMessage: `${error?.details?.map((e) => e.message)}` });
                            });
                        }} />}

                    </div>
                    {this.state.isAsyncCall && <i id='spinner_loader' className='fa-solid fa-spinner fa-spin' />}
                </form>
            </div>
        )
    }

    onSave(values) {
        this.setState({ isAsyncCall: true });
        const restApiServices = new ApiProvider();
        restApiServices.provider({
            baseUrl: systemHousesBaseUrl,
            endpoint: 'api/building/add-rental-detail',
            apiMethod: ApiMethods.post,
            successCodeExpectation: 200,
            requestData: {
                token: {
                    accessToken: localStorage.getItem(`${appName}act`) ?? ''
                },
                body: {
                    ...values,
                    BuildingId: this.props.buildingId,
                },
            },
        }).then((response) => {
            if (typeof (response.error) === "undefined") {
                this.setState({ isAsyncCall: false, isDisabled: true, formValuesToSend: {}});
                this.props.onChange(response.response)
            } else {
                this.setState({ isAsyncCall: false, isDisabled: false, errorMessage: getErrorMessage(response.error, 'en') });
            }
        });
    };

    onUpdate(id, values) {
        this.setState({ isAsyncCall: true });
        const restApiServices = new ApiProvider();
        restApiServices.provider({
            baseUrl: systemHousesBaseUrl,
            endpoint: `api/building/alter-rental-detail/${id}`,
            apiMethod: ApiMethods.patch,
            successCodeExpectation: 200,
            requestData: {
                token: {
                    accessToken: localStorage.getItem(`${appName}act`) ?? '',
                },
                body: values,
            },
        }).then((response) => {
            if (typeof (response.error) === "undefined") {
                this.setState({ isAsyncCall: false, isDisabled: true, formValuesToSend: {}});
                this.props.onChange({...this.props.initialValue, ...values})
            } else {
                this.setState({ isAsyncCall: false, isDisabled: false, errorMessage: getErrorMessage(response.error, 'en') });
            }
        });
    };
}


const mapStateToProps = (storeOf) => ({
    currentLanguage: storeOf.madoshome.currentLanguage.code,
    userInformation: storeOf.madoshome.userInformation,
    currencies: storeOf.madoshome.currencies,
});

export default connect(mapStateToProps)(RentalForm);
