import React, { useEffect, useState } from 'react'
import { GoogleMap, useJsApiLoader, Marker, InfoWindow } from '@react-google-maps/api';
import { useSelector } from 'react-redux';

const containerStyle = {
  width: '100%',
  height: '100%'
};


const libraries = ["places"];

function EmbeddedMap({
  onChange = () => { },
  coordinatesBuilder = (index) => { },
  markerCount = 0,
  onLoaded = () => { },
  infoWindoW = <div>No details has been provided</div>
}) {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    libraries: libraries,
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY,
  })

  const { userLocation } = useSelector((storeOf) => ({
    userLocation: storeOf.madoshome.userLocation,
  }));

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds({ lat: userLocation?.lat, lng: userLocation?.lon });
    map.fitBounds(bounds);
    setMap(map)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  const [selectedPosition, setSelectedPosition] = useState(null);

  const [map, setMap] = React.useState(null);

  const [markers, setMarkers] = React.useState([]);

  const [hasSetMarkers, sethasSetMarkers] = React.useState(false);



  useEffect(() => {
    if (isLoaded) {
      console.log('Loaded');
      onLoaded();
    }
    if (!hasSetMarkers) {
      sethasSetMarkers(true)
      const arr = Array.from({ length: markerCount }, (_, i) => {
        const coordinates = coordinatesBuilder(i);
        return coordinates;
      });
      setMarkers(arr);
      console.log(arr);
    }


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [markerCount, isLoaded])

  const cent = {
    lat: parseFloat(markers[0]?.lat ?? userLocation?.lat),
    lng: parseFloat(markers[0]?.lng ?? userLocation?.lon),
  }

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={cent}
      zoom={8}
      onLoad={onLoad}
      onUnmount={onUnmount}
      onClick={(event) => {
        console.log(map ?? '');
        if (document.fullscreenElement) {
          document.exitFullscreen().then(() => console.log('Full Screen Closed')).catch((err) => console.log(err));
        }
        onChange(event.latLng.lat(), event.latLng.lng());
      }}
    >
      <>
        {markers.map((coordinates, i) => {
          return <Marker key={i}
            position={new window.google.maps.LatLng(parseFloat(coordinates.lat), parseFloat(coordinates.lng))}
            onClick={(event) => {
              setSelectedPosition({ lat: event.latLng.lat(), lng: event.latLng.lng() });
            }}
          />
        })}
        {
          selectedPosition ? <>
            <InfoWindow
              position={new window.google.maps.LatLng(parseFloat(selectedPosition.lat), parseFloat(selectedPosition.lng))}
              onCloseClick={() => {
                setSelectedPosition(null);
              }}>
              {infoWindoW}
            </InfoWindow>
          </> : <></>
        }
      </>
    </GoogleMap>
  ) : <></>
}

export default React.memo(EmbeddedMap)

