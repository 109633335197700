import { useState } from "react";

import { useSelector } from "react-redux";
import './category_displayer.css';

export default function CategoryDisplayer({ onChange = () => { } }) {
    const CurrentLanguage = useSelector(state => state.madoshome.currentLanguage.code)
    const tabs = [
        {name: {fr: "Immeuble",en: "Property", sw : "Nyumba"}, icon: 'house'},
        {name: {fr: "Hôtel", en: "Hotel", sw : "Hoteli"}, icon: 'hotel'},
        {name: {fr: "Site touristique", en: "tourist site",sw: "Eneo la utalii"}, icon: 'mountain-city'}
    ]
    const [index, changeIndex] = useState(0);


    return <div className='main_displayer_tabs flex'>
        {tabs.map((e, i) => {
            
            var isLast = i === tabs.length - 1;
            var isSelected = i === index;
            // console.log(e)
            return <div className={`main_displayer_tabs_card ${isSelected && 'main_displayer_tabs_card_selected'} ${isLast && 'main_displayer_tabs_card_last'}`} key={i} onClick={() => {
                changeIndex(i);
                onChange(i);
            }}>
                <i className={`fa fa-${e.icon}`} />
                <h3>{e.name[`${CurrentLanguage}`]}</h3>
            </div>
        })}
    </div>
}