import { useSelector } from "react-redux";



export function EmptyScreen({ Message, imgToShow }) {
    const currentLanguage = useSelector(state => state.madoshome.currentLanguage.code);
    return <div className="no_data_was_found expandeIMg">
        <img src={imgToShow} alt="no_data was found"/>
        <span className='not_login_text'>
            <p>
                {Message[currentLanguage]}
            </p>
        </span>
    </div>
}

export default function NotFound({ Data, Message, onRetry, imgToShow }) {

    const currentLanguage = useSelector(state => state.madoshome.currentLanguage.code);

    return <>
        {Data.length === 0 ?
            <div className="no_data_was_found">
                <img src={imgToShow} alt="no_data was found" />
                <span className='not_login_text'>
                    <p>
                        {Message[currentLanguage]}
                    </p>
                </span>
                <i className="fa-solid fa-rotate-right" onClick={onRetry}></i>
            </div>
            : <></>
        }
    </>

}

