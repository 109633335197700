import React, { createRef } from "react";
import { connect, useSelector } from "react-redux";
import { systemAuthBaseUrl } from "../../../config/config";
import ApiProvider, { ApiMethods } from "../../../data/providers/api_services";
import { capitalize, getErrorMessage, madoshomeUSer } from "../../../utils/utils";
import ListViewBuilder from '../../components/list_view_builder/listViewBuilder';
import { CardObserver } from "../cards_displayer/cards_displayer";
import Loader from "../loader/loader";
import CommentsDisplayer from "./comments";
import { Link } from "react-router-dom";


class ViewOverComments extends React.Component {

    state = {
        comments: [],
        commenters: [],
        isAsyncCall: true,
        isAsyncMore: false,
    }

    componentDidMount() { 
        this.loading();
    }

    max = 50;

    loading() {
        if (!this.state.isAsyncMore) {
            this.setState({ isAsyncMore: true });
            this.onFetchingComments(this.state.comments.length ? this.state.comments[this.state.comments.length - 1].id : 0).then(async (comments) => {
                const commenters = await this.onFetchingCommenters(comments.map((e) => { return e.UserId }));
                await this.setState({ isAsyncMore: false, isAsyncCall: false, commenters: [...this.state.commenters, ...commenters] });
                if (comments.length) {
                    if (this.state.comments.length < this.max) {
                        const commentsList = [...this.state.comments, ...comments];
                        await this.setState({ comments: commentsList.map((e) => { return { ...e, commenter: this.state.commenters.filter((i) => i.id === Number(e.UserId))[0] } }) });
                    } else {
                        await this.setState({ comments: comments.map((e) => { return { ...e, commenter: this.state.commenters.filter((i) => i.id === Number(e.UserId))[0] } }) });
                    }
                } else {
                    await this.setState({ maxReached: true });
                }
            });
        }
    }

    displayerRef = createRef();

    render() {
        console.log(this.state.comments);
        const { entityId, baseUrl, commentBody, commentEndpoint } = this.props.options;
        return <div className="view_over_comments">
            <div className="view_over_comments_header">
                <div>
                    <h2>{{
                        fr: "Commentaires",
                        en: "Comments",
                        sw: "Komenti"
                    }[`${this.props.currentLanguage}`]}</h2>
                </div>
                <span id='cross_container' onClick={() => { this.props.onClosePressed() }}>
                    <i className="fa-solid fa-xmark"></i>
                </span>
            </div>
            <div className="divider" />
            <CommentsDisplayer options={{
                comments: this.state.comments.length,
                entityId: entityId,
                currentUser: this.props.userInformation?.id,
                baseUrl: baseUrl,
                commentBody: commentBody,
                commentEndpoint: commentEndpoint,
                giveMeTheComment: async (comment) => {
                    this.setState({ isAsyncCall: true })
                    // const commenters = await this.onFetchingCommenters([comment?.UserId ?? 0]);
                    await this.setState({ comments: [{ ...comment, commenter: this.props.userInformation }, ...this.state.comments], isAsyncCall: false, });
                },
                onCommentsStateUpdate: (comments) => {
                    // this.setState({ comments: comments });
                }
            }} />
            <div className="divider" />
            <ListViewBuilder
                itemCount={this.state.isAsyncMore ? this.state.comments.length + 1 : this.state.comments.length}
                itemBuilder={(index) => {
                    const element = this.state.comments[index];
                    const isTheTrueLastOne = (this.state.comments.length - 1) === index;
                    return Object.keys(element ?? {}).length > 0
                        ? isTheTrueLastOne
                            ? <CardObserver
                                displayerRef={this.displayerRef}
                                onReachBottom={() => {
                                    if (!this.state.maxReached) {
                                        this.loading();
                                    }
                                }}
                                key={index}><CommentCard
                                    id={element.id}
                                    commenter={element.commenter}
                                    comment={element.Comment}
                                    createdAt={element.createdAt}
                                    onDelete={(id) => this.onDeleteComment(id)}
                                /></CardObserver>
                            : <CommentCard
                                id={element.id}
                                commenter={element.commenter}
                                comment={element.Comment}
                                createdAt={element.createdAt}
                                onDelete={(id) => this.onDeleteComment(id)}
                            />
                    : this.state.isAsyncMore ? <div className="full_space_loader"><Loader /></div> : <div />
                }}
                onReachBottom={() => { }}
            />
        </div>
    }


    onDeleteComment(commentId) {
        const { entityId, baseUrl, comments, deleteCommentEndpoint } = this.props.options;
        this.setState({ isAsyncCall: false, errorMessage: '' });
        const restApiServices = new ApiProvider();
        restApiServices.provider({
            baseUrl: baseUrl,
            endpoint: `api/${deleteCommentEndpoint}/${commentId}/${entityId}`,
            apiMethod: ApiMethods.delete,
            successCodeExpectation: 200,
            requestData: {
                token: { accessToken: this.props?.userInformation?.accessToken },
                body: {}
            },
        }).then((response) => {
            if (typeof (response.error) === 'undefined') {
                console.log(response.response);
                this.props.options.onCommentsStateUpdate(Number(comments) - 1)
                this.setState({ isAsyncCall: false, comments: [...this.state.comments].filter((e) => e.id !== commentId) });
            } else {
                this.setState({ isAsyncCall: false, errorMessage: getErrorMessage('e13', 'en') });
            }
        });
    }

    async onFetchingComments(lastId) {
        const { entityId, baseUrl, fetchingCommentsEnpoint } = this.props.options;
        const restApiServices = new ApiProvider();
        return await restApiServices.provider({
            baseUrl: baseUrl,
            endpoint: `api/${fetchingCommentsEnpoint}/${entityId}?offset=${lastId}`,
            apiMethod: ApiMethods.get,
            successCodeExpectation: 200,
            requestData: {
                token: { accessToken: '' }
            },
        }).then(async (response) => {
            if (typeof (response.error) === "undefined") {
                return response.response;
            } else {
                return [];
            }
        });
    }

    async onFetchingCommenters(ids) {
        if (!ids.length) return [];
        const restApiServices = new ApiProvider();
        return await restApiServices.provider({
            baseUrl: systemAuthBaseUrl,
            endpoint: `api/user-view-list`,
            apiMethod: ApiMethods.post,
            successCodeExpectation: 200,
            requestData: {
                token: {
                    accessToken: ''
                },
                body: ids,
            },
        }).then((response) => {
            if (typeof (response.error) === "undefined") {
                return response.response;
            } else {
                return [];
            }
        });
    }
}

const mapStateToProps = (storeOf) => ({
    currentLanguage: storeOf.madoshome.currentLanguage.code,
    userInformation: storeOf.madoshome.userInformation,
});

export default connect(mapStateToProps)(ViewOverComments);

function CommentCard({ id = 0, commenter = madoshomeUSer, comment = 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.', createdAt = '2 hours ago', onDelete = (id) => { } }) {
    const { userInformation } = useSelector((storeOf) => ({
        userInformation: storeOf.madoshome.userInformation,
    }));

    return <div className="comments_displayer_comment_card">
        <div className="comments_displayer_comment_card_header flex">
            <img src={commenter.ProfilImage} alt="" />
            <div style={{ width: '100%' }}>


                <Link to={`/users/${commenter.UID}`}><h4>{capitalize(commenter.Fullname)}</h4></Link>
    
                
                <div className="comments_displayer_comment_card_header_footer flex">
                    <p className="comments_displayer_comment_card_comment_date">{new Date(createdAt).toLocaleString()}</p>
                    <div />
                    {(commenter.id === userInformation.id) ? <i className="fa fa-trash" id="delete_comment_button" onClick={(event) => {
                        event.stopPropagation();
                        onDelete(id)
                    }} /> : <></>}
                </div>
            </div>

        </div>
        <p className="comments_displayer_comment_card_comment">{comment}</p>

        {/* <div className="divider" /> */}
    </div>
}