import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PopUpInput from "../pop_up_input/pop_up_input";
import './multi_select_input.css';

function isSelected(id, items) {
    return [...items].filter((e) => Number(e) === id).length > 0;
}

function onSelection(id, items) {
    if (isSelected(id, items)) {
        return [...items].filter((e) => Number(e) !== id);
    }
    else {
        const list = [...items];
        list.push(id);
        return list;
    }
}

export default function MultiSelectInput({ isResetTime = false, isDisabled = false, intialValue = [], placeholder = '', items = [], onConfirm = (items) => { } }) {
    const [selectedItems, setItems] = useState([]);
    const [isPoped, setPopUp] = useState(false);
    const [finalItems, setFinalItems] = useState([]);
    const inputId = `${placeholder}${Math.random() * 28368762318}`;
    const currentLanguage = useSelector(state => state.madoshome.currentLanguage.code)

    if (isResetTime) {
        if (!isDisabled) {
            setFinalItems([]);
            setItems([]);
        }
    }

    useEffect(() => {
        if (intialValue.length) {
            setFinalItems(intialValue.map((e) => e.name));
            setItems(intialValue.map((e) => e.name));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [intialValue])


    return <div className="multi_select_input">
        {!finalItems.length ? <>
            <input type='text' id={inputId} style={{ display: 'none' }} onClick={(event) => {
                event.stopPropagation();
                setFinalItems([]);
                setItems([]);
                setPopUp(!isPoped);
            }} />
            <label className="input_like_div file_uploader_input_hint_style file_uplaoder_input_field_label" htmlFor={inputId}>{placeholder}</label>
            <PopUpInput
                isDisplayed={isPoped}
                expanded={false}
                body={<div className="multi_select_input_comp">
                    <p>{placeholder}</p>
                    <p className="multi_select_input_comp_helper">{{
                        fr : 'Cliquez sur un element pour le choisir',
                        en : 'Click on an item to select it',
                        sw : 'Bofya kwenye kipengee ili kukichagua'
                    }[`${currentLanguage}`]}</p>
                    <div className="multi_select_input_body">
                        {items.map((e, i) => {
                            return <p key={i} className={`multi_select_input_body_item ${isSelected(i, selectedItems) && 'multi_select_input_body_item_selected'}`} onClick={(event) => {
                                event.stopPropagation();
                                setItems(onSelection(i, selectedItems));
                            }}>{e}</p>
                        })}
                    </div>
                </div>}
                actions={[
                    { label: 'Cancel', onClick: () => { setPopUp(!isPoped); } },
                    { label: 'Confirm', onClick: () => { onConfirm(selectedItems); setFinalItems(selectedItems); setPopUp(!isPoped); } },
                ]} />
        </> : <></>
        }
        {finalItems.length ? <div className="multi_select_input multi_select_input_not_empty">
            <div className="flex multi_select_input_not_empty_title">
                <p id="multi_select_input_title">{selectedItems.length} selected</p>
                <i className="fa fa-close" onClick={(event) => {
                    event.stopPropagation();
                    event.preventDefault();
                    if (!isDisabled) {
                        setFinalItems([]);
                    }
                }} />
            </div>
        </div> : <></>}
    </div>
}