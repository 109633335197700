import './navigation.css';
import React from 'react';
import { Link } from 'react-router-dom';
import { appName } from '../../../config/config';
import { connect } from 'react-redux';
import { copyWith } from '../../../logic/slice';

import Authentication from '../../pages/Authentication/authentication';
import Publish from '../../pages/publish/publish';
import SearchUsers from '../search_users/search_users';
import ThemeChanger from '../theme_changer/theme_changer';
import { onLogout } from '../../pages/my_account/myaccount';
import LanguageChanger from '../language_changer/languageChanger';
import Multilanguage from '../multi_language/multilanguage';
import noImg from '../../../assets/images/empty_picture_password.png'




const NavbarData = {
    fr: {
        home: 'Acceuil',
        search: 'Recherche',
        myAccount: 'Mon Compte',
        favorite: 'Favoris',
        publish: 'Publier',
        joinus: 'Rejoignez nous pour debloquer le meilleur de Madoshome',
        contactus: 'Nous contacter',
        tc: 'Conditions et termes',
    },
    en: {
        home: 'Home',
        search: 'Search',
        myAccount: 'My Account',
        favorite: 'Favorites',
        publish: 'Publish',
        joinus: 'Joins us to unlock the best of Madoshome',
        contactus: 'Nous contacter',
        tc: 'Terms and conditions',
    },
    sw: {
        home: 'Nyumbani',
        search: 'Tafuta',
        myAccount: 'Account Yangu',
        favorite: 'Vipendwa',
        publish: 'Kuchapisha',
        joinus: 'Ungana nasi ili ufunguwe utendaji bora wa madoshome',
        contactus: 'Wasiliana nasi',
        tc: 'Masharti'
    }
}

class NavigationMenuTranslate extends React.Component {
    state = {
        isClicked: 0,
        isShowSmallMenucontainer: false,
        isShowLanguageDisplayer: false,
        wannaPublish: false,
        wannaSearch: false,
        isShowLogin: false,
        isModaloginformDisplayed: false
    }
    Menu = (hasLoggedIn) => [
        { listName: { 'fr': "Accueil", 'en': "Home", 'sw': "Nyumbani" }, route: '', IconName: "", canBeDisplayed: true },
        { listName: { 'fr': "Favoris", 'en': "Favourites", 'sw': "Vipendwa" }, route: 'favorites', IconName: "", canBeDisplayed: hasLoggedIn },
        { listName: { 'fr': "Chercher", 'en': "Search", 'sw': "Tafuta" }, route: 'search', IconName: "", canBeDisplayed: true },
        { listName: { 'fr': "Mon compte", 'en': "My account", 'sw': "Account yangu" }, route: 'my-account', IconName: "", canBeDisplayed: true },
    ].filter((element) => element.canBeDisplayed === true);

    options = (hasLoggedIn) => [
        {
            IconName: "fa-solid fa-arrow-right-to-bracket", link: " ",
            listName: {
                'fr': `Rejoignez-nous pour debloquer le meilleur de ${appName}`,
                'en': `Join us to unlock the best of ${appName}`,
                'sw': `Jiunge nasi ili kufungua vipengele bora vya ${appName}`,
            },
            canBeDisplayed: !hasLoggedIn
        },
        {
            IconName: "fa-solid fa-arrow-right-from-bracket", link: " ",
            listName: {
                'fr': "Se Déconnecter",
                'en': "Log out",
                'sw': "Ondoka"
            },
            canBeDisplayed: hasLoggedIn
        },
        {
            IconName: "fa-solid fa-headset", link: "/contact-us/",
            listName: {
                'fr': "Nous contacter",
                'en': "Contact us",
                'sw': "Wasiliyana nasi"
            },
            canBeDisplayed: true
        },
        {
            IconName: "fa-solid fa-file-lines", link: "/terms-and-conditions/",
            listName: {
                'fr': "Termes et conditions",
                'en': "Terms and Conditions",
                'sw': "Sheria na Masharti"
            },
            canBeDisplayed: true
        },
    ].filter((element) => element.canBeDisplayed === true);

    componentDidMount() {
        let currentIndex = localStorage.getItem(`${appName}clm`) ?? 0;
        this.setState({ isClicked: Number(currentIndex) })
    }



    render() {
        const { isLogin, userInformation, callbackLogin } = this.props.otherProps
        // const TranslatedData = this.props.data;
        return (
            <nav>
                {this.state.isShowLogin ? <Authentication onClosePressed={() => { this.setState({ isShowLogin: false }) }} /> : <></>}
                {this.state.wannaSearch ? <SearchUsers onClosePressed={() => { this.setState({ wannaSearch: false }) }} currentLanguage={this.props.CurrentLanguage} /> : <></>}
                {this.state.wannaPublish && <Publish onClosePressed={() => { this.setState({ wannaPublish: !this.state.wannaPublish }) }} />}
                {this.state.isModaloginformDisplayed === true && <Authentication onClosePressed={() => { this.setState({ isModaloginformDisplayed: !this.state.isModaloginformDisplayed }) }} />}
                {this.state.isShowLanguageDisplayer ? <div className='curtain_small'
                    onClick={(e) => {
                        e.stopPropagation();
                        this.setState({ isShowLanguageDisplayer: false })
                    }}>
                    <LanguageChanger onLanguageChanged={() => {
                        this.setState({ isShowLanguageDisplayer: false })
                    }} />
                </div> : <></>}

                <div className='Logo'>
                    <Link to=''>
                        <h2 id='logo'>{appName}</h2>
                    </Link>
                </div>
                <ul className='Menu_container'>

                    {this.Menu(isLogin).map((e, i) => {
                        return (
                            <Menu
                                key={i}
                                isClicked={e.route === String(window.location.pathname).replaceAll('/', '')}
                                route={e.route}
                                listName={e.listName[`${this.props.CurrentLanguage}`]}
                                onPressed={() => {
                                    localStorage.setItem(`${appName}clm`, i);
                                    this.setState({ isClicked: i });
                                }}
                                iconName={e.IconName}
                            />
                        )
                    })}
                </ul>
                <div className='Nav_action'>
                    <i id='publish-on-small-area' className='fa-solid fa-house-laptop' onClick={(event) => {
                        event.stopPropagation()
                        isLogin ? this.setState({ wannaPublish: !this.state.wannaPublish })
                            : this.setState({ isShowLogin: !this.state.isShowLogin })
                    }} />
                    <i className="fa-solid fa-search" onClick={(e) => { e.stopPropagation(); this.setState({ wannaSearch: true }) }}></i>
                    <i className="fa-solid fa-globe" onClick={(e) => { e.stopPropagation(); this.setState({ isShowLanguageDisplayer: true }) }}></i>
                    <div className='action_button flex' id='publish-on-wide-area' onClick={(event) => {
                        event.stopPropagation()
                        isLogin ? this.setState({ wannaPublish: !this.state.wannaPublish })
                            : this.setState({ isShowLogin: !this.state.isShowLogin })
                    }}>
                        <i className='fa fa-house-laptop' />

                        <h6>{{
                            'fr': "Publier",
                            'en': "Publish",
                            'sw': "Kuchapisha"
                        }[`${this.props.CurrentLanguage}`]}</h6>
                    </div>

                    <div className='profil_container' onClick={() => { this.setState({ isShowSmallMenucontainer: !this.state.isShowSmallMenucontainer }) }}>
                        <div className="small_user_circle">
                            {isLogin ?
                                <img src={userInformation.ProfilImage ?? noImg} alt={`${userInformation.Fullname}_madosHome`} />
                                : <img src={noImg} alt="-" />
                            }
                        </div>
                        <i className="fa-solid fa-bars"></i>
                    </div>
                    {this.state.isShowSmallMenucontainer === true ?
                        <div className='curtain_small' onClick={() => { this.setState({ isShowSmallMenucontainer: !this.state.isShowSmallMenucontainer }) }}>
                            <div className='small_menu_container'>
                                {this.options(isLogin).map((e, i) => {
                                    return <>
                                        {
                                            i === 0 ?
                                                <div
                                                    className={`small_menu_title ${isLogin && "tiles_single_content logout"}`}
                                                    key={e.IconName}
                                                    onClick={(event) => {
                                                        event.stopPropagation();
                                                        if (isLogin) {
                                                            onLogout(callbackLogin);
                                                            this.setState({ isShowSmallMenucontainer: !this.state.isShowSmallMenucontainer })
                                                        } else {
                                                            this.setState({ isShowSmallMenucontainer: false });
                                                            this.setState({ isModaloginformDisplayed: true });

                                                        }
                                                    }}>
                                                    <i className={e.IconName}></i>
                                                    <span>{e.listName[`${this.props.CurrentLanguage}`]}</span>
                                                </div>

                                                : <div className='small_menu' key={e.IconName}>
                                                    <i className={e.IconName}></i>
                                                    <p>
                                                        <a href={`https://madoshome.com${e.link}${this.props.CurrentLanguage !== 'en' ? this.props.CurrentLanguage + "/" : ''}`} target={"_blank"} rel={"noreferrer"}>{e.listName[`${this.props.CurrentLanguage}`]}</a>
                                                    </p>
                                                </div>
                                        }
                                    </>
                                })}
                                <ThemeChanger isExpended={true} />
                            </div>
                        </div>
                        : <></>
                    }
                </div>
            </nav>
        )
    }
}


class NavigationMenu extends React.Component {

    callbackLogin = () => {
        this.props.dispatch(copyWith({
            userInformation: {}
        }));
        this.setState({ historySubscription: [], pendingPaymentIntents: [] });
    }

    render() {
        const UserInformation = this.props.userInformation;
        const isLogin = Object.keys(this.props.userInformation).length > 0;
        return <Multilanguage
            ComponentTorender={NavigationMenuTranslate}
            data={NavbarData}
            isLogin={isLogin}
            userInformation={UserInformation}
            callbackLogin={this.callbackLogin}
        />
    }
}



export function Menu({ iconName, listName, route, onPressed, isClicked }) {
    return (
        <li onClick={onPressed}>
            <Link className={`${isClicked === true && "active_route"}`} to={`/${route}`} >

                {iconName === '' ? <> </> : <i className={`fa fa-${iconName}`}></i>}

                <h4 style={{ fontWeight: '700' }}>{listName}</h4>

            </Link>
        </li>

    );
}


const mapStateToProps = (storeOf) => ({
    currentLanguage: storeOf.madoshome.currentLanguage,
    userInformation: storeOf.madoshome.userInformation,
});
export default connect(mapStateToProps)(NavigationMenu)