import React, { Component, useState } from 'react'
import { connect, useSelector } from 'react-redux';
import { systemHousesBaseUrl } from '../../../config/config';
import ApiProvider, { ApiMethods } from '../../../data/providers/api_services';
import { getErrorMessage, isEmpty } from '../../../utils/utils';
import Notifier from '../notifier/notifier';
import './update_bedrooms.css';

class UpdateBedrooms extends Component {

    state = {
        rooms: [],
        errorMessage: '',
        isAsyncCall: false,
        roomToAdd: { BathRoom: false, Wardrobe: false, Balcony: false },
    }

    componentDidMount() {
        this.setState({ rooms: this.props.rooms ?? [] });
    }
    render() {
        const rooms = this.state.rooms;
        return (
            <div className='update_bedrooms'>
                {!isEmpty(this.state.errorMessage) && <>
                    <Notifier type='error' message={this.state.errorMessage} />
                    <div className="divider" />
                </>}
                {[...rooms ?? []].map((e, i) => {
                    return <Room
                        key={e.id}
                        houseId={this.props.houseId}
                        room={e}
                        onDelete={(id) => {
                            this.setState({ rooms: this.state.rooms.filter((e) => e.id !== id) });
                        }}
                        onUpdate={() => { }}
                        onError={(message) => {
                            this.setState({ errorMessage: message });
                        }}
                    />
                })}
                <div className='update_bedrooms_item'>
                    <div className='update_bedrooms_item_body'>
                        <fieldset className="checkbox_input_holder_disabled_holder update_bedrooms_item_body_new">
                            <div className="checkbox_input_holder">
                                <input type='checkbox' checked={this.state.roomToAdd.BathRoom} onChange={(event) => {
                                    event.stopPropagation();
                                    this.setState({ roomToAdd: { ...this.state.roomToAdd, BathRoom: event.target.checked } })
                                }} />
                                <label>{{
                                    fr: "Douche",
                                    en: "Bathroom",
                                    sw: "Bafuni"
                                }[this.props.currentLanguage.code]}</label>

                            </div>
                            <div className="checkbox_input_holder">
                                <input type='checkbox' checked={this.state.roomToAdd.Wardrobe} onChange={(event) => {
                                    event.stopPropagation();
                                    this.setState({ roomToAdd: { ...this.state.roomToAdd, Wardrobe: event.target.checked } })
                                }} />
                                <label>{{
                                    fr: 'Armoire',
                                    en: 'Wardrobe',
                                    sw: 'Kabati',

                                }[this.props.currentLanguage.code]}</label>
                            </div>
                            <div className="checkbox_input_holder">
                                <input type='checkbox' checked={this.state.roomToAdd.Balcony} onChange={(event) => {
                                    event.stopPropagation();
                                    this.setState({ roomToAdd: { ...this.state.roomToAdd, Balcony: event.target.checked } })
                                }} />
                                <label>{{
                                    fr: 'Balcon',
                                    en: 'Balcony',
                                    sw: 'Balcony'
                                }[this.props.currentLanguage.code]}</label>
                            </div>
                        </fieldset>
                    </div>
                    <h6 className='add_new_room' onClick={(event) => {
                        event.stopPropagation();
                        this.onAdd(this.state.roomToAdd);
                    }}>Add</h6>
                    {this.state.isAsyncCall && <i id='spinner_loader' className='fa-solid fa-spinner fa-spin' />}
                </div>

            </div>
        )
    }

    onAdd(data) {
        this.setState({ isAsyncCall: true, errorMessage: '' });
        const restApiServices = new ApiProvider();
        restApiServices.provider({
            baseUrl: systemHousesBaseUrl,
            endpoint: `api/building/add-bedroom`,
            apiMethod: ApiMethods.post,
            successCodeExpectation: 200,
            requestData: {
                token: { accessToken: this.props.userInformation?.accessToken },
                body: {
                    BuildingId: this.props.houseId,
                    ...data,
                }
            },
        }).then((response) => {
            if (typeof (response.error) === 'undefined') {
                console.log(response.response);
                this.setState({ isAsyncCall: false, errorMessage: '', rooms: [...this.state.rooms, response.response], roomToAdd: { BathRoom: false, Wardrobe: false, Balcony: false } });

            } else {
                this.setState({ isAsyncCall: false, errorMessage: getErrorMessage(response.error) });

            }
        });
    }

}

const mapStateToProps = (storeOf) => ({
    currentLanguage: storeOf.madoshome.currentLanguage,
    userInformation: storeOf.madoshome.userInformation,
});

export default connect(mapStateToProps)(UpdateBedrooms);


function Room({ houseId = 0, room = {}, onDelete = () => { }, onUpdate = () => { }, onError = () => { } }) {
    const [isDisabled, setEnable] = useState(true);
    const [roomState, setRoomState] = useState(room);
    const [changes, setChanges] = useState({});
    const [isAsyncCall, setAsyncCall] = useState(false);

    const { userInformation, currentLanguage } = useSelector((storeOf) => ({
        userInformation: storeOf.madoshome.userInformation,
        currentLanguage: storeOf.madoshome.currentLanguage.code
    }));
    function onUpdateRoom() {
        setAsyncCall(true);
        onError('');
        const restApiServices = new ApiProvider();
        restApiServices.provider({
            baseUrl: systemHousesBaseUrl,
            endpoint: `api/building/alter-bedroom/${room.id}/${houseId}`,
            apiMethod: ApiMethods.patch,
            successCodeExpectation: 200,
            requestData: {
                token: { accessToken: userInformation?.accessToken },
                body: changes
            },
        }).then((response) => {
            if (typeof (response.error) === 'undefined') {
                console.log(response.response);
                setAsyncCall(false);
                setEnable(!isDisabled);
            } else {
                setAsyncCall(false);
                onError(getErrorMessage(response.error));
            }
        });
    }

    function onDeleteRoom() {

        setAsyncCall(true);
        const restApiServices = new ApiProvider();
        restApiServices.provider({
            baseUrl: systemHousesBaseUrl,
            endpoint: `api/building/remove-bedroom/${room.id}/${houseId}`,
            apiMethod: ApiMethods.delete,
            successCodeExpectation: 200,
            requestData: {
                token: { accessToken: userInformation?.accessToken },
            },
        }).then((response) => {
            if (typeof (response.error) === 'undefined') {
                console.log(response.response);
                onDelete(room.id);
                setAsyncCall(false);
            } else {
                setAsyncCall(false);
            }
        });
    }

    return <div className='update_bedrooms_item'>
        <div className='bedRoom_input_title room_item update_bedrooms_item_body'>
            <fieldset disabled={isDisabled} className="checkbox_input_holder_disabled_holder ">
                <div className="checkbox_input_holder">
                    <input type='checkbox' checked={roomState.BathRoom} onChange={(event) => {
                        event.stopPropagation();
                        setRoomState({ ...roomState, BathRoom: event.target.checked });
                        setChanges({ BathRoom: event.target.checked });
                    }} />
                    <label>{{
                        fr: "Douche",
                        en: "Bathroom",
                        sw: "Bafuni"
                    }[currentLanguage]}</label>
                </div>
                <div className="checkbox_input_holder">
                    <input type='checkbox' checked={roomState.Wardrobe} onChange={(event) => {
                        event.stopPropagation();
                        setRoomState({ ...roomState, Wardrobe: event.target.checked });
                        setChanges({ Wardrobe: event.target.checked });
                    }} />
                    <label>{{
                        fr: 'Armoire',
                        en: 'Wardrobe',
                        sw: 'Kabati',

                    }[currentLanguage]}</label>
                </div>
                <div className="checkbox_input_holder">
                    <input type='checkbox' checked={roomState.Balcony} onChange={(event) => {
                        event.stopPropagation();
                        setRoomState({ ...roomState, Balcony: event.target.checked });
                        setChanges({ Balcony: event.target.checked });
                    }} />
                    <label>{{
                        fr: 'Balcon',
                        en: 'Balcony',
                        sw: 'Balcony'
                    }[currentLanguage]}</label>
                </div>
            </fieldset>
        </div>
        {isDisabled && <i className='fa fa-lock' onClick={(event) => {
            event.stopPropagation();
            setEnable(!isDisabled);
        }} />}
        {!isDisabled && <i className='fa fa-unlock' onClick={(event) => {
            event.stopPropagation();
            if (Object.keys(changes).length) {
                onUpdateRoom();
            } else {
                setEnable(!isDisabled);
            }
        }} />}
        <i className='fa fa-trash' onClick={(event) => {
            event.stopPropagation();
            onDeleteRoom();
        }} />
        {isAsyncCall && <i id='spinner_loader' className='fa-solid fa-spinner fa-spin' />}
    </div>



}
