import Internationalisation from "../providers/internationalisation";
import LocalStorageServices from "../providers/local_storage";


export default class ManagerRepo {

    loadAppSettings() {
        const localStorageServices = new LocalStorageServices();
        let isDarkModeOn = localStorageServices.isDarkTheme();
        let currentLanguage = localStorageServices.getCurrentLanguage();
        let refreshToken = localStorageServices.getRefreshToken();
        let accessToken = localStorageServices.getAccessToken();
        return {
            isDarkModeOn: isDarkModeOn,
            currentLanguage: new Internationalisation().getLanguageByCode(currentLanguage),
            refreshToken: refreshToken,
            accessToken: accessToken,
        };
    }

    onFirstTimeOpeningTheApp() {
        const localStorageServices = new LocalStorageServices();
        localStorageServices.onFirstTime();
        localStorageServices.onThemeChanged(false);
        localStorageServices.onLanguageChanged(
            new Internationalisation().codeToType(navigator.language.split("-")[0]),
        );
    }

    onThemeChanged(isDarkModeOn) {
        const localStorageServices = new LocalStorageServices();
        localStorageServices.onThemeChanged(isDarkModeOn);
    }

    onSetRefreshToken(tokens) {
        const { accessToken, refreshToken } = tokens;
        const localStorageServices = new LocalStorageServices();
        localStorageServices.setRefreshToken(refreshToken);
        localStorageServices.setAccessToken(accessToken);
    }

    onLanguageChanged(language) {
        const localStorageServices = new LocalStorageServices();
        const internationalisation = new Internationalisation();
        localStorageServices.onLanguageChanged(language);
        internationalisation.onLanguageChanged(language);
        return internationalisation.currentLanguage;
    }
}