import axios from "axios";
// import { deleteFile, uploadFile } from "react-s3";
import { appName } from "../config/config";
import ApiProvider, { ApiErrors, ApiMethods } from "../data/providers/api_services";



export const madoshomeUSer = {
    "id": 0,
    "UID": "b9a31f59-7e6c-43a4-bd79-e8256777b0e0",
    "Fullname": "Madoshome user",
    "ProfilImage": `https://avatars.dicebear.com/api/personas/male/${Math.floor(Math.random() * 46542655127517)}.png`,
    "PhoneNumber": "+000 111 222 333 444",
    "WhatsappNumber": "+000 111 222 333 444",
    "Email": "madoshomeuser@example.com",
    "createdAt": new Date(),
    "UserType": {
        "Name": "Unknown user"
    },
    "Metadata": {
        "UserId": 0,
        "Building": 0,
        "ActifBuilding": 0,
        "Hotel": 0,
        "ActifHotel": 0,
        "Tourism": 0,
        "ActifTourism": 0
    }
}

export function capitalize(value) {
    const wordsInSentence = String(`${value}`).trim().split(" ");
    var valueToBeRetuned = '';
    for (var i in wordsInSentence) {
        var h = String(wordsInSentence[i][0]).toLocaleUpperCase().concat(String(wordsInSentence[i]).substring(1).toLocaleLowerCase());
        valueToBeRetuned = `${String(valueToBeRetuned).concat(h)} `;
    }
    return String(valueToBeRetuned).trim();
}

export function isEmpty(value) {
    return String(`${value}`).split(" ").join("").length < 1;
}

export function getRandomArbitrary(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

function stringtoError(errorMessage) {
    switch (errorMessage) {
        case "e12":
            return ApiErrors.e12;
        case "e13":
            return ApiErrors.e13;
        case "e14":
            return ApiErrors.e14;
        default:
            return ApiErrors.e15;
    }
}


export function getErrorMessage(errorMessage, languageCode) {

    const internationalisation = {
        "e12": {
            "fr":
                `Pour utiliser ${appName}, activez les données mobiles ou connectez-vous au Wi-Fi.`,
            "en": `To use ${appName}, turn on mobile data or connect to Wi-Fi.`,
            "sw":
                `Ili kutumia ${appName}, washa data ya mtandao wa simu au unganisha kwenye Wi-Fi.`,
        },
        "e13": {
            "fr":
                "Une erreur de serveur s'est produite et notre équipe y travaille. Veuillez réessayer plus tard..",
            "en":
                "A server error has occurred and our team is working on it. Please try again later..",
            "sw":
                "Hitilafu ya seva imetokea na timu yetu inaifanyia kazi. Tafadhali jaribu tena baadae..",
        },
        "e14": {
            "fr":
                "L'application a rencontré des problèmes, notre équipe y travaille. Veuillez réessayer plus tard..",
            "en": "The app has encountered issues, our team is working on it. Please try again later..",
            "sw":
                "Programu imekumbana na matatizo, timu yetu inaifanyia kazi. Tafadhali jaribu tena baadae..",
        },
    };
    switch (stringtoError(errorMessage)) {
        case ApiErrors.e12:
            return internationalisation.e12[languageCode];
        case ApiErrors.e13:
            return internationalisation.e13[languageCode];
        case ApiErrors.e14:
            return internationalisation.e14[languageCode];
        case ApiErrors.e15:
            if (typeof errorMessage === 'object' && Array.isArray(errorMessage) === false) {
                if (Object.keys(errorMessage).length) {
                    if (Object.keys(errorMessage).filter((e) => e === 'errors').length) {
                        var message = '';
                        for (var i = 0; i < errorMessage.errors.length; i++) {
                            message = `${message} ${errorMessage.errors[i].message}`
                        }
                        return `${message}`;
                    }
                } else {
                    return internationalisation.e14[languageCode];
                }
            }
            return `${errorMessage}`;
        default:
            return `${errorMessage}`;
    }
}


export function geoUserLocation({ onSuccess = () => { }, onError = () => { } }) {
    navigator.geolocation.getCurrentPosition((position) => {
        console.log(position);
        onSuccess({
            lat: Number(position.coords.latitude),
            lon: Number(position.coords.longitude),
            regionName: '',
        });
    }, (error) => {
        onSuccess({
            lat: -3.361260,
            lon: 29.347916,
            regionName: 'Bujumbura, Burundi',
        });
    });
    // restApiServices.provider({
    //     baseUrl: 'http://ip-api.com',
    //     endpoint: 'json',
    //     apiMethod: ApiMethods.get,
    //     successCodeExpectation: 200,
    //     requestData: {},
    // }).then((response) => {
    //     if (typeof (response.error) === 'undefined') {
    //         onSuccess(response.response);
    //     } else {
    //         onError(getErrorMessage(response.error, 'en'));
    //     }
    // });
};


export async function uploadFilesS3(file, { userUUID = getRandomArbitrary(1000000, 1000000000), field = 'Unspecified' }) {
    const date = new Date();
    const url = `https://s3.${process.env.REACT_APP_S3_BUCKET_REGION}.amazonaws.com/${process.env.REACT_APP_S3_BUCKET_NAME}`;
    const form = new FormData();
    console.log("FILE : ",file);
    console.log("NAME", file.name)
    const key = `${appName}/${userUUID}/${field}/${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}/${file.name}`;
    form.append('key', key);
    form.append('acl', 'public-read');
    form.append('file', file);
    return await axios({
        method: 'post',
        headers: {
            ...{
                "Content-Type": "multipart/form-data"
            }
        },
        data: form,
        url: url,
    }).then((res) => {
        if (res.status === 204) {
            const value =res.headers.location;
            console.log("Success upload => Location", res.headers.location, 'host name', String(value).replaceAll(`${new URL(value).hostname}/${process.env.REACT_APP_S3_BUCKET_NAME}`, `${process.env.REACT_APP_S3_BUCKET_CLOUD_FRONT_URL}`));
            // return res.headers.location;
            return String(value).replaceAll(`${new URL(value).hostname}/${process.env.REACT_APP_S3_BUCKET_NAME}`, `${process.env.REACT_APP_S3_BUCKET_CLOUD_FRONT_URL}`)
        }
        return '';
    }).catch((e) => {
        return '';
    })
    // return uploadFile(file, {
    //     bucketName: process.env.REACT_APP_S3_BUCKET_NAME,
    //     region: process.env.REACT_APP_S3_BUCKET_REGION,
    //     accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
    //     secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
    //     dirName: `${appName}/${userUUID}/${field}/${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`,
    // })
    //     .then((data) => {
    //         console.log(data);
    //         return encodeURI(decodeURI(data.location));
    //     })
    //     .catch((err) => {
    //         console.log(err);
    //         return '';
    //     });
}

export async function deleteFilesS3(fileUrl) {
    const path = String(fileUrl).replaceAll(`https://${new URL(fileUrl).hostname}/`, '');
    const url = `https://s3.${process.env.REACT_APP_S3_BUCKET_REGION}.amazonaws.com/${process.env.REACT_APP_S3_BUCKET_NAME}`
    return await new ApiProvider().provider({
        baseUrl: url,
        endpoint: path,
        apiMethod: ApiMethods.delete,
        successCodeExpectation: 204,
        requestData: {
            headers: {
                bucket: process.env.REACT_APP_S3_BUCKET_NAME,
                key: path,
            }
        },
    }
    ).then((res) => {
        return typeof (res.error) === 'undefined';
    })
}

// const currentLanguage = useSelector(state => state.madoshome.currentLanguage.code)

export const amenities = [
    {
        key: "Sauna", name: {
            fr: "Sauna",
            en: "Sauna",
            sw: "Sauna"
        }
    },
    {
        key: "Laundry", name: {
            fr: "Lessive",
            en: "Laundry",
            sw: "Kufulia"
        }
    },
    {
        key: "Garden", name: {
            fr: "Jardin",
            en: "Garden",
            sw: "Bustani"
        }
    },
    {
        key: "Gym", name: {
            fr: "Gym",
            en: "Gym",
            sw: "Uwanja wa mazoezi"
        }
    },
    {
        key: "SwimmingPool", name: {
            fr: "Piscine",
            en: "Swimming pool",
            sw: "Bwawa la kuogelea"
        }
    },
    {
        key: "Heater", name: {
            fr: "Chauffage",
            en: "Heater",
            sw: "Heater"
        }
    },
    {
        key: "Jacuzy", name: {
            fr: "Jaccuzi",
            en: "Jaccuzi",
            sw: "Jaccuzi"
        }
    },
    {
        key: "StoreRoom", name: {
            fr: "Magasin",
            en: "Store room ",
            sw: "chumba cha kuhifadhi"
        }
    },
    {
        key: "AirConditioner", name: {
            fr: "Climatiseur",
            en: "Air conditioner",
            sw: "Kiyoyozi"
        }
    },
    {
        key: "PetFriendly", name: {
            fr: "Les animeaux acceptés",
            en: "Pet friendrly ",
            sw: "Wanyama wanaokubaliwa"
        }
    },
    {
        key: "Funished", name: {
            fr: "Meublé",
            en: "Furnished",
            sw: "Samani"
        }
    },
    {
        key: "Doorbell", name: {
            fr: "Chambre",
            en: "Bedroom",
            sw: "Vyumba"
        }
    },
    {
        key: "Wifi", name: {
            fr: "Wi-fi",
            en: "Wi-fi",
            sw: "Wi-fi"
        }
    },
    {
        key: "BackupSourceForPowerOutage", name: {
            fr: "Source de secours en cas de panne de courant",
            en: "Backup source for power outage",
            sw: "Chanzo cha chelezo katika kesi ya hitilafu ya umeme"
        }
    },
];


export function CalcCrow(lat1, long1, lat2, long2) {
    var earthRadius = 6371;
    var dLat = toRadius(lat2 - lat1);
    var dLon = toRadius(long2 - long1);
    var rlat1 = toRadius(lat1);
    var rlat2 = toRadius(lat2);
    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(rlat1) * Math.cos(rlat2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = earthRadius * c;
    return d.toFixed(2);
}

function toRadius(value) {
    return value * Math.PI / 180;
}