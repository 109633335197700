import React, { Component } from 'react'
import Numeral from 'react-numeral';
import { connect } from 'react-redux';
import { CalcCrow, capitalize } from '../../../utils/utils';
import RawTitleValue from '../title_value/title_value';

class HouseDetails extends Component {
    render() {
        const { building } = this.props;
        return (
            <div>
                <div className='houses_card_details_price_like flex'>
                    {building.SaleDetail && <div className='house_card_details_star'>
                        <h3>{{
                            fr: "Prix de vente",
                            en: "Sale price",
                            sw: "Bei ya kuuza"
                        }[`${this.props.currentLanguage}`]}</h3>
                        <p>{this.props?.currencies.find((e) => e.id === Number(building.SaleDetail.Currency))?.Name} <Numeral value={building.SaleDetail.Price} format={'0.0a'} /></p>
                    </div>}
                    {building.Rental && <div className='house_card_details_star'>
                        <h3>{{
                            fr: "Prix de location",
                            en: "Rental price",
                            sw: "Bei ya kukodisha"
                        }[`${this.props.currentLanguage}`]}</h3>
                        <p>{this.props?.currencies.find((e) => e.id === Number(building.Rental.Currency))?.Name}  <Numeral value={building.Rental.Price} format={'0.0a'} />/{String(building.Rental.RentalType[capitalize(this.props.currentLanguage)]).toLocaleLowerCase()}</p>
                    </div>}
                </div>


                <RawTitleValue title={{
                    fr: "Type",
                    en: "Type",
                    sw: "Aina"
                }[`${this.props.currentLanguage}`]} value={`${building.BuildingType[capitalize(this.props.currentLanguage)]}`} />

                <RawTitleValue title={{
                    fr: "Distance apartir de votre emplacement",
                    en: "Distance from your location",
                    sw: "Umbali kutoka eneo lako"
                }[`${this.props.currentLanguage}`]} value={`${CalcCrow(building.Latitude, building.Longitude, this.props.userLocation?.lat, this.props.userLocation?.lon)} ${{
                    fr: " Kilomètre de loins",
                    en: " Kilometer away",
                    sw: " Kilomita mbali"
                }[`${this.props.currentLanguage}`]} `} />


                <RawTitleValue title={{
                    fr: "Adresse",
                    en: "Address",
                    sw: "Anwani"
                }[`${this.props.currentLanguage}`]} value={`${building.Address}`} />


                <RawTitleValue title={{
                    fr: "Chambre",
                    en: "Bedroom",
                    sw: "Vyumba"
                }[`${this.props.currentLanguage}`]} value={building.BedRooms.length} />

                <RawTitleValue title={{
                    fr: "Douche",
                    en: "Bathroom",
                    sw: "Bafuni"
                }[`${this.props.currentLanguage}`]} value={`${building.BathRoom}`} />


                <RawTitleValue title={{
                    fr: "Salon",
                    en: "Living room",
                    sw: "Sebuleni"
                }[`${this.props.currentLanguage}`]} value={`${building.LivingRoom}`} />


                <RawTitleValue title={{
                    fr: "Salle à manger",
                    en: "Dinner room",
                    sw: "Chumba cha kulia"
                }[`${this.props.currentLanguage}`]} value={`${building.DinningRoom}`} />

                <RawTitleValue title={{
                    fr: "Cuisine",
                    en: "Kitchen",
                    sw: "Jikoni"
                }[`${this.props.currentLanguage}`]} value={`${building.Kitchen}`} />

                <RawTitleValue title={{
                    fr: "Parking",
                    en: "Parking",
                    sw: "Maegesho"
                }[`${this.props.currentLanguage}`]} value={`${building.Parking}`} />


                <RawTitleValue title={{
                    fr: "Garage",
                    en: "Garage",
                    sw: "Gereji"
                }[`${this.props.currentLanguage}`]} value={`${building.Garage}`} />

                <RawTitleValue title={{
                    fr: "Bureau",
                    en: "Office",
                    sw: "Ofisi"
                }[`${this.props.currentLanguage}`]} value={`${building.Office}`} />

                <RawTitleValue title={{
                    fr: "Balcon",
                    en: "Balcony",
                    sw: "Balcony"
                }[`${this.props.currentLanguage}`]} value={`${building.Balcony}`} />

                <RawTitleValue title={{
                    fr: "Taille de la parcelle",
                    en: "Plot size",
                    sw: "Ukubwa wa kiwanja"
                }[`${this.props.currentLanguage}`]} value={`${building.Size} m2`} />

                <RawTitleValue title={{
                    fr: "Construit en",
                    en: "Built in",
                    sw: "Ime jengwa mwaka"
                }[`${this.props.currentLanguage}`]} value={`${building.BuiltIn}`} />


                {building.Funished ? <RawTitleValue title={{
                    fr: "Meublé",
                    en: "Funished",
                    sw: "Samani"
                }[`${this.props.currentLanguage}`]} value={'✓'} /> : <></>}
                {building.Sauna ? <RawTitleValue title={{
                    fr: "Sauna",
                    en: "Sauna",
                    sw: "Sauna"
                }[`${this.props.currentLanguage}`]} value={'✓'} /> : <></>}
                {building.Laundry ? <RawTitleValue title={{
                    fr: "Lessive",
                    en: "Laundry",
                    sw: "Kufulia"
                }[`${this.props.currentLanguage}`]} value={'✓'} /> : <></>}
                {building.SwimmingPool ? <RawTitleValue title={{
                    fr: "Piscine",
                    en: "Swimming pool",
                    sw: "Bwawa la kuogelea"
                }[`${this.props.currentLanguage}`]} value={'✓'} /> : <></>}
                {building.Jacuzy ? <RawTitleValue title={{
                    fr: "Jacuzzi",
                    en: "Jacuzzi",
                    sw: "Jacuzzi"
                }[`${this.props.currentLanguage}`]} value={'✓'} /> : <></>}
                {building.Garden ? <RawTitleValue title={{
                    fr: "Jardin",
                    en: "Garden",
                    sw: "Bustani"
                }[`${this.props.currentLanguage}`]} value={'✓'} /> : <></>}
                {building.AirConditioner ? <RawTitleValue title={{
                    fr: "Climatizateur",
                    en: "Air conditioner",
                    sw: "Kiyoyozi"
                }[`${this.props.currentLanguage}`]} value={'✓'} /> : <></>}
                {building.Wifi ? <RawTitleValue title={{
                    fr: "Wi-fi",
                    en: "Wi-fi",
                    sw: "Wi-fi"
                }[`${this.props.currentLanguage}`]} value={'✓'} /> : <></>}
                {building.Doorbell ? <RawTitleValue title={{
                    fr: "Sonette de porte",
                    en: "Door bell",
                    sw: "Kengele ya mlango"
                }[`${this.props.currentLanguage}`]} value={'✓'} /> : <></>}
                {building.Heater ? <RawTitleValue title={{
                    fr: "Chauffage",
                    en: "Heater",
                    sw: "Heater"
                }[`${this.props.currentLanguage}`]} value={'✓'} /> : <></>}
                {building.Gym ? <RawTitleValue title={{
                    fr: "Gymnase",
                    en: "Gym",
                    sw: "Uwanja wa mazoezi"
                }[`${this.props.currentLanguage}`]} value={'✓'} /> : <></>}
                {building.StoreRoom ? <RawTitleValue title={{
                    fr: "Magasin",
                    en: "Store room ",
                    sw: "Chumba cha kuhifadhi"
                }[`${this.props.currentLanguage}`]} value={'✓'} /> : <></>}
                {building.BackupSourceForPowerOutage ? <RawTitleValue title={{
                    fr: "Sauvegarde en cas de panne d'electicité",
                    en: "Backup in case of power failure ",
                    sw: "Backup in case of power failure"
                }[`${this.props.currentLanguage}`]} value={'✓'} /> : <></>}
                {building.PetFriendly ? <RawTitleValue title={{
                    fr: "Les animeaux  accepter",
                    en: "Pet friendrly ",
                    sw: "Wanyama wanaokubaliwa"
                }[`${this.props.currentLanguage}`]} value={'✓'} /> : <></>}

                
                <div className='divider' />
                <p>{building.Description}</p>
                <div className='divider' />
            </div>
        )
    }
}


const mapStateToProps = (storeOf) => ({
    currentLanguage: storeOf.madoshome.currentLanguage.code,
    currencies: storeOf.madoshome.currencies,
    userLocation: storeOf.madoshome.userLocation,
});

export default connect(mapStateToProps)(HouseDetails);
