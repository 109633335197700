import React from "react";
// import { getErrorMessage, isEmpty } from "../../../utils/utils";
// import Notifier from "../../components/notifier/notifier";

import { systemHotelsBaseUrl, systemHousesBaseUrl, systemTourismBaseUrl, systemFavoriteBaseUrl } from "../../../config/config";

import fixingAccount from "../../../assets/svgs/fixingaccount.svg"

import { connect } from "react-redux";
import ApiProvider, { ApiMethods } from "../../../data/providers/api_services";
import Loader from "../../components/loader/loader";


class RepairAccount extends React.Component {

    state = {
        isAsyncCall: false,
        errorMessage: '',
        successMessage: '',
        step: 0
    }

    endpoints = [
        systemHotelsBaseUrl,
        systemHousesBaseUrl,
        systemTourismBaseUrl,
        systemFavoriteBaseUrl
    ]


    onRepairAccount = () => {
        let Tasks = this.endpoints.map((url) => new ApiProvider().provider({
            baseUrl: url,
            endpoint: "api/account/check-my-health",
            apiMethod: ApiMethods.put,
            successCodeExpectation: 200,
            requestData: {
                token: {
                    accessToken: this.props.userInformation?.accessToken,
                }
            },
        }));

        this.setState({ isAsyncCall: true });
        Promise.all(Tasks).then((response) => {
            console.log(response);
        }).catch(e => {
            console.log(e)
        }).finally(() => this.setState({ isAsyncCall: false }))
    }


    render() {


        return <div className="modal_rideau">
            <div className="dif_modal change_password_up">
                <div className="flex call_to_subcsription_header update_user_informations_header">
                    <div>
                        <h2>{{
                            fr: "Réparation de votre compte",
                            en: "Repair your account",
                            sw: "Rekebisha akaunti yako"
                        }[`${this.props.currentLanguage}`]}</h2>
                    </div>
                    <span id='cross_container' onClick={() => { this.props.onClosePressed() }}>
                        <i className="fa-solid fa-xmark"></i>
                    </span>
                </div>
                <div className="divider" />
                <div className="div_main_repair_container">
                    <div className="header_img_and_text">
                        <img src={fixingAccount} alt="fixing_account" width={200} height={200} />
                        <p>{{
                            fr: `
                                Vous allez réparer votre compte, c'est une opération 
                                qui ne doit être faite que lorsque vous 
                                rencontrez des problèmes avec certaines fonctionnalités sur notre 
                                platforme comme "Aimer, commenter, ou rendre votre publication visible publiquement". Nous analysons et réalisons une réparation complète de votre compte.
                            `,
                            en: `
                                Your are  going to repair your account, this is an operation that should
                                only be done when you encounter problems with certain functionalities on our platform
                                such as "liking, commenting, or making your property publicly visible".
                                We analyze and carry out a complete repair of your account.
                                `,
                            sw: `Utarekebisha akaunti yako, hii ni operesheni ambayo inapashwa kufanywa tu unapokumbana na matatizo ya untendakazi
                                fulani kwenye mfumo wetu kama vile "Kupenda, kutoa maoni au kufanya chapisho lako kuonekana hadharani". Tunachanganua na kufanya ukarabati kamili wa akaunti yako ndani ya muda fulani.`
                        }[`${this.props.currentLanguage}`]}</p>

                        {this.state.isAsyncCall && <Loader />}
                    </div>
                    <div className="call_to_subcsription_buttons flex">
                        <div className='action_button flex' onClick={(event) => {
                            event.stopPropagation()
                            this.props.onClosePressed()
                        }}>
                            <i className='fa-solid fa-ban' />
                            <h6>{{
                                fr: "Annuler",
                                en: "Cancel",
                                sw: "Ghairi"
                            }[this.props.currentLanguage]}</h6>
                        </div>
                        <div className='action_button flex' onClick={(event) => {
                            event.stopPropagation();
                            event.preventDefault();
                            this.onRepairAccount();
                        }}>
                            <i className='fa-solid fa-screwdriver-wrench' />
                            <h6>{{
                                fr: "Réparer",
                                en: "Repair",
                                sw: "Rekebisha"
                            }[`${this.props.currentLanguage}`]}</h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    }




    // repairHouseServer() {
    //     this.setState({ isAsyncCall: true, errorMessage: '', successMessage: '' });
    //     const restApiServices = new ApiProvider();
    //     restApiServices.provider({
    //         baseUrl: systemAuthBaseUrl,
    //         endpoint: 'api/alter-user-change-password',
    //         apiMethod: ApiMethods.patch,
    //         successCodeExpectation: 200,
    //         requestData: {
    //             token: {
    //                 accessToken: this.props?.userInformation?.accessToken,
    //             },
    //             body: formValues,
    //         },
    //     }).then((response) => {
    //         if (typeof (response.error) === 'undefined') {
    //             this.setState({ isAsyncCall: false, successMessage: 'You password has been changed successfully' });
    //             this.props.dispatch(copyWith({ userInformation: {} }));
    //         } else {
    //             this.setState({ isAsyncCall: false, errorMessage: getErrorMessage(response.error, 'en') });
    //         }
    //     });
    // }
}

const mapStateToProps = (storeOf) => ({
    currentLanguage: storeOf.madoshome.currentLanguage.code,
    userInformation: storeOf.madoshome.userInformation,
});

export default connect(mapStateToProps)(RepairAccount);