import React, { createRef } from "react";
import { getErrorMessage, isEmpty } from "../../../utils/utils";
import Notifier from "../../components/notifier/notifier";
import './forget_password.css';
import Joi from "joi";
import ApiProvider, { ApiMethods } from "../../../data/providers/api_services";
import { systemAuthBaseUrl } from "../../../config/config";
import { copyWith } from "../../../logic/slice";
import { connect } from "react-redux";


class UpdateEmail extends React.Component {

    state = {
        isAsyncCall: false,
        errorMessage: '',
        successMessage: '',
        currentEmailOrUsername: '',
        formValues: {},
        doUserExistKnown: false,
    }

    componentDidMount() {
        this.onSendingMail();
    }

    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState(state => ({ ...state, formValues: { ...state.formValues, [name]: value } }));
    }


    formRef = createRef();

    render() {
        const joiSchema = Joi.object({
            VerificationCode: Joi.number().required(),
        });
        return <div className="modal_rideau">
            <div id="forget_password" className="dif_modal">
                <div className="flex call_to_subcsription_header update_user_informations_header">
                    <div>
                        <h3>Update your primary email</h3>
                        <p>A verification code has been sent to <span>{this.props.email}</span>. Consult the inbox to verify your account.</p>
                    </div>
                    <span id='cross_container' onClick={() => { this.props.onClosePressed() }}>
                        <i className="fa-solid fa-xmark"></i>
                    </span>
                </div>
                <div className="divider" />
                {!isEmpty(this.state.errorMessage) && <>
                    <Notifier type='error' message={this.state.errorMessage} />
                    <div className="divider" />
                </>}
                {!isEmpty(this.state.successMessage) && <>
                    <Notifier type='success' message={this.state.successMessage} />
                    <div className="divider" />
                </>}
                <fieldset disabled={this.state.isAsyncCall}>
                    <form ref={this.formRef} className="" onChange={this.handleChange} onSubmit={(event) => {
                        event.preventDefault();
                        this.setState({ errorMessage: '' });
                        joiSchema.validateAsync(this.state.formValues).then((formValues) => {
                            this.onUpdateInfo(formValues);
                        }).catch((error) => {
                            this.setState({ errorMessage: `${error.details.map((e) => e.message)}` });
                        });
                    }}>
                        <input type="text" name="VerificationCode" placeholder="Verification Code" />
                        <button className={`${this.state.isAsyncCall && 'button_on_async'}`} type={'submit'}>Continue</button>
                    </form>
                </fieldset>
            </div>
        </div>
    }

    onSendingMail() {
        this.setState({ isAsyncCall: true });
        const restApiServices = new ApiProvider();
        restApiServices.provider({
            baseUrl: systemAuthBaseUrl,
            endpoint: 'api/mail-checker',
            apiMethod: ApiMethods.post,
            successCodeExpectation: 200,
            requestData: {
                token: {},
                body: {
                    Email: this.props.email,
                }
            },
        }).then((response) => {
            if (typeof (response.error) === 'undefined') {
                this.setState({ isAsyncCall: false });
            } else {
                console.log(response.error);
                this.setState({ isAsyncCall: false, errorMessage: getErrorMessage("e13", 'en') });
            }
        });
    }

    onUpdateInfo(values) {
        const { VerificationCode } = values;
        this.setState({ isAsyncCall: true });
        const restApiServices = new ApiProvider();
        restApiServices.provider({
            baseUrl: systemAuthBaseUrl,
            endpoint: 'api/alter-user-information',
            apiMethod: ApiMethods.patch,
            successCodeExpectation: 200,
            requestData: {
                token: {
                    accessToken: this.props?.userInformation?.accessToken,
                },
                body: {
                    ...this.props?.otherValues,
                    Email: {
                        Code: VerificationCode,
                        Email: this.props.email,
                    }
                }
            },
        }).then((response) => {
            if (typeof (response.error) === 'undefined') {
                this.formRef.current.reset();
                const newUser = { ...response.response };
                this.props.dispatch(copyWith({
                    userInformation: { ...this.props.userInformation, ...newUser },
                }));
                this.setState({ isAsyncCall: false });
                this.props.onUpdate();
                this.props.onClosePressed()
            } else {
                console.log(response.error);
                this.setState({ isAsyncCall: false, errorMessage: getErrorMessage(response.error, 'en') });
            }
        });
    }
}

const mapStateToProps = (storeOf) => ({
    currentLanguage: storeOf.madoshome.currentLanguage,
    userInformation: storeOf.madoshome.userInformation,
    accountTypes: storeOf.madoshome.accountTypes,
});

export default connect(mapStateToProps)(UpdateEmail);