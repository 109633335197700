import React from "react"


export default class HideandShowInput extends React.Component {

    state = {
        isShowText: false,
    }

    render() {
        return (
            <div className="Hide_and_show_input">
                <i className={`fa-solid ${this.state.isShowText ? "fa-eye-slash" : "fa-eye"}`}
                onClick ={(event)=>{
                    event.stopPropagation();
                    this.setState({isShowText : !this.state.isShowText})
                }}></i>
                <input autoComplete="password" type={this.state.isShowText ? 'text' : 'password'} name="Password" placeholder={this.props.placeholder}/>
            </div>
        )
    }
}