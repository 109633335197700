import axios from "axios";
// import { authSystemBaseUrl, systemBaseUrl } from "../../config/config";



export const ApiMethods = {
    post: 'POST',
    get: 'GET',
    put: 'PUT',
    patch: 'PATCH',
    delete: 'DELETE',
}

export const ApiErrors = {
    e12: 'e12',
    e13: 'e13',
    e14: 'e14',
    e15: 'e15',
}


export default class ApiProvider {

    errorReturner(e) {
        if (e.code === "ERR_NETWORK") {
            return {
                error: "e12",
                response: {},
            }
        } else if (e.code === "ERR_BAD_REQUEST") {
            return {
                error: e.response.data,
                response: {},
            }
        } else if (e.code === "ERR_BAD_RESPONSE") {
            return {
                error: "e13",
                response: {},
            }
        } else if (e.code === "ERR_CANCELED") {
            return {
                error: "Canceled",
                response: {},
            }
        } else {
            if (e?.response?.data) {
                return {
                    error: `${String(e.response.data).includes('html') ? 'e13' : JSON.stringify(e.response.data)}`,
                    response: {},
                }
            } else {
                return {
                    error: "e14",
                    response: {},
                }
            }
        }
    }

    async provider(config) {
        const { baseUrl, endpoint, apiMethod, successCodeExpectation, requestData } = config;
        const url = `${baseUrl}/${endpoint}`;
        console.log("Service available at :", url);
        const mHeaders = Object.keys(requestData.token ?? {}).length ? {
            accesstoken: requestData?.token?.accessToken,
            refreshtoken: requestData?.token?.refreshToken,
        } : {};
        const adHeaders = Object.keys(requestData.headers ?? {}).length ? { ...requestData.headers } : {};
        return axios(url, {
            method: apiMethod,
            cancelToken: requestData.cancelToken,
            data: apiMethod !== ApiMethods.get && requestData.body,
            headers: { ...mHeaders, ...adHeaders },
        }).then((response) => {
            var succeed = response.status === successCodeExpectation;
            if (succeed) {
                try {
                    console.log("PARSED", JSON.parse(response.data))
                    return { response: JSON.parse(response.data) };
                } catch (e) {
                    console.log("NOT PARSED",response.data)
                    return { response: response.data };
                }

            } else {
                return {
                    error: "e14",
                    response: {},
                }
            }
        }).catch((e) => {
            console.log(e);
            console.log("[ API SERVICES ] : Enpoint:", endpoint, 'Exception', e);
            return this.errorReturner(e);
        });
    }

}
