import './myaccount.css';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Displayer from '../../components/displayer/displayer';
import { Route, Routes, Link } from 'react-router-dom';
import svg_icon from '../../../assets/svgs/isNotLogin.svg';
import noImgProfile from '../../../assets/images/empty_picture_password.png'
import Authentication from '../Authentication/authentication';
// import SubscriptionHistory from '../history/history';
import ApiProvider, { ApiMethods } from '../../../data/providers/api_services';
import { appName, systemAuthBaseUrl } from '../../../config/config';
import { copyWith } from '../../../logic/slice';
import UpdateUserInfo from '../update_user_info/update_user_info';
import ChangePassword from '../update_user_info/change_password';
// import PendingPaymentIntents from './pending_intents';
import DeleteAccount from '../Authentication/delete_account';
import { myCategories } from '../home/categories';
import { createRef } from 'react';
import Repairaccount from '../update_user_info/repairaccount';
import { capitalize } from '../../../utils/utils';
// import ThemeChanger from '../../components/theme_changer/theme_changer';
// import { getErrorMessage, isEmpty } from '../../../utils/utils';




class Myaccount extends Component {
    state = {
        isModaloginformDisplayed: false,
        wantUpdate: false,
        wantToChangePassword: false,
        wantToDeleteAccount: false,
        wantToRepairAccount: false,
        isLogin: false,
        historySubscription: [],
        pendingPaymentIntents: [],
        successOnAuthentication: false,
        errorOnLogin: false,
        errorMessage: ''
    }

    moreFilter = createRef();

    render() {
        const isUserSigned = Object.keys(this.props.userInformation).length;
        const userData = this.props.userInformation;
        const userSubscription = userData.Subscription ?? {};
        return (
            <div className='my_account_main_container'>
                {this.state.isModaloginformDisplayed === true &&
                    <Authentication
                    onClosePressed={() => { this.setState({ isModaloginformDisplayed: !this.state.isModaloginformDisplayed }) }} />
                }




                {this.state.wantUpdate === true &&
                    <UpdateUserInfo onClosePressed={() => { this.setState({ wantUpdate: !this.state.wantUpdate }) }}
                    />
                }
                {this.state.wantToChangePassword === true &&
                    <ChangePassword onClosePressed={() => { this.setState({ wantToChangePassword: !this.state.wantToChangePassword }) }}
                    />
                }
                {this.state.wantToDeleteAccount === true &&
                    <DeleteAccount onClosePressed={() => { this.setState({ wantToDeleteAccount: !this.state.wantToDeleteAccount }) }}
                    />
                }
                {this.state.wantToRepairAccount === true &&
                    <Repairaccount onClosePressed={() => { this.setState({ wantToRepairAccount: !this.state.wantToRepairAccount }) }} />
                }
                {isUserSigned ? <>
                    <div className="my_account_container">
                        <div ref={this.moreFilter} className='dif_modal my_account_hidden'>
                            {/* <div className="profil_account_container">
                                {this.profileAccountHeader({ userData: userData })}
                                {this.profileAccountMain({ userSubscription: userSubscription })}
                            </div> */}
                            <div className="profil_account_container2">
                                {this.profileAccountHeader({ userData: userData })}
                                {this.profileAccountMain({ userSubscription: userSubscription })}
                            </div>
                        </div>
                        <div className="profil_account_container dif_modal">
                            {this.profileAccountHeader({ userData: userData })}
                            {this.profileAccountMain({ userSubscription: userSubscription })}
                        </div>
                        <div className='simplified_user_card'>
                            <div className='simplified_user_card_left'>
                                <img src={userData.ProfilImage ?? noImgProfile} alt="" />
                                <div>
                                    <h4>{userData.Fullname ?? "Dev"}</h4>
                                    <p id='usertype'>{this.props.accountTypes.filter((e) => e.id === Number(userData.UserTypeId))[0]?.Name}</p>
                                </div>
                            </div>
                            <i className='fa fa-bars' onClick={(event) => {
                                event.stopPropagation();
                                this.moreFilter.current.style.transform = 'scaleX(1)';
                                this.moreFilter.current.style.maxWidth = '28rem';
                            }} />
                        </div>
                        {this.mainContainerAccount()}

                    </div>
                </> : <>
                    <div className="my_account_not_created_yet">
                        <img src={svg_icon} alt="Login" />
                        <span className='not_login_text'>
                            <p>
                                {{
                                    fr: "Vous devez vous connecter pour voir vos informations.",
                                    en: "You must log in to see your informations.",
                                    sw: "Lazima uingie ili kuona yaliyomo."
                                }[`${this.props.currentLanguage}`]}
                            </p>

                            <span className='inline_auth' onClick={() => { this.setState({ isModaloginformDisplayed: true }) }}> {{
                                fr: "Se Connecter",
                                en: "Log in",
                                sw: "Ingia"
                            }[`${this.props.currentLanguage}`]}</span>

                        </span>
                    </div>
                </>}
            </div>
        );
    }



    profileAccountHeader({ userData = {} }) {
        return <div className="profile_account_header">
            <div className="img_container">
                <img src={userData.ProfilImage ?? noImgProfile} alt="Profil" width={130} height={130} />
                <div className='img_container_actions'>
                    <span id='cross_container' onClick={(event) => { event.stopPropagation(); this.setState({ wantUpdate: !this.state.wantUpdate }) }}>
                        <i className="fa-solid fa-user-pen"></i>
                    </span>
                    <span id='cross_container' onClick={(event) => {
                        event.stopPropagation();
                        this.moreFilter.current.style.transform = 'scaleX(0)';
                        this.moreFilter.current.style.maxWidth = '0rem';
                    }}>
                        <i className="fa-solid fa-xmark"></i>
                    </span>
                </div>
            </div>
            <div className="user_information_container">
                <h3>{userData.Fullname ?? "Dev"}</h3>
                <p id='usertype'>{userData?.UserType[capitalize(this.props?.currentLanguage)]}</p>
                <h5 id='email'>{userData.Email ?? "uiellele@gmail.com"}</h5>
                <div className="contact_user">
                    <span>
                        <i className="fa-solid fa-phone"></i>
                        <h5>{userData.PhoneNumber ?? "+23499884747"} </h5>
                    </span>
                    <span>
                        <i className="fa-brands fa-whatsapp"></i>
                        <h5>{userData.WhatsappNumber ?? "8736615542"}</h5>
                    </span>
                </div>
            </div>
        </div>
    }

    callbackLogin = () => {
        this.props.dispatch(copyWith({
            userInformation: {}
        }));
        this.setState({ historySubscription: [], pendingPaymentIntents: [] });
    }

    profileAccountMain({ userSubscription = {} }) {
        return <div className="profile_account_main">
            
            {/* <div className="tiles_single_content">
                <ThemeChanger isExpended={true} isNewStyle={true} />
            </div> */}
            <Tiles
                iconName={"fa-solid fa-box-archive"}
                title={{
                    fr: "Publications",
                    en: "Post",
                    sw: "Machapisho"
                }[`${this.props.currentLanguage}`]}
                child={''}
                route={''}
                moreFilter={this.moreFilter}
            />
            {/* <Tiles
                iconName={"fa-solid fa-stopwatch"}
                title={{
                    fr: "Intentions de paiement en attente",
                    en: "Pending payment intents",
                    sw: "Nia za malipo zinazosubiri"
                }[`${this.props.currentLanguage}`]}
                child={``}
                route={'pending-payment-intents'}
                moreFilter={this.moreFilter}
            />
            <Tiles
                iconName={"fa-solid fa-crown"}
                title={{
                    fr: "Abonnement",
                    en: "Subscription",
                    sw: "Usajili"
                }[`${this.props.currentLanguage}`]}
                child={userSubscription !== null && Object.keys(userSubscription).length > 0 ? <>
                    <p>{userSubscription?.SubscriptionType?.Name} - {userSubscription?.SubscriptionType?.Price} USD</p>
                    <p>{{
                        fr: "Date d'expiration",
                        en: "Expiration date",
                        sw: "Tarehe ya kumalizika muda wake"
                    }[`${this.props.currentLanguage}`]} {new Date(userSubscription?.To).toLocaleDateString()}</p>
                </> : <></>}
                route={'subscriptions'}
                moreFilter={this.moreFilter}
            /> */}
            <div className="tiles_single_content" onClick={(event) => {
                event.stopPropagation();
                event.preventDefault();
                this.setState({ wantToChangePassword: !this.state.wantToChangePassword })
            }}>
                <i id="inside_icon" className="fa fa-key" />
                <div className="title_and_details_container">
                    <h4>{{
                        fr: "Changer votre mot de passe",
                        en: "Change your password",
                        sw: "Badilisha neno la siri yako"
                    }[`${this.props.currentLanguage}`]}</h4>
                </div>
            </div>
            <a href={`https://${appName.toLocaleLowerCase()}.com/contact-us/${this.props.currentLanguage === 'en' ? "" : this.props.currentLanguage + "/"}`} className="tiles_single_content" target={"_blank"} rel={"noreferrer"}>
                <i id="inside_icon" className="fa-solid fa-headset" />
                <div className="title_and_details_container">
                    <h4>{{
                        fr: "Nous contacter",
                        en: "Contact us",
                        sw: "Wasiliana nasi"
                    }[`${this.props.currentLanguage}`]}</h4>
                </div>
            </a>
            {/* <a href={`https://${appName.toLocaleLowerCase()}.com/terms-and-conditions/${this.props.currentLanguage === 'en' ? "" : this.props.currentLanguage + "/"}`} className="tiles_single_content" target={"_blank"} rel={"noreferrer"}>
                <i id="inside_icon" className="fa-solid fa-file-lines" />
                <div className="title_and_details_container">
                    <h4>{{
                        fr: "Conditions et termes",
                        en: "Terms and conditions",
                        sw: "Sheria na masharti"
                    }[`${this.props.currentLanguage}`]}</h4>
                </div>
            </a> */}
            <div className="tiles_single_content" onClick={(event) => {
                event.stopPropagation();
                event.preventDefault();
                this.setState({ wantToRepairAccount: !this.state.wantToRepairAccount })
            }}>
                <i id="inside_icon" className="fa fa-house-medical" />
                <div className="title_and_details_container">
                    <h4 >{{
                        fr: "Réparer votre compte",
                        en: "Repair your account",
                        sw: "Rekebisha akaunti yako"
                    }[`${this.props.currentLanguage}`]}</h4>
                </div>
            </div>

            <div className="tiles_single_content" onClick={(event) => {
                event.stopPropagation();
                event.preventDefault();
                this.setState({ wantToDeleteAccount: !this.state.wantToDeleteAccount })
            }}>
                <i id="inside_icon" className="fa fa-trash" />
                <div className="title_and_details_container">
                    <h4 >{{
                        fr: "Supprimer votre compte",
                        en: "Delete your account",
                        sw: "Futa akaunti yako"
                    }[`${this.props.currentLanguage}`]}</h4>
                </div>
            </div>
            <div className="tiles_single_content logout" onClick={() => { onLogout(this.callbackLogin) }}>
                <i id="inside_icon_logout" className="fa-solid fa-arrow-right-from-bracket"></i>
                <div className="title_and_details_container">
                    <h4 >{{
                        fr: "Se déconnecter",
                        en: "Log out",
                        sw: "Ondoka"
                    }[`${this.props.currentLanguage}`]}</h4>
                </div>
            </div>
        </div>
    }

    mainContainerAccount() {
        // console.log(this.props.currentLanguage);
        return <div className="main_container_account">
            <Routes>
                <Route path='/' index element={<Displayer key={'displayer'} categories={myCategories(
                    {
                        lat: this.props.userLocation?.lat,
                        lng: this.props.userLocation?.lon,
                        region: this.props.userLocation?.regionName,
                        accessToken: this.props.userInformation.accessToken ?? '',
                        isUpdateTime: true,
                        currentLanguage: this.props.currentLanguage,
                    }
                )} />} />
                {/* <Route path='/pending-payment-intents' element={<PendingPaymentIntents items={this.state.pendingPaymentIntents} onItems={(items) => { this.setState({ pendingPaymentIntents: items }) }} />} />
                <Route path='/subscriptions' element={<SubscriptionHistory items={this.state.historySubscription} onItems={(items) => { this.setState({ historySubscription: items }) }} />} /> */}
                <Route path='/change-password' element={<div><h2>Change-Password</h2></div>} />
                <Route path='/delete-account' element={<div><h2>Delete Account</h2></div>} />
                <Route path='*' element={<div><h1>Url not found 404</h1></div>} />
            </Routes>
        </div>
    }
}


export function onLogout(callbackLogin) {
    const restApiServices = new ApiProvider()
    return restApiServices.provider({
        baseUrl: systemAuthBaseUrl,
        endpoint: "api/logout",
        apiMethod: ApiMethods.get,
        successCodeExpectation: 200,
        requestData: {
            token: {
                accessToken: localStorage.getItem(`${appName}act`) ?? '',
            }
        }
    }).then(() => {
        callbackLogin();
    })

}

function Tiles({ iconName, title, child, subTiles = [], route, moreFilter }) {
    return (
        <>
            {subTiles.length === 0 ? <>
                <Link to={`${route}`} onClick={(event) => {
                    event.stopPropagation();
                    if (moreFilter) {
                        moreFilter.current.style.transform = 'scaleX(0)';
                        moreFilter.current.style.maxWidth = '0rem';
                    }
                }}>
                    <div className="tiles_single_content">
                        <i id="inside_icon" className={iconName}></i>
                        <div className="title_and_details_container">
                            <h4>{title}</h4>
                            {child.length === 0 ? <></> : <>
                                {child}
                            </>}
                        </div>
                        <i className="fa-solid fa-angle-right"></i>
                    </div>
                </Link>

            </>
                : <>

                </>
            }

        </>
    )
}
const mapStateToProps = (storeOf) => ({
    currentLanguage: storeOf.madoshome.currentLanguage.code,
    userInformation: storeOf.madoshome.userInformation,
    accountTypes: storeOf.madoshome.accountTypes,
    subscriptionPlans: storeOf.madoshome.subscriptionPlans,
    userLocation: storeOf.madoshome.userLocation,
    isDarkModeOn: storeOf.madoshome.isDarkModeOn
});

export default connect(mapStateToProps)(Myaccount);