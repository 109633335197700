import React, { createRef } from "react";
import ApiProvider, { ApiMethods } from "../../../data/providers/api_services";
import { getErrorMessage, isEmpty } from "../../../utils/utils";
import Notifier from "../notifier/notifier";
import './comments.css';
import Joi from "joi";
import { connect } from "react-redux";




class CommentsDisplayer extends React.Component {
    state = {
        isAsyncCall: false,
        comment: {},
        formValues: {},
        errorMessage: '',
        viewOverComment: false,
    }


    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState(state => ({ ...state, formValues: { ...state.formValues, [name]: value } }));
    }

    formRef = createRef();

    render() {
        const joiSchema = Joi.object({ Comment: Joi.string().required() });
        return Object.keys(this.props.userInformation ?? {}).length ? <div className="comments_displayer">
            {!isEmpty(this.state.errorMessage) && <>
                <Notifier type='error' message={this.state.errorMessage} />
            </>}
            <fieldset disabled={this.state.isAsyncCall}>
                <form className="comments_displayer_form" autoComplete="off" ref={this.formRef} onChange={this.handleChange} onSubmit={(event) => {
                    event.preventDefault();
                    this.setState({ errorMessage: '' });
                    joiSchema.validateAsync(this.state.formValues).then((formValues) => {
                        this.onCommenting(formValues.Comment);
                    }).catch((error) => {
                        this.setState({ errorMessage: `${error.details.map((e) => e.message)}` });
                    });
                }}>
                    <input type="text" name="Comment" placeholder={{
                        fr : "Taper votre commentaire ici",
                        en : "Write your comment here",
                        sw : "Andika komenti yako apa"
                    }[`${this.props.currentLanguage}`]} />
                    
                    <button className={`${this.state.isAsyncCall && 'button_on_async'}`} type={'submit'}>{{
                        fr : "Commenter",
                        en : "Comment ",
                        sw : "Komenti "
                    }[`${this.props.currentLanguage}`]}</button>
                </form>
            </fieldset>

            {/* {this.props.options.comments > 1 ? <p className="comments_displayer_action_p">View all comments</p> : <></>} */}
            {/* {this.state.comments.length > 0 ? <CommentCard id={this.state.comments[0].id} userId={this.state.comments[0].UserId} comment={this.state.comments[0].Comment} createdAt={this.state.comments[0].createdAt} onDelete={(id) => this.onDeleteComment(id)} /> : <></>} */}
        </div> : <></>
    }

    onCommenting(comment) {
        const { commentBody, baseUrl, commentEndpoint, comments } = this.props.options;
        this.setState({ isAsyncCall: true, errorMessage: '' });
        const restApiServices = new ApiProvider();
        restApiServices.provider({
            baseUrl: baseUrl,
            endpoint: `api/${commentEndpoint}`,
            apiMethod: ApiMethods.post,
            successCodeExpectation: 200,
            requestData: {
                token: { accessToken: this.props?.userInformation?.accessToken },
                body: {

                    Comment: comment,
                    ...commentBody,
                }
            },
        }).then((response) => {
            if (typeof (response.error) === 'undefined') {
                console.log(response.response);
                this.props.options.onCommentsStateUpdate(Number(comments) + 1)
                if(this.props?.options?.giveMeTheComment){
                    this.props.options.giveMeTheComment(response.response);
                }
                this.setState({ isAsyncCall: false });
                this.formRef.current.reset();
            } else {
                this.setState({ isAsyncCall: false, errorMessage: getErrorMessage('e13', 'en') });
            }
        });
    }

}

const mapStateToProps = (storeOf) => ({
    currentLanguage: storeOf.madoshome.currentLanguage.code,
    userInformation: storeOf.madoshome.userInformation,
});

export default connect(mapStateToProps)(CommentsDisplayer);


