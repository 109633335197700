import { createSlice } from '@reduxjs/toolkit'
import Internationalisation from '../data/providers/internationalisation';

export const nisysSlice = createSlice({
    name: 'madoshome',
    initialState: {
        refreshToken: '',
        accessToken: '',
        isDarkModeOn: false,
        isInitialized: false,
        currentLanguage: new Internationalisation().getLanguageByCode('fr'),
        userInformation: {},
        userLocation: {},
        subscriptionPlans: [],
        accountTypes: [],
        currencies: [],
        tourismTypes: [],
        days: [],
        ratingTypes: [],
        roomTypes: [],
        propertyTypes: [],
        RentalTypes: [],
    },
    reducers: {
        copyWith: (state, action) => {
            state.userLocation = action.payload.userLocation ?? state.userLocation;
            state.isInitialized = action.payload.isInitialized ?? state.isInitialized;
            state.userInformation = action.payload.userInformation ?? state.userInformation;
            state.isDarkModeOn = action.payload.isDarkModeOn ?? state.isDarkModeOn;
            state.refreshToken = action.payload.refreshToken ?? state.refreshToken;
            state.accessToken = action.payload.accessToken ?? state.accessToken;
            state.currentLanguage = action.payload.currentLanguage ?? state.currentLanguage;
            state.accountTypes = action.payload.accountTypes ?? state.accountTypes;
            state.subscriptionPlans = action.payload.subscriptionPlans ?? state.subscriptionPlans;
            state.currencies = action.payload.currencies ?? state.currencies;
            state.tourismTypes = action.payload.tourismTypes ?? state.tourismTypes;
            state.days = action.payload.days ?? state.days;
            state.ratingTypes = action.payload.ratingTypes ?? state.ratingTypes;
            state.roomTypes = action.payload.roomTypes ?? state.roomTypes;
            state.propertyTypes = action.payload.propertyTypes ?? state.propertyTypes;
            state.RentalTypes = action.payload.RentalTypes ?? state.RentalTypes;
        },
    },
})

export const { copyWith } = nisysSlice.actions;


export default nisysSlice.reducer;