import './App.css'
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from '../home/home';
// import Splash from '../splash/splash';
// import { Elements } from '@stripe/react-stripe-js';
// import { loadStripe } from '@stripe/stripe-js';


export default class App extends React.Component {

    // stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);
    render() {
        return <div className='App'>
            <Routes>
                <Route index element={<Home />} />
                <Route path='/' exact element={<Home />}>
                    <Route path='home/*' element={<div className='flex' />} />
                    <Route path='*' element={<div><h1>Url not found 404</h1></div>} />
                </Route>
                <Route path='*' element={<div><h1>Url not found 404</h1></div>} />
            </Routes>
        </div>
        // return <Elements stripe={this.stripePromise}>

        // </Elements>
    }
}