import React, { createRef } from "react";
import { getErrorMessage, isEmpty } from "../../../utils/utils";
import Notifier from "../../components/notifier/notifier";
import './forget_password.css';
import Joi from "joi";
import ApiProvider, { ApiMethods } from "../../../data/providers/api_services";
import { systemAuthBaseUrl } from "../../../config/config";


export default class ForgetPassword extends React.Component {

    state = {
        isAsyncCall: false,
        errorMessage: '',
        successMessage: '',
        currentEmailOrUsername: '',
        formValues: {},
        doUserExistKnown: false,
        successState : false,
    }

    componentDidMount() {

    }

    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState(state => ({ ...state, formValues: { ...state.formValues, [name]: value } }));
    }

    getJoiSchemaValues(doUserExistKnown) {
        const unknownFormValues = {
            EmailOrUsername: Joi.string().required()
        };
        return !doUserExistKnown ? unknownFormValues : {
            VerificationCode: Joi.number().required(),
            Password: Joi.string().required(),
            ConfirmPassword: Joi.string().required().valid(Joi.ref('Password')),
        }
    }

    formRef = createRef();

    render() {
        const waitingmsg = {
            'fr': "Patientez s'il vous plait...",
            'en': "Please wait...",
            'sw': "Tafadhali subiri..."
        }[`${this.props.currentLanguage}`]

        const change = {
            'fr': "Taper votre e-mail",
            'en': "Enter your e-mail ",
            'sw': "Ingiza barua pepe yako"
        }[`${this.props.currentLanguage}`] 


        
        const joiSchema = Joi.object(this.getJoiSchemaValues(this.state.doUserExistKnown));
        return <div className="modal_rideau">
            <div id="forget_password" className="dif_modal">
                <div className="flex call_to_subcsription_header update_user_informations_header">
                    <div>
                        <h3>{{
                            fr: "Mot de passe oublié ?",
                            en: "Forget password ?",
                            sw: "Je, ume sahahu nywila ?"
                        }[`${this.props.currentLanguage}`]}</h3>
                    </div>
                    <span id='cross_container' onClick={() => { this.props.onClosePressed() }}>
                        <i className="fa-solid fa-xmark"></i>
                    </span>
                </div>
                <div className="divider" />







                {!isEmpty(this.state.errorMessage) && <>
                    <Notifier type='error' message={this.state.errorMessage} />
                    <div className="divider" />
                </>}
                {!isEmpty(this.state.successMessage) && <>
                    <Notifier type='success' message={this.state.successMessage} />
                    <div className="divider" />
                </>}






                <fieldset disabled={this.state.isAsyncCall}>
                    <form ref={this.formRef} className="" onChange={this.handleChange} onSubmit={(event) => {
                        event.preventDefault();
                        this.setState({ errorMessage: '' });
                        joiSchema.validateAsync(this.state.formValues).then((formValues) => {
                            if (this.state.doUserExistKnown) {
                                this.onUpdatePassword(formValues);
                            } else {
                                this.onChekcking(formValues.EmailOrUsername);
                            }
                        }).catch((error) => {
                            this.setState({ errorMessage: `${error.details.map((e) => e.message)}` });
                        });
                    }}>
                        {this.state.doUserExistKnown
                            ? <div>
                                <input type="text" name="VerificationCode" placeholder={{
                                    fr: "Code de vérification",
                                    en: "Verification code",
                                    sw: "Nambari ya uthibitishaji"
                                }[`${this.props.currentLanguage}`]} />
                                <div className="flex forget_pass_kn">
                                    <input type="password" name="Password" placeholder={{
                                        fr: "Nouveau mot de passe",
                                        en: "New password",
                                        sw: "Neno siri la mpia"
                                    }[`${this.props.currentLanguage}`]} />
                                    <input type="password" name="ConfirmPassword" placeholder={{
                                        fr: "Confirmer votre mot de passe",
                                        en: "Confrim your password",
                                        sw: "hakikisha neno siri lako"
                                    }[`${this.props.currentLanguage}`]} />
                                </div>
                            </div>
                            : <input type="text" name="EmailOrUsername" placeholder={this.state.isAsyncCall ? waitingmsg : change } onChange={(event) => { this.setState({ currentEmailOrUsername: event.target.value }) }} />}

                        <button className={`${this.state.isAsyncCall && 'button_on_async'}`} type={'submit'}>{{
                            fr : "Continuer",
                            en : "Continue",
                            sw : "Endeleya"
                        }[`${this.props.currentLanguage}`]}</button>
                    </form>
                </fieldset>
            </div>
        </div>
    }

    onChekcking(emailOrUserName) {
        this.setState({ isAsyncCall: true });
        const restApiServices = new ApiProvider();
        restApiServices.provider({
            baseUrl: systemAuthBaseUrl,
            endpoint: 'api/mail-checker-forget-password',
            apiMethod: ApiMethods.post,
            successCodeExpectation: 200,
            requestData: {
                token: {},
                body: {
                    Email: emailOrUserName,
                }
            },
        }).then((response) => {
            if (typeof (response.error) === 'undefined') {
                this.setState({ isAsyncCall: false, doUserExistKnown: true, formValues: {} });
            } else {
                const hasTask = typeof response.error?.task !== 'undefined';
                this.setState({ isAsyncCall: false, errorMessage: `${hasTask ? "User doesn't exist" : getErrorMessage(response.error, 'en')}` });
            }
        });
    }

    onUpdatePassword(values) {
        const { VerificationCode, Password, ConfirmPassword } = values;
        this.setState({ isAsyncCall: true });
        const restApiServices = new ApiProvider();
        restApiServices.provider({
            baseUrl: systemAuthBaseUrl,
            endpoint: 'api/alter-user-password',
            apiMethod: ApiMethods.patch,
            successCodeExpectation: 200,
            requestData: {
                token: {},
                body: {
                    Code: VerificationCode,
                    Email: this.state.currentEmailOrUsername,
                    Password: Password,
                    ConfirmPassword: ConfirmPassword,
                }
            },
        }).then((response) => {
            if (typeof (response.error) === 'undefined') {
                this.setState({ isAsyncCall: false, successState : true});
                // this.formRef.current.reset();
                this.props.onSuccessForgetPassword(this.state.successState)
            } else {
                console.log(response.error);
                this.setState({ isAsyncCall: false, errorMessage: getErrorMessage(response.error, 'en') });
            }
        });
    }
}