import React from 'react';
import { connect } from 'react-redux';
import ManagerRepo from '../../../data/repositories/managerRepo';
import { copyWith } from '../../../logic/slice';


import './theme_changer.css';





class ThemeChanger extends React.Component {
    
    render() {
        let lightMode = {
            'fr' : 'Mode lumière',
            'en' : 'Light Mode',
            'sw' : 'Hali ya mwanga'
        }
        let darkMode = {
            'fr' : 'Mode Sombre',
            'en' : 'Dark Mode',
            'sw' : 'Hali ya giza'
        }
        
        return <div className={`themeChangerButton ${this.props.isExpended && "expended"} ${this.props.isNewStyle && "onWideArea"}`} onClick={() => { this.changeTheme() }}>
            <i className={`fa fa-${this.props.isDarkModeOn ? "sun" : "moon"}`}  />
            {this.props.isExpended && <p>{this.props.isDarkModeOn ? lightMode[`${this.props.currentLanguage.code}`] :darkMode[`${this.props.currentLanguage.code}`]}</p>}
            
        </div>
    }

    async changeTheme() {
        try {
            const managerRepo = new ManagerRepo();
            managerRepo.onThemeChanged(!this.props.isDarkModeOn);
            await this.props.dispatch(await copyWith({
                isDarkModeOn: this.props.isDarkModeOn === true ? false: true,
            }));
        } catch (e) {
            this.props.dispatch(copyWith({
                errorMessage: `An error has occured while changing the app theme ${e}`,
                isErrorOccured: true,
            }));
        }
        this.props.dispatch(copyWith({ isErrorOccured: false }));
        console.log("Theme changed");
    }
}



const mapStateToProps = (storeOf) => ({
    isDarkModeOn: storeOf.madoshome.isDarkModeOn,
    currentLanguage: storeOf.madoshome.currentLanguage
});

export default connect(mapStateToProps)(ThemeChanger);