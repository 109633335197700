import React from 'react';
import DetailsModal from '../../pages/details_modal/details_modal';
import CustomCarousel from '../caroussel/caroussel';
import './tour_site_card.css';
import DetailsDisplayer from '../details_displayer/details_displayer';
import { systemTourismBaseUrl } from '../../../config/config';
import Numeral from 'react-numeral';
import { CalcCrow, capitalize } from '../../../utils/utils';
import { connect } from 'react-redux';
import TourSiteDetails from './tour_site_details';
import TourSiteUpdates from './tour_site_updates';



class TourSiteCard extends React.Component {

    state = {
        isDetailsDisplayed: false,
    }


    render() {
        const { id, tourismId, Actif, Name, Images, Latitude, Longitude, TourismType } = this.props.tourSite;
        const tour = this.state.currenttour;
        return <div className='houses_card'>
            {(this.props.isUpdateTime && !Actif) ? <div className='show_actif_status' id={`${!Actif && 'show_actif_status_disabled'}`}>{Actif ? <i className='fa fa-eye' /> : <i className='fa fa-eye-slash' />}</div> : <></>}
            {this.state.isDetailsDisplayed && <DetailsModal
                body={<DetailsDisplayer detailsOptions={{
                    entityId: tourismId ? tourismId : id,
                    images: Images,
                    baseUrl: systemTourismBaseUrl,
                    entityEndpoint: 'tourism/view-over-tourism',
                    likeEndpoint: 'like',
                    unlikeEndpoint: 'unlike',
                    commentBody: { TourismId: tourismId ? tourismId : id },
                    entityDetailsUi: <TourSiteDetails tour={tour} />,
                    commentEndpoint: 'comment/add-comment',
                    bookmarkEndpoint: 'tourism',
                    deleteCommentEndpoint: 'comment/remove-comment',
                    fetchingCommentsEnpoint: 'comment/filter-comment',
                    reportEndpoint: 'add-report-Touristic',
                    reportKey: 'idTouristic',
                    onObjectReady: (object) => { this.setState({ currenttour: object }) },
                }} />}
                onClose={() => { this.setState({ isDetailsDisplayed: !this.state.isDetailsDisplayed }) }} />
            }
            {this.state.isUpdateTime && <DetailsModal
                body={<TourSiteUpdates tour={this.props.tourSite} onDeleteObject={() => {
                    this.setState({ isUpdateTime: false })
                }} />}
                onClose={() => { this.setState({ isUpdateTime: false }) }}
            />
            }
            <CustomCarousel images={Images ? [...Images].map((e) => e.Url) : []} />
            <div className='tour_site_card_details' onClick={(event) => {
                event.stopPropagation();
                if (this.props.isUpdateTime) {
                    this.setState({ isUpdateTime: !this.state.isUpdateTime })
                } else {
                    this.setState({ isDetailsDisplayed: !this.state.isDetailsDisplayed })
                }
            }}>
                <div className='tour_site_card_details_price_like flex'>
                    <h4>{Name}</h4>
                    {/* <i className='fa fa-heart' onClick={() => {
                        console.log('You liked this tourist site dudeeee');
                    }} /> */}
                </div>
                <div className='houses_card_details_more flex'>
                    <i className='fa fa-clover' />
                    <h6>{TourismType ? TourismType[capitalize(this.props.currentLanguage)] : ''}</h6>
                </div>
                <div className='houses_card_details_more flex'>
                    <i className='fa fa-map-location-dot' />
                    <h6><Numeral value={CalcCrow(Latitude, Longitude, this.props.userLocation?.lat, this.props.userLocation?.lon)} format={'0.0a'} />{{
                        fr: " Kilomètre de loins",
                        en: " Kilometer away",
                        sw: " Kilomita mbali"
                    }[`${this.props.currentLanguage}`]}</h6>
                </div>
            </div>
        </div>
    }
}

const mapStateToProps = (storeOf) => ({
    currentLanguage: storeOf.madoshome.currentLanguage.code,
    userInformation: storeOf.madoshome.userInformation,
    userLocation: storeOf.madoshome.userLocation,
    currencies: storeOf.madoshome.currencies,
});

export default connect(mapStateToProps)(TourSiteCard);