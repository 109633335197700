import React, { Component, createRef } from 'react'
import './caroussel002.css';
import { getErrorMessage, isEmpty, uploadFilesS3, deleteFilesS3 } from '../../../utils/utils';
import Notifier from '../notifier/notifier';
import ApiProvider, { ApiMethods } from '../../../data/providers/api_services';
import Loader from '../loader/loader';
import { appName } from '../../../config/config';
import { connect } from 'react-redux';
import FilePicker from '../file_picker/file_picker';


class Caroussel002 extends Component {

    state = {
        currentIndex: 0,
        fTaken: 0,
        errorMessage: '',
        isAsyncCall: false,
        items: [],
    }

    componentDidMount() {
        this.setState({ fTaken: this.props.initialValues.length, items: this.itemBuilder(this.props.initialValues, this.props.video) });
    }

    slideRef = createRef();
    videoRef = createRef();

    itemBuilder(images, video) {
        var items = this.props.initialValues.map((e, i) => {
            return this.props.builder(i);
        })
        if (video) {
            const isThereAVideo = (typeof this.props.video !== 'undefined') && this.props.video !== null;
            const isVideoPreviewed = isThereAVideo && (this.state.currentIndex === this.state.items.length - 1);
            items.push(<video ref={this.videoRef} src={video} style={{ width: '100%', height: '100%' }} controls={isVideoPreviewed} />)
        }
        return items;
    }

    componentWillUnmount() {
        this.videoRef?.current?.pause();
        this.videoRef?.current?.removeAttribute('src');
        this.videoRef?.current?.load();
    }


    render() {
        const { max, initialValues, min, uploadOptions, isVideoUseless } = this.props;
        const sliderGap = 300;
        const extensions = String(this.props.extensions?.map((e) => `.${e}`));
        const isThereAVideo = (typeof this.props.video !== 'undefined') && this.props.video !== null;
        const isVideoPreviewed = isThereAVideo && (this.state.currentIndex === this.state.items.length - 1);
        const items = this.itemBuilder(this.props.initialValues, this.props.video)
        return (
            <div className='caroussel002'>
                {!isEmpty(this.state.errorMessage) && <>
                    <Notifier type='error' message={this.state.errorMessage} />
                </>}
                <div className='caroussel002_preview'>
                    {this.state.isAsyncCall && <div className='caroussel002_preview_loader'><Loader /></div>}
                    {isVideoPreviewed ? <div className='caroussel002_preview_actions'>
                        {uploadOptions && <div className='caroussel002_preview_actions_button'>
                            <i className='fa fa-trash' onClick={(event) => {
                                event.stopPropagation();
                                this.setState({ isAsyncCall: true });
                                deleteFilesS3(this.props.video).then((deleted) => {
                                    this.onAddVideo(undefined);
                                });
                            }} />
                        </div>}
                    </div> : <></>}
                    {!isVideoPreviewed ? <div className='caroussel002_preview_actions'>
                        <div className='caroussel002_preview_actions_button' onClick={(event) => {
                            event.stopPropagation();
                            window.open(initialValues[this.state.currentIndex]?.Url)
                        }}>
                            <i className='fa fa-expand' />
                        </div>
                        {uploadOptions && <div className='caroussel002_preview_actions_button'>
                            <i className='fa fa-trash' onClick={(event) => {
                                if (initialValues.length <= (min)) {
                                    this.setState({ errorMessage: `At least ${min} files is required.` })
                                } else {
                                    event.stopPropagation();
                                    this.setState({ isAsyncCall: true });
                                    deleteFilesS3(initialValues[this.state.currentIndex]?.Url).then((deleted) => {
                                        this.deletFile(initialValues[this.state.currentIndex]?.id);
                                    });
                                }
                            }} />
                        </div>}
                    </div> : <></>}
                    {items[this.state.currentIndex]}
                </div>
                <div ref={this.slideRef} className='caroussel002_items'>
                    <div className='caroussel002_items_icons caroussel002_items_icons_left' onClick={(event) => {
                        event.stopPropagation();
                        this.slideRef.current.scrollLeft -= sliderGap;
                    }}>
                        <i className='fa fa-arrow-left' />
                    </div>
                    {(!isThereAVideo && uploadOptions && !isVideoUseless) && <>
                        <FilePicker
                            extensions={'.mp4'}
                            isMultiple={false}
                            isEnabled={true}
                            max={1}
                            onChange={(files) => {
                                console.log("VIDEOOOOOOOOOOOO", files)
                                for (var i = 0; i < files.length; i++) {
                                    this.uploadGottenFiles(files[i], true);
                                }
                            }}
                            onLoading={(isAsyncl) => {
                                this.setState({ isAsyncCall: isAsyncl });
                            }}
                            builder={(inputId) => {
                                return  <label className='caroussel002_items_child add_files_card' htmlFor={inputId}>
                                <i className='fa fa-video' />
                                <p>{{
                                    fr: 'Ajouter une vidéo',
                                    en: 'Add a video',
                                    sw: 'Ongeza video'
                                }[this.props.currentLanguage]}</p>
                            </label>
                            }}
                        />
                    </>}
                    {uploadOptions && <>
                        <FilePicker
                            extensions={extensions}
                            isMultiple={false}
                            isEnabled={initialValues.length < max}
                            max={max}
                            onChange={(files) => {
                                console.log('PICKED FILES ===> ', files.map((file) => file.name));
                                for (var i = 0; i < files.length; i++) {
                                    this.uploadGottenFiles(files[i], false);
                                }
                            }}
                            onLoading={(isAsyncl) => {
                                this.setState({ isAsyncCall: isAsyncl });
                            }}
                            builder={(inputId) => {
                                return <label className='caroussel002_items_child add_files_card' htmlFor={inputId}>
                                    <i className='fa fa-image' />
                                    <p>{{
                                        fr: 'Ajouter une photo',
                                        en: 'Add a photo',
                                        sw: 'Ongeza picha'
                                    }[this.props.currentLanguage]}</p>
                                </label>
                            }}
                        />
                    </>}

                    {items.map((e, i) => {
                        return <div key={i} className='caroussel002_items_child' onClick={(event) => {
                            event.stopPropagation();
                            this.setState({ currentIndex: i });
                        }}>{e}</div>
                    })}
                    <div className='caroussel002_items_icons caroussel002_items_icons_right' onClick={(event) => {
                        event.stopPropagation();
                        this.slideRef.current.scrollLeft += sliderGap;
                    }}>
                        <i className='fa fa-arrow-right' />
                    </div>
                </div>
            </div>
        )
    }

    async downloadFunction(url) {
        return await fetch(url).then(r => r.blob()).then(data_blob => {
            const imageURL = URL.createObjectURL(data_blob);
            const link = document.createElement('a');
            link.href = imageURL;
            link.download = url.split('/').pop();
            link.click();
            return true;
        }).catch((e) => {
            return false;
        });
    }



    async uploadGottenFiles(file, isVideo) {
        await this.setState({ isAsyncCall: true });
        await uploadFilesS3(file, { userUUID: this.props.uploadOptions.userUUID, field: this.props.uploadOptions.field }).then((url) => {
            if (!isEmpty(url)) {
                if (!isVideo) {
                    this.uploadFile(url);
                } else {
                    this.onAddVideo(url);
                }
            }else{
                this.setState({ isAsyncCall: false, errorMessage: getErrorMessage('e12', this.props.currentLanguage) });
            }
            this.setState({ isAsyncCall: false });
        });
    }

    async uploadFile(url) {
        if (!isEmpty(url)) {
            this.setState({ isAsyncCall: true });
            const { baseUrl, uploadEndpoint } = this.props.uploadOptions;
            const { onUpload, entityIdObject } = this.props;
            const restApiServices = new ApiProvider();
            await restApiServices.provider({
                baseUrl: baseUrl,
                endpoint: `api/${uploadEndpoint}`,
                apiMethod: ApiMethods.post,
                successCodeExpectation: 200,
                requestData: {
                    token: {
                        accessToken: localStorage.getItem(`${appName}act`) ?? ''
                    },
                    body: {
                        Url: url,
                        ...entityIdObject,
                    }
                },
            }).then((response) => {
                if (typeof (response.error) === "undefined") {
                    const object = response.response;
                    onUpload(object);
                    this.setState({ isAsyncCall: false, currentIndex: 0 });
                } else {
                    this.setState({ isAsyncCall: false, errorMessage: getErrorMessage(response.error, this.props.currentLanguage) });
                }
            });
        }
    };

    deletFile(id) {
        this.setState({ isAsyncCall: true });
        const { baseUrl, deleteEndpoint } = this.props.uploadOptions;
        const { onDelete } = this.props;
        const restApiServices = new ApiProvider();
        restApiServices.provider({
            baseUrl: baseUrl,
            endpoint: `api/${deleteEndpoint(id)}`,
            apiMethod: ApiMethods.delete,
            successCodeExpectation: 200,
            requestData: {
                token: {
                    accessToken: localStorage.getItem(`${appName}act`) ?? ''
                },
            },
        }).then((response) => {
            if (typeof (response.error) === "undefined") {
                this.setState({ isAsyncCall: false });
                onDelete(id);
            } else {
                this.setState({ isAsyncCall: false, errorMessage: getErrorMessage(response.error, this.props.currentLanguage) });
            }
        });
    };

    onAddVideo(url) {
        const { baseUrl, uploadVideoEndpoint } = this.props.uploadOptions;
        this.setState({ isAsyncCall: true });
        const restApiServices = new ApiProvider();
        restApiServices.provider({
            baseUrl: baseUrl,
            endpoint: `api/${uploadVideoEndpoint}`,
            apiMethod: ApiMethods.patch,
            successCodeExpectation: 200,
            requestData: {
                token: {
                    accessToken: localStorage.getItem(`${appName}act`) ?? ''
                },
                body: {
                    Video: url ?? null,
                },
            },
        }).then((response) => {
            if (typeof (response.error) === "undefined") {
                this.setState({ isAsyncCall: false, currentIndex: 0 });
                this.props.onVideoChanged(url);
            } else {
                this.setState({ isAsyncCall: false, errorMessage: getErrorMessage(response.error, this.props.currentLanguage) });
            }
        });

    };
}

const mapStateToProps = (storeOf) => ({
    currentLanguage: storeOf.madoshome.currentLanguage.code
})

export default connect(mapStateToProps)(Caroussel002)
